import ioc from '@/services/ioc';
import {Store} from 'vuex';
import installRouter from '@Framework/installRouter';
import {Vue} from 'vue-property-decorator';

const authService = () => ioc.get('service.authService') as any;

export default (store: Store<any>) => {
    const router = installRouter(Vue);
    ioc.set(
        'router',
        // eslint-disable-next-line func-names
        function () {
            return router;
        },
        {},
    );

    router.beforeEach(async (to, from, next) => {
        const forPublic = to.meta?.routeFor?.includes('PUBLIC');
        const forAdmins = to.meta?.routeFor?.includes('ADMIN');
        const forClients = to.meta?.routeFor?.includes('CLIENT');
        const authRequired = !forPublic;
        const loggedIn = authService().isLogged();
        const isAdmin = await store.dispatch('auth/checkIfAdmin');

        if (authRequired && !loggedIn) {
            return next('/login');
        }

        if (forAdmins && !isAdmin) {
            return next('/');
        }

        if (forClients && isAdmin) {
            return next('/admin/requests/open');
        }

        // todo
        // zrobiłbym to fn/klase do aktualizacji breadcrumba
        // przekazuje obiekt routingu

        return next();
    });

    return router;
};