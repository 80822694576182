<template>
    <div class="product-info">
        <div class="product-info__details">
            <span class="product-info__details-date">{{ _date }}</span> <br/>
            <span class="product-info__status-type">{{ $t( getLastUpdatedStatusOfDevice.actionName ) }}:</span>
            <product-status-badge
                class="product-info__status"
                :variant="getLastUpdatedStatusOfDevice.badge"
                :icon-name="getLastUpdatedStatusOfDevice.icon"
                :label="getLastUpdatedStatusOfDevice.statusName"/>
        </div>
        <div class="product-info__product">
            <product-block-details
                @detailsClick="onDetailsButtonClick"
                :size="size"
                :device="device"
                :device-serial="device.deviceSerial"
                :device-name="device.deviceName"
                >
            </product-block-details>
        </div>
    </div>
</template>

<script lang="ts">
import Device from '@/models/Device';
import ProductBlockDetails from '@/components/devices/ProductBlockDetails.vue';
import ProductStatusBadge from '@/components/devices/ProductStatusBadge.vue';
import {ProductLogStatusPropsFactory} from '@/components/devices/ProductLogStatusPropsFactory';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import ioc from '@/services/ioc';
import Vue, {PropType} from 'vue';

export default Vue.extend({
    name: 'product-log-block',
    components: {ProductStatusBadge, ProductBlockDetails},
    props: {
        size: {
            type: String,
            default: 'medium',
        },
        deviceName: {
            type: String,
        },
        deviceSerial: String,
        device: Object as PropType<Device>,
        date: String,
    },
    methods: {
        onDetailsButtonClick({device}: {device: Device, deviceSerial: string, deviceName: string}) {
            this.$router.push({
                name: 'UserSingleDevice',
                params: {
                    id: String(device.id),
                },
            });
        },
    },
    computed: {
        _deviceName() {
            return this.device?.deviceName ?? this.deviceName;
        },
        _deviceSerial() {
            return this.device?.deviceSerial ?? this.deviceSerial;
        },
        _date() {
            if (this.date) return this.date;
            const date = new Date(this.device?.createdAt?.date ?? '');

            return date.toLocaleString();
        },
        getLastUpdatedStatusOfDevice() {
            const statusCalculators = ioc.get('service.statusCalculators') as StatusCalculators;
            const factory = new ProductLogStatusPropsFactory();
            const deviceStatus = statusCalculators.deviceStatusCalculator(this.device);
            let b = {
                actionName: 'cts.product.label-change-status-device',
                statusName: deviceStatus.statusLabel(),
                status: {
                    isApproved: this.device?.isApproved,
                    isRejected: this.device?.isRejected,
                },
            };
            if (this.device.request?.status?.isSet) {
                const status = statusCalculators.requestStatusWithCcCalculator(this.device.request);
                b = {
                    actionName: 'cts.product.label-change-status-device',
                    statusName: status.statusLabel(),
                    status: {
                        isApproved: this.device.request.isApproved,
                        isRejected: this.device.request.isRejected,
                    },
                };
            }

            return factory.create(b);
        },
    },
});
</script>
<style scoped lang="scss">
    .product-info {
        display: grid;
        width: 100%;
        grid-template-columns: 21% 77%;
        aspect-ratio: 795 / 120;
        grid-gap: 2%;
        margin-bottom: 1em;

        &__details {
            font-size: 0.875em;
            align-items: flex-start;
        }

        &__status-type {
            display: inline-block;
            margin-bottom: 0.5em;
        }

        &__details-date {
            display: inline-block;
            color: #9A9B9C;
            margin-bottom: 0.25em;
        }

        &__badge {
            width: 100%;
            justify-content: flex-start;
        }
    }
</style>