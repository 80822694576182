import MessageInterface from '@/services/message-factory/MessageInterface';
import Notification from '@/services/notification-system/Notification';
import NotificationType from '@/services/notification-system/NotificationType';

export default class MessageBuilder implements MessageInterface {
    message!: Notification;

    constructor() {
        this.reset();
    }

    reset(): MessageInterface {
        this.message = {
            text: '',
            type: NotificationType.success,
            data: {},
            returnType: 'string',
            render() {
                return '';
            },
        };

        return this;
    }

    setText(text: string): MessageInterface {
        this.message.text = text;

        return this;
    }

    setData(data: unknown): MessageInterface {
        this.message.data = data;

        return this;
    }

    setType(type: NotificationType): MessageInterface {
        this.message.type = type;

        return this;
    }

    setRender(render: () => string): MessageInterface {
        this.message.render = render;

        return this;
    }

    getMessage(): Notification {
        return {...this.message};
    }
}