import {AdminModuleState} from '@/store/admin/types/adminModuleState';
import devices from '@/store/admin/devices/devicesIndexModule';
import {StoreRootState} from '@/store/storeRootState';
import {Module} from 'vuex';
import messages from './messages/messagesIndexModule';
import requests from './requests/requestsIndexModule';
import clients from './users/adminClientsModule';

const adminModule: Module<AdminModuleState, StoreRootState> = {
    namespaced: true,
    modules: {
        requests,
        clients,
        devices,
        messages,
    },
};

export default adminModule;