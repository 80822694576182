<template>
    <div class="search-box">
        <search-input></search-input>
    </div>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';
import SearchInput from './SearchInput.vue';

@Component({
    components: {SearchInput},
})
export default class SearchBox extends Vue {}
</script>

<style lang="scss" scoped>
    .search-box {
        display: flex;
        align-items: center;
    }
</style>