<template>
    <header class="header">
        <div class="header__container container">
            <div class="header__left">
                <component :is="getMobileMenuButton()" v-if="getMobileMenuButton()"></component>
                <component class="header__logo" :is="getAppLogo()" v-if="getAppLogo()"/>
                <h1 class="header__title" v-if="getAppName()" v-html="getAppName()"></h1>
            </div>
            <nav class="header__nav">
                <slot name="header-nav"></slot>
            </nav>
        </div>
    </header>
</template>

<script lang="ts">
    import {Component} from 'vue-property-decorator';
    import BaseLayoutMixin from '@Framework/layouts/BaseLayoutMixin';

    @Component({})
    export default class BaseHeader extends BaseLayoutMixin {}
</script>

<style scoped lang="scss">
    .header {
        padding-top: 13.5px;
        padding-bottom: 13.5px;
        height: 64px;
        color: var(--layout-header-color);
        background-color: var(--layout-header-background-color);

        &__container {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__left {
            display: flex;
            align-items: center;
        }

        &__logo {
            margin-right: 8px;
        }

        &__title {
            color: #fff;
            font-weight: 300;
            margin: 0;
        }

        &__nav {
            display: flex;

            a {
                color: var(--layout-header-link-color);

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }
        @media (max-width: 1199px) {
            height: 32px;

            &__container {
                justify-content: center;
            }

            &__title {
                font-size: 24px;
                line-height: 32px;
            }

            &__nav {
                display: none;
            }

            &__logo {
                display: none;
            }
        }
    }

</style>
