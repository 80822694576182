import {DeviceStatusEnum} from '@/services/domain/statuses/enums/DeviceStatusEnum';

export const deviceStatusDictionary = new Map([
    [DeviceStatusEnum.PENDING, 'cts.ccstatus.unconfirmed'],
    [DeviceStatusEnum.APPROVED, 'cts.ccstatus.accepted'],
    [DeviceStatusEnum.REJECTED, 'cts.ccstatus.rejected'],
    [DeviceStatusEnum.ARCHIVED, 'cts.ccstatus.archived'],
    [DeviceStatusEnum.REQUEST, 'cts.ccstatus.submitted-for-complaint'],
]);

export const deviceStatusForAdminDictionary = new Map([
    [DeviceStatusEnum.PENDING, 'cts.ccstatus.unconfirmed'],
    [DeviceStatusEnum.APPROVED, 'cts.ccstatus.accepted'],
    [DeviceStatusEnum.REJECTED, 'cts.ccstatus.rejected'],
    [DeviceStatusEnum.ARCHIVED, 'cts.ccstatus.archived'],
    [DeviceStatusEnum.REQUEST, 'cts.ccstatus.submitted-for-complaint'],
]);