<template>
    <component :is="menuComponent" :items="menuItems"></component>
</template>

<script lang="ts">
    import {Vue, Component, Prop} from 'vue-property-decorator';
    import MenuItem from '@Framework/interfaces/MenuItem';
    import ioc from '@Framework/ioc';

    @Component
    export default class MenuRenderer extends Vue {
        @Prop() menu!: string;

        getMenu() {
            return (ioc.get('config.menu') as any).menus[this.menu];
        }

        get menuComponent() {
            return this.getMenu().component
        }

        get menuItems() {
            return this.getMenu()
                .items
                .map((menuItem: MenuItem) => ({
                    ...menuItem,
                    component: menuItem.component ? menuItem.component : 'li'
                }))
        }
    }
</script>
