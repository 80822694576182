<template>
    <img v-if="src === ''" :style="{'--photo-size': size + 'px'}" :src="noDevicePhotoFoundSrc"/>
    <img v-else :src="src" :style="{'--photo-size': size + 'px'}"/>
</template>

<script lang="ts">
import ImagesProvider from '@/services/domain/images/ImagesProvider';
import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import ioc from '@/services/ioc';

// @ts-ignore eslint-disable-next-line
// eslint-disable-next-line @typescript-eslint/no-var-requires
const NO_DEVICE_PHOTO_FOUND = require('@/assets/devices/no-device-photo-found.png');

@Component({})
export default class ProductImage extends Vue {
    @Prop({default: 0}) id!: number;

    @Prop({default: 100}) size!: number;

    noDevicePhotoFoundSrc = NO_DEVICE_PHOTO_FOUND;

    private imagesProvider!: ImagesProvider;

    src = '';

    @Watch('id', {immediate: true})
    async onDeviceIdChanged() {
        if (this.id !== 0) {
            this.src = this.id === 0
                ? NO_DEVICE_PHOTO_FOUND
                : await this.getImagesProvider().getProductImage(this.id);
        }
    }

    getImagesProvider() {
        if (!this.imagesProvider) {
            this.imagesProvider = ioc.get('provider.images') as ImagesProvider;
        }

        return this.imagesProvider;
    }
}
</script>

<style lang="scss" scoped>
    img {
        width: var(--photo-size, 100px);
        height: var(--photo-size, 100px);
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
    }
</style>