import Model from '@/models/Model';
import {DateType} from '@/services/domain/request/RequestType';

export type ClientTypeType = 'PERSON' | 'COMPANY';
export type UserRole = string;

type Client = {
    availableAt: DateType | null,
    clientType: ClientTypeType,
    companyName: string,
    createdAt: DateType | null,
    email: string;
    firstName: string,
    id: number,
    language: string,
    lastName: string,
    phone: string,
    reservedAt: DateType | null,
    ridNumber: string,
    roles: UserRole[]
    taxNumber: string,
    updatedAt: DateType | null,
};

export interface ClientInterface {
    availableAt: DateType,
    clientType: ClientTypeType,
    companyName: string,
    createdAt: DateType,
    email: string;
    firstName: string,
    id: number,
    language: string,
    lastName: string,
    phone: string,
    reservedAt: DateType,
    ridNumber: string,
    roles: UserRole[]
    taxNumber: string,
    updatedAt: DateType,
}

const emptyDateType = {
    date: '',
    timezone_type: 0,
    timezone: '',
};

export class ClientModel implements Model<Client>, ClientInterface {
    private client: Client;

    constructor(client?: Client) {
        this.client = client ?? ClientModel.getEmptyValue();
    }

    get availableAt(): DateType {
        return this.client?.availableAt ?? {...emptyDateType};
    }

    get clientType(): ClientTypeType {
        return this.client?.clientType ?? 'PERSON';
    }

    get companyName(): string {
        return this.client?.companyName ?? '';
    }

    get createdAt(): DateType {
        return this.client?.createdAt ?? {...emptyDateType};
    }

    get email(): string {
        return this.client?.email ?? '';
    }

    get firstName(): string {
        return this.client?.firstName ?? '';
    }

    get id(): number {
        return this.client?.id ?? 0;
    }

    get language(): string {
        return this.client?.language ?? '';
    }

    get lastName(): string {
        return this.client?.lastName ?? '';
    }

    get phone(): string {
        return this.client?.phone ?? '';
    }

    get reservedAt(): DateType {
        return this.client?.reservedAt ?? {...emptyDateType};
    }

    get ridNumber(): string {
        return this.client?.ridNumber ?? '';
    }

    get roles(): UserRole[] {
        return this.client?.roles ?? [];
    }

    get taxNumber(): string {
        return this.client?.taxNumber ?? '';
    }

    get updatedAt(): DateType {
        return this.client?.updatedAt ?? {...emptyDateType};
    }

    value(): Client {
        return this.client ?? ClientModel.getEmptyValue();
    }

    static getEmptyValue(): Client {
        return {
            availableAt: {...emptyDateType},
            clientType: 'PERSON',
            companyName: '',
            createdAt: {...emptyDateType},
            email: '',
            firstName: '',
            id: 0,
            language: '',
            lastName: '',
            phone: '',
            reservedAt: {...emptyDateType},
            ridNumber: '',
            roles: [],
            taxNumber: '',
            updatedAt: {...emptyDateType},
        };
    }
}

export default Client;