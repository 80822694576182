import {RequestCCStatusEnum} from '@/services/domain/statuses/enums/RequestCCStatusEnum';
import {CCRequestStatusType} from '@/services/domain/request/RequestType';
import Model from '@/models/Model';

export class CCStatusModel implements CCStatusModelInterface, Model<CCRequestStatusType> {
    constructor(private status: CCRequestStatusType, private ccRequestId: string, private labelPrefix: string = '') {
    }

    value(): CCRequestStatusType {
        return this.status;
    }

    getDescription(): string {
        return this.status.description ?? '';
    }

    submitDate(): string {
        return this.status.submitDate ?? '';
    }

    getLabel(): string {
        const prefix = this.labelPrefix ? `${this.labelPrefix}.` : '';
        if (!this.status.code) {
            if (Number(this.ccRequestId) > 0) {
                return `${prefix}ccstatus.sr`;
            }

            return `${prefix}ccstatus.undefined`;
        }
        if (!CCStatusModel.isValidStatusCode(this.status.code)) {
            return `${prefix}ccstatus.unknown`;
        }

        return `${prefix}ccstatus.${String(this.status.code).toLowerCase()}`;
    }

    getCode(): string {
        let {code} = this.status;
        if (!code) {
            if (Number(this.ccRequestId) > 0) {
                code = RequestCCStatusEnum.SR;
            }
        }

        return code ?? '';
    }

    private static isValidStatusCode(statusCode: string): boolean {
        const validCodes = Object.values(RequestCCStatusEnum) as string[];

        return validCodes.includes(statusCode);
    }
}
export interface CCStatusModelInterface {
    getCode(): string;

    submitDate(): string;

    getDescription(): string;

    getLabel(): string;
}