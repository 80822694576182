export default class LoadingService {
    private loadingCallbacks: ((status: boolean) => void)[] = [];

    public registerLoading(callback: (status: boolean) => void) {
        this.loadingCallbacks.push(callback);
    }

    public emitLoading(status: boolean): void {
        this.loadingCallbacks.forEach((loadingCallback) => {
            loadingCallback(status);
        });
    }

    public async runWithLoading(toRun: () => void): Promise<void> {
        this.emitLoading(true);
        await toRun();
        this.emitLoading(false);
    }
}