import AssetsApi from '@/services/api/AssetsApi';

export default class ImagesProvider {
    constructor(private readonly assetsApi: AssetsApi) {
    }

    async getProductImage(productUid: number) {
        const {hostName, path} = this.assetsApi.getProductImage(productUid);

        return `${hostName}${path}`;
    }
}