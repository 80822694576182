import RequestsProvider from '@/services/domain/request/RequestsProvider';
import StatusBadges from '@/services/domain/statuses-badge/StatusBadges';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import ioc from '@/services/ioc';
import {ActionContext, Module} from 'vuex';
import {ProductStatusBadgeProps} from '@/components/devices/ProductStatusBadgeProps';
import {RequestType} from '@/services/domain/request/RequestType';
import {StoreRootState} from '@/store/storeRootState';
import {formatDate} from '@/helpers/formatDate';
import {getField} from 'vuex-map-fields';

type ModuleState = {
    request: RequestType | null;
};

const requestModule: Module<ModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        request: null,
    },
    mutations: {
        SET_REQUEST: (state: ModuleState, request: RequestType) => {
            state.request = request;
        },
    },
    actions: {
        async loadRequest(
            context: ActionContext<ModuleState, StoreRootState>,
            uuid: RequestType['requestUuid'],
        ): Promise<null> {
            const requestsProvider = ioc.get('provider.adminRequestsProvider') as RequestsProvider;
            const request = await requestsProvider.getRequest(uuid);
            context.commit('SET_REQUEST', request);

            return null;
        },
    },
    getters: {
        description(state: ModuleState): string {
            return state.request?.device?.details?.description ?? '';
        },
        registerProductDate(state: ModuleState): Date | string {
            return formatDate(state.request?.device?.createdAt?.date, 'onlyDate');
        },
        files(state: ModuleState): string[] {
            return state.request?.device?.details?.documents
                ?.split(',')
                .filter((fileName: string) => fileName !== '') ?? [];
        },
        purchaseDate(state: ModuleState): string {
            return formatDate(state.request?.device.purchaseDate?.date, 'onlyDate');
        },
        statusBadge(state: ModuleState): ProductStatusBadgeProps | undefined {
            if (state.request) {
                const statusBadgeService = ioc.get('service.statusBadge') as StatusBadges;
                const statusCalculators = ioc.get('service.statusCalculators') as StatusCalculators;

                return statusBadgeService.create(
                    statusCalculators.requestStatusWithCcCalculator(state.request),
                );
            }

            return undefined;
        },
        clientAskForTemporaryDevice(state: ModuleState): boolean {
            return state.request?.clientAskForTemporaryDevice || false;
        },
        getField,
    },
};

export default requestModule;