import ParamsOptionsType, {convertOptionsToURLSearchParams} from '@/services/api/ParamsOptionsType';
import RequestsApiInterface from '@/services/api/RequestsApiInterface';
import ApiRequestObjectType from '@/services/api/ApiRequestObjectType';

export type RequestOptions = ParamsOptionsType & {
    uuid?: string;
    ridNumber?: string
};

export default class RequestsApi implements RequestsApiInterface {
    constructor(private readonly apiUrl: string) {
    }

    getUserRequest(options: RequestOptions): ApiRequestObjectType {
        const query = convertOptionsToURLSearchParams(options);

        return {
            method: 'get',
            hostName: this.apiUrl,
            path: `/devices_requests?${query.toString()}`,
        };
    }

    getRequest(uuid: string): ApiRequestObjectType {
        const query = new URLSearchParams();
        query.set('requestUuid', uuid);
        query.set('page', '0');
        query.set('limit', '1');

        return {
            method: 'get',
            hostName: this.apiUrl,
            path: `/devices_requests?${query.toString()}`,
        };
    }

    getRequests(options: RequestOptions): ApiRequestObjectType {
        const opt = {
            page: options.page ?? 0,
            limit: options.limit ?? 20,
            orderBy: options.orderBy ?? '',
            order: options.order ?? 'DESC',
            uuid: options.uuid ?? '',
            rid_number: options?.ridNumber ?? '',
        };
        const query = new URLSearchParams();
        query.set('page', String(opt.page));
        query.set('limit', String(opt.limit));
        if (opt.order && opt.orderBy) {
            query.set('order_by', `${opt.order.toLowerCase()}(${opt.orderBy})`);
        }
        if (opt.uuid) {
            query.set('requestUuid', opt.uuid);
        }

        return {
            method: 'get',
            hostName: this.apiUrl,
            path: `/devices_requests?${query.toString()}`,
        };
    }
}