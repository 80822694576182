import {formatDate} from '@/helpers/formatDate';
import NotificationService from '@/services/domain/NotificationService';
import RequestService from '@/services/domain/request/RequestService';
import RequestsProvider from '@/services/domain/request/RequestsProvider';
import {RequestType} from '@/services/domain/request/RequestType';
import StatusObjectType from '@/services/domain/statuses/models/StatusObject';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import ioc from '@/services/ioc';
import {StoreRootState} from '@/store/storeRootState';
import {ActionContext, Module} from 'vuex';
import {getField} from 'vuex-map-fields';

const request: Module<RequestModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        request: null as null | RequestType,
    },
    mutations: {
        SET_REQUEST: (state: RequestModuleState, payload: RequestType) => {
            state.request = payload;
        },
    },
    actions: {
        async loadRequest(
            context: ActionContext<RequestModuleState, StoreRootState>,
            uuid: RequestType['requestUuid'],
        ): Promise<null | RequestType> {
            const requestsProvider = ioc.get('provider.adminRequestsProvider') as RequestsProvider;
            const requestRecord = await requestsProvider.getRequest(uuid);
            if (requestRecord) {
                requestRecord.client = await context.dispatch(
                    'admin/clients/fetchClient',
                    requestRecord.ridNumber,
                    {root: true},
                );
                context.commit('SET_REQUEST', requestRecord);

                return requestRecord;
            }

            return null;
        },
        async acceptRequest(
            {state}: ActionContext<RequestModuleState, StoreRootState>,
            message: string,
        ): Promise<boolean> {
            const notificationService = ioc.get('service.notification') as NotificationService;
            const requestService = ioc.get('service.request') as RequestService;
            const translator = ioc.get('translator') as LanguageSwitcherInterface;
            if (!state.request) return false;
            const res = await requestService.approveRequest(state.request, message);

            if (res) {
                notificationService.success(translator.t('cts.alert.REQUEST_HAS_BEEN_ACCEPTED'));

                return true;
            }
            notificationService.error(translator.t('cts.alert.ERROR_CANNOT_ACCEPT_REQUEST'));

            return false;
        },
        async rejectRequest(
            {state}: ActionContext<RequestModuleState, StoreRootState>,
            message: string,
        ): Promise<boolean> {
            const notificationService = ioc.get('service.notification') as NotificationService;
            const requestService = ioc.get('service.request') as RequestService;
            if (!state.request) return false;
            const res = await requestService.rejectRequest(state.request, message);

            if (res) {
                notificationService.success('Urządzenie pomyślnie zostało odrzucone');

                return true;
            }
            notificationService.error('Odrzucanie urządzenie nie powiodło się');

            return false;
        },
    },
    getters: {
        description(state: RequestModuleState): string {
            return state.request?.device?.details?.description ?? '';
        },
        registerProductDate(state: RequestModuleState): Date | string {
            return formatDate(state.request?.device?.createdAt?.date, 'onlyDate');
        },
        files(state: RequestModuleState): string[] {
            return state.request?.device?.details?.documents
                ?.split(',')
                .filter((fileName: string) => fileName !== '') ?? [];
        },
        // proofDocument(): string {
        //     return 'Paragon lub faktura z innego źródła';
        // },
        purchaseDate(state: RequestModuleState): string {
            return formatDate(state.request?.device.purchaseDate?.date, 'onlyDate');
        },
        registerDate(state: RequestModuleState): string { // todo
            return formatDate(state.request?.createdAt?.date, 'onlyDate');
        },
        clientName(state: RequestModuleState): string {
            return `${state.request?.client?.firstName} ${state.request?.client?.lastName
            }${state.request?.client?.companyName
                ? `, ${state.request?.client?.companyName}`
                : ''}`;
        },
        status(state: RequestModuleState): StatusObjectType | undefined {
            if (state.request) {
                const statusCalculators = ioc.get('service.statusCalculatorsForAdmin') as StatusCalculators;
                const statusCalculator = statusCalculators.requestStatusWithCcCalculator(state.request);

                return statusCalculator.toValue();
            }

            return undefined;
        },
        clientAskForTemporaryDevice(state: RequestModuleState): boolean {
            return state.request?.clientAskForTemporaryDevice || false;
        },
        getField,
    },
};

export type RequestModuleState = {
    request: null | RequestType,
};

export type StatusBadgeType = {
    text: string;
    type: string;
};

export default request;