import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import {ConsentsURLReplacerInterface} from '@/services/domain/consents/interfaces/ConsentsURLReplacerInterface';
import {ConsentsProviderInterface} from '@/services/domain/consents/interfaces/ConsentsProviderInterface';

export default class ConsentsProvider implements ConsentsProviderInterface {
    constructor(
        private translator: LanguageSwitcherInterface,
        private urlMap: Record<string, string>,
        private consentsURLReplacer: ConsentsURLReplacerInterface,
    ) {}

    public getReplacementDeviceConsentURL(): string {
        const consent = this.translator.t('cts.repairview.checkbox-replacement-device');

        return this.consentsURLReplacer.replaceConsentURL(consent, this.urlMap);
    }
}