import Client from '@/models/Client';
import ClientApiInterface from '@/services/api/ClientApiInterface';
import ResponseDataExtractor from '@/services/domain/ResponseDataExtractor';
import ResponseHandler from '@/services/domain/ResponseHandler';
import SsoService from '@/services/SsoService';
import ioc from '@/services/ioc';
import {RequestManagerInterface} from '@/services/requestManager';
import {getCookie, setCookie} from '@/helpers/cookies';

export interface AuthServiceInterface {
    anonymousLogin: (ridToken: string, clientToken: string) => Promise<string>;
    getRidNumber: () => string;
    getRidToken: () => string | undefined;
    fetchMyClient: () => Promise<Client | null>;
    isLogged: () => boolean;
    login: (login: string, password: string) => Promise<string | undefined>;
    logout: () => void;
    parseJwtToken: (token: string) => ParsedJwtToken,
    sendAnonymousToken: (email: string) => Promise<string | undefined>
}

type ParsedJwtToken = any;

export default class AuthService implements AuthServiceInterface {
    constructor(
        private requestManager: RequestManagerInterface,
        private api: ClientApiInterface,
        private responseHandler: ResponseHandler,
        private dataExtractor: ResponseDataExtractor,
    ) {
    }

    getRidNumber(): string {
        const ridToken = this.getRidToken() ?? '';
        const parsedJwtToken = this.parseJwtToken(ridToken);
        if (parsedJwtToken?.data) {
            const {rid, loginSystem} = parsedJwtToken.data;

            return String(rid).replace(`${loginSystem}|`, '');
        }

        return '';
    }

    getRidToken(): string | undefined {
        return getCookie('rid_token', '') ?? '';
    }

    async fetchMyClient(): Promise<Client | null> {
        try {
            const response = await this.requestManager.call(
                this.api.fetchMyClient(),
                '',
                '',
            );
            const json = await response.json();
            if (this.responseHandler.handle(json, {
                displaySuccess: false,
            })) {
                return this.dataExtractor.extract(json) as Client;
            }
        } catch (e) {
            this.responseHandler.handleFatal(e);
        }

        return null;
    }

    isLogged(): boolean {
        const cookiesRidToken = getCookie('rid_token', '') !== '';
        if (!cookiesRidToken) ioc.get('store')?.commit('auth/loginFailure');

        return cookiesRidToken;
    }

    async login(login: string, password: string): Promise<string | undefined> {
        const service = ioc.get('service.ssoService') as SsoService;

        return service.login(login, password);
    }

    async anonymousLogin(ridToken: string, clientToken: string): Promise<string> {
        const {hostName, path, method} = this.api.loginAnonymous(ridToken, clientToken);
        const response = await this.requestManager.getMethodByString(method)(hostName, path, '');
        const json = await response.json();

        try {
            this.responseHandler.handle(json);
        } catch (e) {
            this.responseHandler.handleFatal(e);
        }

        return this.dataExtractor.extract(json) as string;
    }

    parseJwtToken(token: string): ParsedJwtToken {
        const parts = token.split('.');

        if (parts.length !== 3) return null;

        const header = JSON.parse(atob(parts[0]));
        const data = JSON.parse(atob(parts[1]));

        return {
            header,
            data,
        };
    }

    logout(): void {
        setCookie('rid_token', '', -1);
    }

    async sendAnonymousToken(email: string): Promise<string | undefined> {
        const response = await this.requestManager.call(
            this.api.sendAnonymousToken(email),
            '',
            '',
        );
        const json = await response.json();

        return this.dataExtractor.extract(json) as string;
    }
}