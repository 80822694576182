import defaultNoop from '@/helpers/defaultNoop';
import {RouteConfig} from 'vue-router';

function routesRequestsIndex(): RouteConfig[] {
    return [
        {
            path: '/user/requests',
            name: 'Requests',
            component: () => import(/* webpackChunkName: "requests" */ '@/views/user/requests/RequestsIndex.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-your-requests'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesSingleRequest(): RouteConfig[] {
    return [
        {
            path: '/user/requests/:id',
            name: 'UserSingleRequest',
            component: () => import(
                '@/views/user/requests/SingleRequest.vue'/* webpackChunkName: "user-single-request" */
            ),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'Requests', icon: '', title: 'cts.routes.routes-your-requests'},
                    {name: '', icon: '', title: 'cts.routes.routes-request'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesRepairRequest(): RouteConfig[] {
    return [
        {
            path: '/user/devices/:id/repair-request/',
            name: 'RepairRequest',
            component: () => import(
                '@/views/user/RepairRequestPage.vue'
                /* webpackChunkName: "products-repair-request" */
            ),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'Devices', icon: '', title: 'cts.routes.routes-your-products'},
                    {name: '', icon: '', title: 'cts.btn-request-repair'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesOrderParts(): RouteConfig[] {
    return [
        {
            path: '/user/order-parts/:id',
            name: 'OrderParts',
            component: () => import(/* webpackChunkName: "order-parts" */ '@/views/user/parts/OrderParts.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.homeview.order-parts'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

export function routesRequests(): RouteConfig[] {
    return [
        ...routesRequestsIndex(),
        ...routesSingleRequest(),
        ...routesRepairRequest(),
        ...routesOrderParts(),
    ];
}

export default defaultNoop;