import {StoreRootState} from '@/store/storeRootState';
import {ActionContext, Module} from 'vuex';
import Client, {ClientModel} from '@/models/Client';
import ParamsOptionsType from '@/services/api/ParamsOptionsType';
import RequestsProvider from '@/services/domain/request/RequestsProvider';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import ioc from '@/services/ioc';
import requestsTableFields from '@/store/admin/requests/tableFields';
import singleRequestModule from '@/store/admin/requests/requestModule';
import type {RequestTableRowType} from '@/store/admin/requests/RequestTableDatasetType';
import {RequestType} from '@/services/domain/request/RequestType';
import {formatDate} from '@/helpers/formatDate';

export type RequestsModuleState = {
    requests: RequestType[];
};

const requests: Module<RequestsModuleState, StoreRootState> = {
    namespaced: true,
    modules: {
        single: singleRequestModule,
    },
    state: {
        requests: Array<RequestType>(),
    },
    mutations: {
        SET_REQUESTS: (state: RequestsModuleState, payload: RequestType[]) => {
            state.requests = payload;
        },
    },
    actions: {
        async fetchRequests({commit}: ActionContext<RequestsModuleState, StoreRootState>, {
            page,
            limit,
            orderBy,
            order,
        }: ParamsOptionsType) {
            const requestsProvider = ioc.get('provider.adminRequestsProvider') as RequestsProvider;
            const requestsList = await requestsProvider.getAllRequests(page, limit, orderBy, order);

            if (requestsList) {
                commit('SET_REQUESTS', requestsList);

                return requestsList;
            }

            return [];
        },
    },
    getters: {
        newRequestsCount(state: RequestsModuleState) {
            return state.requests.reduce((acc: number, request) => acc + (request.isNew ? 1 : 0), 0);
        },
        requestsDataset(state: RequestsModuleState): RequestTableRowType[] {
            return state.requests.map((request: RequestType) => {
                const statusCalculators = ioc.get('service.statusCalculatorsForAdmin') as StatusCalculators;
                const client = new ClientModel(
                    {
                        ...request.client,
                        ridNumber: request.ridNumber,
                    } as Client,
                );

                return {
                    date: formatDate(request.createdAt.date),
                    client,
                    device: request.device,
                    request,
                    status: statusCalculators.requestStatusWithCcCalculator(request).toValue(),
                    process: statusCalculators.requestStatusCalculator(request).toValue(),
                };
            });
        },
        requestsTableRowsMeta(state: RequestsModuleState) {
            return state.requests.map((request: RequestType) => ({
                rowClass: request.isNew ? 'is-new' : '',
            }));
        },
        requestsTableFields() {
            return requestsTableFields();
        },
    },
};

export default requests;