type PaginableModuleStateType = {
    limit: number;
    page: number;
};

export function getPaginableModuleState(): {limit: number, page: number} {
    return {
        limit: 100,
        page: 0,
    };
}

export default PaginableModuleStateType;