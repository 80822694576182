import Client, {UserRole} from '@/models/Client';
import {AuthServiceInterface} from '@/services/AuthService';
import {StoreRootState} from '@/store/storeRootState';
import {CustomGetterTree} from '@/types/vuex-getters';
import {
    ActionContext, Commit, Dispatch, GetterTree, Module,
} from 'vuex';
import {setCookie} from '@/helpers/cookies';
import ioc from '@/services/ioc';
import {ADMIN_ROLES} from '@/constants';

type UserType = Client;
export type AuthModuleState = {
    status: {
        loggedIn: boolean;
    },
    user: UserType | null
};

type AuthModuleGetters = CustomGetterTree<GetterTree<AuthModuleState, StoreRootState>, {
    isAdmin: boolean
    roles: UserRole[]
}>;

function setSessionAfterLogin(loginKey: string) {
    setCookie('rid_token', loginKey, 60 * 60 * 24 * 14);
}

function clearSession() {
    setCookie('rid_token', '', -1);
}

function validateLoginKey(loginKey: string | undefined) {
    return typeof loginKey !== 'undefined';
}

const auth: Module<AuthModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        status: {loggedIn: (ioc.get('service.authService') as AuthServiceInterface).isLogged()},
        user: null,
    },
    mutations: {
        loginSuccess: (state: AuthModuleState) => {
            state.status.loggedIn = true;
        },
        loginFailure: (state: AuthModuleState) => {
            state.status.loggedIn = false;
        },
        setUser: (state: AuthModuleState, user: UserType) => {
            state.user = user;
        },
    },
    actions: {
        async nonAnonymousLogin({dispatch}: {dispatch: Dispatch}, {
            login,
            password,
        }: {login: string, password: string}): Promise<boolean> {
            const authService = ioc.get('service.authService') as AuthServiceInterface;
            const loginKey = await authService.login(login, password);

            return dispatch('login', loginKey);
        },
        async anonymousLogin({dispatch}: {dispatch: Dispatch}, {
            ridToken,
            clientToken,
        }: {ridToken: string, clientToken: string}): Promise<boolean> {
            const authService = ioc.get('service.authService') as AuthServiceInterface;
            const loginKey = await authService.anonymousLogin(ridToken, clientToken);

            return dispatch('login', loginKey);
        },
        async login({commit, dispatch}: {commit: Commit, dispatch: Dispatch}, loginKey: string | undefined) {
            if (validateLoginKey(loginKey)) {
                setSessionAfterLogin(<string>loginKey);
                const result = await dispatch('fetchAndSetUserData');

                if (!result) {
                    clearSession();
                    commit('loginFailure');

                    return false;
                }
                commit('loginSuccess');

                return true;
            }
            clearSession();
            commit('loginFailure');

            return false;
        },
        async fetchAndSetUserData({commit, dispatch}: {commit: Commit, dispatch: Dispatch}) {
            const authService = ioc.get('service.authService') as AuthServiceInterface;
            const ridNumber = authService.getRidNumber();

            if (ridNumber) {
                const clientData = await authService.fetchMyClient();
                if (!clientData) {
                    await dispatch('logout', {reload: false});

                    return false;
                }
                commit('setUser', clientData);

                return clientData;
            }
            await dispatch('logout', {reload: false});

            return false;
        },
        async logout({commit}: {commit: Commit}, {reload} = {reload: false}) {
            await (ioc.get('service.authService') as AuthServiceInterface).logout();
            commit('setUser', null);
            console.log('logged out');
            //            commit('loginFailure')
            if (reload) document.location.reload();
        },
        async requestAnonymousLoginToken(
            _: ActionContext<AuthModuleState, StoreRootState>,
            email: string,
        ): Promise<string | undefined> {
            const authService = ioc.get('service.authService') as AuthServiceInterface;

            return authService.sendAnonymousToken(email);
        },
        async checkIfAdmin({dispatch, state, getters}: ActionContext<AuthModuleState, StoreRootState>) {
            if (!state.user) await dispatch('fetchAndSetUserData');

            return getters.isAdmin;
        },
    },
    getters: {
        isAdmin: (state: AuthModuleState, getters) => ADMIN_ROLES.some((role: string) => getters.roles.includes(role)),
        roles: (state: AuthModuleState) => state.user?.roles ?? [],
    } as AuthModuleGetters,
};
export default auth;