import ApiResponseType from '@/services/api/ApiResponseType';
import bodyMap from '@/services/domain/responseDataExtractorBodyMap';

class ResponseDataExtractor implements ResponseDataExtractorInterface {
    extract(apiResponse: ApiResponseType, whatToExtract = 'body'): unknown {
        if (ResponseDataExtractor.verifyResponseType(apiResponse)) {
            switch (whatToExtract) {
                case 'statusCode':
                    return ResponseDataExtractor.extractStatusCode(apiResponse);
                case 'status':
                    return ResponseDataExtractor.extractStatus(apiResponse);
                case 'type':
                    return ResponseDataExtractor.extractType(apiResponse);
                case 'bodyType':
                    return ResponseDataExtractor.extractBodyType(apiResponse);
                case 'body':
                    return ResponseDataExtractor.extractBody(apiResponse);
                default:
                    return undefined;
            }
        }

        return undefined;
    }

    private static verifyResponseType(apiResponse: ApiResponseType): true | never {
        switch (apiResponse.type) {
            case 'success':
            case 'error':
                return true;
            default:
                throw new Error('Unhandled response type');
        }
    }

    private static extractBody(apiResponse: ApiResponseType): unknown {
        if (apiResponse.type === 'success') {
            const bodyKey = bodyMap.get(apiResponse.bodyType);
            if (bodyKey) {
                return apiResponse.body[bodyKey];
            }

            return apiResponse.body;
        }

        return apiResponse.body;
    }

    private static extractStatusCode(apiResponse: ApiResponseType): number {
        return ResponseDataExtractor.returnIfExist<number>(apiResponse.statusCode);
    }

    private static extractStatus(apiResponse: ApiResponseType): boolean {
        return ResponseDataExtractor.returnIfExist<boolean>(apiResponse.status);
    }

    private static extractType(apiResponse: ApiResponseType): string {
        return ResponseDataExtractor.returnIfExist<string>(apiResponse.type);
    }

    private static extractBodyType(apiResponse: ApiResponseType): string {
        return ResponseDataExtractor.returnIfExist<string>(apiResponse.bodyType);
    }

    private static returnIfExist<T>(value: T): T {
        if (value != null) {
            return value;
        }
        throw new Error('Field does not exist in provided response');
    }
}

export interface ResponseDataExtractorInterface {
    extract(apiResponse: ApiResponseType, whatToExtract?: string): unknown;
}

export default ResponseDataExtractor;