<template>
    <div class="application-root">
        <ioc-provider>
            <component :is="getAppLayout()">
                <slot v-for="(_, name) in $slots" :name="name" :slot="name"/>
                <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
                    <slot :name="name" v-bind="slotData"/>
                </template>
                <slot></slot>
            </component>
        </ioc-provider>
        <slot></slot>
    </div>
</template>

<script lang="ts">
    import {Vue, Component} from 'vue-property-decorator';
    import ioc from '@Framework/ioc';

    @Component
    export default class ApplicationRoot extends Vue {
        getAppLayout() {
            return (ioc.get('config.layout') as any).layout.component;
        }
    }
</script>
