import ioc from '@/services/ioc';
import SparePartsService, {OrderPartsFullFormDataType} from '@/services/sparePartsService';
import {StoreRootState} from '@/store/storeRootState';
import {SparePartsModuleState} from '@/store/user/types/sparePartsModuleState';
import {ActionContext, Module} from 'vuex';

const sparePartsModule: Module<SparePartsModuleState, StoreRootState> = {
    namespaced: true,
    actions: {
        async sendSparePartsRequest(
            {dispatch, rootState}: ActionContext<SparePartsModuleState, StoreRootState>,
            data: OrderPartsFullFormDataType,
        ) {
            const productData = await dispatch('user/products/fetchProduct', data.product, {root: true});
            const clientData = rootState.auth.user;
            if (!clientData) throw new Error('User in authModule is null');
            const sparePartsService = () => ioc.get('service.sparePartsService') as SparePartsService;

            await sparePartsService().sendSparePartsRequest(data, clientData, productData);
        },
    },
};

export default sparePartsModule;