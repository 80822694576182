import toFormData from '@/helpers/toFormData';
import ApiResponseType from '@/services/api/ApiResponseType';
import ClientApiInterface from '@/services/api/ClientApiInterface';
import {AuthServiceInterface} from '@/services/AuthService';
import {AddressType, FullAddressFormData} from '@/services/domain/forms/contactForm/ContactFormSchemaProvider';
import {RepairRequestFormData} from '@/services/domain/forms/repairRequestForm/RepairRequestFormSchemaProvider';
import {RequestManagerInterface} from '@/services/requestManager';

export type AnotherAddressesStatus = {
    deliveryAddress: boolean,
    pickupAddress: boolean
};

export default class RequestRegister {
    constructor(
        private requestManager: RequestManagerInterface,
        private api: ClientApiInterface,
        private authService: AuthServiceInterface,
    ) {
    }

    async register(
        repairRequestFormData: RepairRequestFormData,
        contactFormData: FullAddressFormData,
        anotherAddresses: AnotherAddressesStatus,
    ): Promise<ApiResponseType> {
        const formData = this.prepareFormData(repairRequestFormData, contactFormData, anotherAddresses);
        const res = await this.call(formData);

        return res.json();
    }

    private async call(formData: FormData) {
        const {method, hostName, path} = this.api.registerRequest();

        return this.requestManager.getMethodByString(method)(hostName, path, formData);
    }

    private prepareFormData(
        repairRequestFormData: RepairRequestFormData,
        contactFormData: FullAddressFormData,
        anotherAddressesStatus: AnotherAddressesStatus,
    ): FormData {
        const {
            description, additionalDescription, clientAskForTemporaryDevice, product, files,
        } = repairRequestFormData;
        const {phone, addresses} = contactFormData;

        const data = {
            rawlplugId: this.authService.getRidNumber(),
            tools: [
                {
                    tool: {
                        id: product,
                    },
                    requestDetails: {
                        description: RequestRegister.joinDescriptionWithPhoneAndAdditionalDescription(
                            description,
                            additionalDescription,
                            phone,
                        ),
                        clientAskForTemporaryDevice,
                        files,
                    },
                },
            ],
            person: {},
            address: RequestRegister.prepareAddressesData(addresses, anotherAddressesStatus),
        };

        return toFormData({
            clientRequests: data,
        });
    }

    /**
     * Łączymy opis z telefonem ponieważ na ten moment nie obsługujemy telefonu osobno
     * opis służy do opisania problemu, nie wszyscy będą wiedzieć żeby wpisać tam telefon.
     * // WEB-5717
     *
     * @param description
     * @param additionalDescription
     * @param phone
     * @private
     */
    private static joinDescriptionWithPhoneAndAdditionalDescription(
        description: string,
        additionalDescription: string,
        phone: string,
    ) {
        return `${description}\n\nInformacje dodatkowe:\n${additionalDescription}\ntel. ${phone}`;
    }

    private static prepareAddressesData(addresses: AddressType[], anotherAddressesStatus: AnotherAddressesStatus) {
        let clientAddress = addresses[0];
        let pickupAddress = addresses[1];
        let deliveryAddress = addresses[2];

        clientAddress = {
            ...clientAddress,
            addressId: pickupAddress.addressId,
        };

        pickupAddress = {
            ...pickupAddress,
            addressId: pickupAddress.addressId,
        };

        if (!anotherAddressesStatus.deliveryAddress) {
            deliveryAddress = {
                ...deliveryAddress,
                addressId: pickupAddress.addressId,
            };
        }

        return [clientAddress, pickupAddress, deliveryAddress];
    }
}