import defaultNoop from '@/helpers/defaultNoop';
import {RouteConfig} from 'vue-router';
import {
    tryRedirectToRoute,
    isLogged,
    hasHash,
} from '@/router/routerUtils';

function routesAnonRepairRequest(): RouteConfig[] {
    return [
        {
            path: '/repair-request',
            name: 'AnonRepairRequest',
            component: () => import(
                '@/views/anonymous/AnonRepairRequest.vue'
                /* webpackChunkName: "anon-repair-request" */
            ),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-anon-request-fix'},
                ],
                routeFor: ['PUBLIC'],
            },
            beforeEnter: tryRedirectToRoute([
                {
                    tryFunction: hasHash,
                    location: {name: 'AnonRegisterConfirmation'},
                    args: {hash: 'register-confirmation'},
                },
                {
                    tryFunction: hasHash,
                    location: {name: 'AccountConfirmation'},
                    args: {hash: 'account-confirmation'},
                },
                {
                    tryFunction: hasHash,
                    location: {name: 'AnonSuccessPage'},
                    args: {hash: 'success-page'},
                },
                {
                    tryFunction: isLogged,
                    location: {name: 'RepairRequest', params: {id: '-1'}},
                },
            ]),
        },
    ];
}

function routesAnonRegisterDevice(): RouteConfig[] {
    return [
        {
            path: '/register-device',
            name: 'AnonRegisterDevice',
            component: () => import(
                '@/views/anonymous/AnonRegisterDevice.vue'
                /* webpackChunkName: "anon-register-device" */
            ),
            beforeEnter: tryRedirectToRoute([
                {
                    tryFunction: hasHash,
                    location: {name: 'AnonRegisterConfirmation'},
                    args: {hash: 'register-confirmation'},
                },
                {
                    tryFunction: hasHash,
                    location: {name: 'AccountConfirmation'},
                    args: {hash: 'account-confirmation'},
                },
                {
                    tryFunction: hasHash,
                    location: {name: 'AnonSuccessPage'},
                    args: {hash: 'success-page'},
                },
                {
                    tryFunction: isLogged,
                    location: {name: 'NewDevice', params: {id: '-1'}},
                },
            ]),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-anon-register-product'},
                ],
                routeFor: ['PUBLIC'],
            },
        },
    ];
}

function routesAnonOrderParts(): RouteConfig[] {
    return [
        {
            path: '/order-parts',
            name: 'AnonOrderParts',
            component: () => import(
                '@/views/anonymous/AnonOrderParts.vue'
                /* webpackChunkName: "anon-order-parts" */
            ),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-anon-order-parts'},
                ],
                routeFor: ['PUBLIC'],
                beforeEnter: tryRedirectToRoute([
                    {
                        tryFunction: isLogged,
                        location: {name: 'OrderParts', params: {id: '-1'}},
                    },
                ]),
            },
        },
    ];
}

function routesAnonManual(): RouteConfig[] {
    return [
        {
            path: '/manuals',
            name: 'AnonDeviceManuals',
            component: () => import(
                '@/views/user/devices/DeviceManuals2/DeviceManuals2.vue'
                /* webpackChunkName: "anon-device-manuals" */
            ),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-anon-manuals'},
                ],
                routeFor: ['PUBLIC'],
            },
            beforeEnter: tryRedirectToRoute([
                {
                    tryFunction: isLogged,
                    location: {name: 'DeviceManuals'},
                },
            ]),
        },
    ];
}

function routesAccountConfirmation(): RouteConfig[] {
    return [
        {
            path: '/account-confirmation',
            name: 'AccountConfirmation',
            component: () => import(
                '@/views/returnPages/AccountConfirmation.vue'
                /* webpackChunkName: "account-confirmation" */
            ),
            meta: {
                routeFor: ['PUBLIC'],
            },
            beforeEnter: tryRedirectToRoute([
                {
                    tryFunction: isLogged,
                    location: {name: 'Home'},
                },
            ]),
        },
    ];
}

function routesAnonRegisterConfirmation(): RouteConfig[] {
    return [
        {
            path: '/register-confirmation',
            name: 'AnonRegisterConfirmation',
            component: () => import(
                '@/views/returnPages/RegisterConfirmation.vue'
                /* webpackChunkName: "anon-register-confirmation" */
            ),
            meta: {
                routeFor: ['PUBLIC'],
            },
        },
    ];
}

function routesAnonSuccessPage(): RouteConfig[] {
    return [
        {
            path: '/succcess-page',
            name: 'AnonSuccessPage',
            component: () => import(
                '@/views/returnPages/AnonSuccessPage.vue'
                /* webpackChunkName: "anon-success-page" */
            ),
            meta: {
                routeFor: ['PUBLIC'],
            },
        },
    ];
}

export function routesAnon(): RouteConfig[] {
    return [
        ...routesAnonRepairRequest(),
        ...routesAnonRegisterDevice(),
        ...routesAnonOrderParts(),
        ...routesAnonManual(),
        ...routesAnonRegisterConfirmation(),
        ...routesAccountConfirmation(),
        ...routesAnonSuccessPage(),
    ];
}

export default defaultNoop;