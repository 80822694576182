import VueRouter, {RouteConfig, RouterOptions} from 'vue-router';
import ioc from '@Framework/ioc';

const defaultRouterOptions = {
    mode: 'history',
    base: '/',
}

export default function installRouter(Vue: any, options = {}) {
    Vue.use(VueRouter);
    const routes: Array<RouteConfig> = (ioc.get('config.router') as any).routes;

    return new VueRouter({
        ...defaultRouterOptions,
        ...options,
        routes
    } as RouterOptions);
}
