import defaultNoop from '@/helpers/defaultNoop';
import {RouteConfig} from 'vue-router';

function routesUsersIndex(): RouteConfig[] {
    return [
        {
            path: '/admin/users',
            name: 'UsersIndex',
            component: () => import(/* webpackChunkName: "users-index" */ '@/views/admin/users/UsersIndex.vue'),
            meta: {
                crumbs: [
                    {name: 'RequestsOpenIndex', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-users'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

function routesSingleUser(): RouteConfig[] {
    return [
        {
            path: '/admin/users/:id',
            name: 'SingleUser',
            component: () => import(/* webpackChunkName: "single-user" */ '@/views/admin/users/SingleUser.vue'),
            meta: {
                crumbs: [
                    {name: 'RequestsOpenIndex', icon: 'home', title: ''},
                    {name: 'UsersIndex', icon: '', title: 'cts.routes.routes-users'},
                    {name: '', icon: '', title: 'cts.routes.routes-user'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

export function routesUsers(): RouteConfig[] {
    return [
        ...routesSingleUser(),
        ...routesUsersIndex(),
    ];
}

export default defaultNoop;