import MenuButton from '@/components/menu/MenuButton.vue';
import BaseLayout from '@Framework/layouts/base-layout/index.vue';
import AsideNav from '@/components/AsideNav.vue';
import BaseHeader from '@Framework/layouts/base-layout/BaseHeader.vue';
import BaseAdminTopBar from '@Framework/layouts/base-layout/BaseAdminTopBar.vue';
import ioc from '@/services/ioc';

ioc.set(
    'container.header',
    function getBaseAdminTopBar() {
        return BaseAdminTopBar;
    },
    {
        args: [],
    },
);

export default () => ({
    layout: {
        component: BaseLayout,
        slots: {
            header: [
                {
                    component: BaseHeader,
                    props: {},
                    templateProviderName: ['header-nav'],
                },
                {
                    component: BaseAdminTopBar,
                    props: {},
                    templateProviderName: ['top-bar'],
                },
            ],
        },
    },
    asideComponent: AsideNav,
    mobileMenuButton: MenuButton,
});