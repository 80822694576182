<template>
    <div class="client-field">
        <a href="#" class="link" @click.prevent.stop="onClick">{{ client.firstName }} {{ client.lastName }} </a><br/>
        <span class="client-field__company">{{ client.companyName }}</span>
    </div>
</template>

<script lang="ts">
import TableFieldComponentInterface from '@/components/tables/TableFieldComponentInterface';
import {TableCellData, TableRowData} from '@/services/table-system/Table';
import TableField from '@/services/table-system/TableField';
import {Vue, Component, Prop} from 'vue-property-decorator';
import Client from '@/models/Client';

@Component({})
export default class ClientTableField extends Vue implements TableFieldComponentInterface {
    @Prop({required: true}) cellData!: TableCellData;

    @Prop({required: true}) rowData!: TableRowData;

    @Prop({required: true}) field!: TableField;

    get client(): Client {
        return this.cellData as Client;
    }

    onClick(): void {
        this.$emit('click');
    }
}
</script>

<style scoped lang="scss">
    a {
        color: #F05600;
    }

    .client-field {
        min-width: 130px;

        &__company {
            font-weight: 300;
        }
    }
</style>