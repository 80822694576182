import toFormData from '@/helpers/toFormData';
import Device, {DeviceDocumentType} from '@/models/Device';
import ClientApiInterface from '@/services/api/ClientApiInterface';
import {RequestManagerInterface} from '@/services/requestManager';
import {PurchaseProofType} from '@/store/user/devices/PurchaseProofType';

export type DeviceData = {
    purchaseCountry: string;
    deviceName: string;
    deviceFullName: string;
    deviceUid: string;
    deviceSerial: string,
    documentType: DeviceDocumentType;
    invoiceNumber: string;
    proofFiles: unknown[];
    cannotAddProofFile: boolean;
    purchaseProofType: PurchaseProofType | '';
    purchaseDate: string;
    proofFileDocumentNumber: string;
    purchaseType: string;
    documentDetails: {
        expiredDate: string;
    } | Record<string, never>
};

export default class DeviceRegistrant {
    constructor(
        private requestManager: RequestManagerInterface,
        private clientApi: ClientApiInterface,
        private deviceMapper: any,
    ) {}

    async register(formData: DeviceData): Promise<false | Device> {
        const obj = {
            tools: [formData],
        };
        const data = toFormData(obj);
        const {hostName, path, method} = this.clientApi.registerDevice();
        const registerDevice = this.requestManager.getMethodByString(method);
        const result = await registerDevice(hostName, path, data);
        const json = await result.json();

        if (json?.body?.devices) {
            return this.deviceMapper.mapDeviceForClient(json.body.devices[0]);
        }

        return false;
    }
}