import FilterObject from '@/services/filterer/FilterObject';

export const createFilter = (filter: FilterObject): Filter => (value: any) => {
    const valueToFilter = filter.property ? value[filter.property] : value;

    switch (filter.sign) {
        case '=':
            return valueToFilter === filter.value;
        case '>':
            return valueToFilter > filter.value;
        case '<':
            return valueToFilter < filter.value;
        case '>=':
            return valueToFilter >= filter.value;
        case '<=':
            return valueToFilter <= filter.value;
        case 'LIKE':
        case 'like':
            return String(valueToFilter).toLowerCase().includes(String(filter.value).toLowerCase());
        default:
            return false;
    }
};

export const filterRecord = (filters: Filter[]) => (record: any) => filters.map((filter: Filter) => filter(record))
    .every(Boolean);

class Filterer {
    filters: Filter[] = [];

    doFilter(records: any[]) {
        return records.filter(filterRecord(this.filters));
    }

    addFilter(filter: FilterObject) {
        this.filters.push(createFilter(filter));
    }
}

interface Filter {
    (value: any): boolean
}

export default Filterer;