<template>
    <div v-if="status">
        <router-link :to="{name: 'SingleDevice', params: {id: device.id}}"
                     class="link"
                     v-if="status.code === DeviceStatusEnum.PENDING">
            {{ $t(status.label) }}
        </router-link>
        <router-link :to="getRequestRouterOptions()"
                     class="link"
                     v-else-if="status.code === DeviceStatusEnum.REQUEST">
            {{ $t(status.label) }}
        </router-link>
        <span v-else>
            {{ $t(status.label) }}
        </span>
    </div>
</template>

<script lang="ts">
import Device from '@/models/Device';
import {DeviceStatusEnum} from '@/components/devices/ProductLogStatusPropsFactory';
import StatusObjectType from '@/services/domain/statuses/models/StatusObject';
import {TableCellData, TableRowData} from '@/services/table-system/Table';
import TableField from '@/services/table-system/TableField';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {RawLocation} from 'vue-router';
import TableFieldComponentInterface from '../TableFieldComponentInterface';

@Component
export default class DeviceProcessField extends Vue implements TableFieldComponentInterface {
    @Prop() rowData!: TableRowData;

    @Prop() cellData!: TableCellData;

    @Prop() field!: TableField;

    DeviceStatusEnum = DeviceStatusEnum;

    getRequestRouterOptions(): RawLocation {
        return {name: 'SingleRequest', params: {id: String(this.device.request?.requestUuid)}};
    }

    get status(): StatusObjectType {
        return this.cellData as StatusObjectType;
    }

    get device(): Device {
        return this.rowData.device as Device;
    }
}
</script>