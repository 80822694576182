/* eslint-disable max-len */

export type ResourceType = {
    lang: string;
    consents: {
        [key: string]: string;
    };
    [key: string]: any;
};

const resourceUrls:ResourceType[] = [
    {
        lang: 'en',
        consents: {
            'replacement-device-terms': 'https://rawl-assets.com/static-files/central-tool-service/en/encentral-tool-service-replacement-device-terms-and-conditions.pdf',
            'terms-and-conditions': 'https://rawl-assets.com/static-files/central-tool-service/en/central-tool-service-terms-and-conditions.pdf',
            'privacy-policy': 'https://old.rawlplug.com/en/general-terms',
        },
    },
    {
        lang: 'pl',
        consents: {
            'replacement-device-terms': 'https://rawl-assets.com/static-files/central-tool-service/pl/central-tool-service-replacement-device-terms-and-conditions.pdf',
            'terms-and-conditions': 'https://rawl-assets.com/static-files/central-tool-service/pl/central-tool-service-terms-and-conditions.pdf',
            'privacy-policy': 'https://www.rawlplug.com/pl/polityka-prywatnosci-i-bezpieczenstwa',
        },
    },
];

export default resourceUrls;