import MobileMenu from '@/components/menu/MobileMenu.vue';
import MessagesMenuItem from '@/components/menu-items/MessagesMenuItem.vue';
import AdminDevicesMenuItem from '@/components/menu-items/AdminDevicesMenuItem.vue';
import AdminRequestsMenuItem from '@/components/menu-items/AdminRequestsMenuItem.vue';
import AsideMenu from '@Framework/components/AsideMenu.vue';
import ioc from '@/services/ioc';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';

export default (): {menus: any;} => {
    const translator = ioc.get('translator') as LanguageSwitcherInterface;
    const t = translator.t.bind(translator);

    function getClientMenuItems() {
        return [
            {
                to: '/', text: 'Start',
            },
            {
                to: '/user/devices', text: t('cts.client-menu.devices'),
            },
            {
                to: '/user/requests', text: t('cts.client-menu.your-requests'),
            },
            {
                to: '/user/addresses', text: t('cts.client-menu.addresses'),
            },
            {
                to: '/user/messages', text: `${t('cts.admin-menu.messages')} `, component: MessagesMenuItem,
            },
        ];
    }

    function getAdminMenuItems() {
        return [
            {
                to: '/admin/requests/open',
                text: t('cts.admin-menu.open-requests'),
                component: AdminRequestsMenuItem,
            },
            {
                to: '/admin/devices',
                text: t('cts.admin-menu.devices'),
                component: AdminDevicesMenuItem,
            },
            {
                to: '/admin/users',
                text: t('cts.admin-menu.users'),
            },
            {
                to: '/admin/messages',
                text: `${t('cts.admin-menu.messages')} `,
                component: MessagesMenuItem,
            },
        ];
    }

    function getAnonymousMobileMenuItems() {
        return [
            {
                to: '/register-device',
                text: `${t('cts.homeview.register-product')}`,
            },
            {
                to: '/repair-request',
                text: `${t('cts.homeview.request-repair')}`,
            },
            {
                to: '/order-parts',
                text: `${t('cts.homeview.order-parts')}`,
            },
            {
                to: '/manuals',
                text: `${t('cts.homeview.manuals-download')}`,
            },
        ];
    }

    return {
        menus: {
            admin: {
                component: AsideMenu,
                items: getAdminMenuItems(),
            },
            client: {
                component: AsideMenu,
                items: getClientMenuItems(),
            },
            'client-mobile': {
                component: MobileMenu,
                items: getClientMenuItems(),
            },
            'admin-mobile': {
                component: MobileMenu,
                items: getAdminMenuItems(),
            },
            'anonymous-mobile': {
                component: MobileMenu,
                items: getAnonymousMobileMenuItems(),
            },
        },
    };
};