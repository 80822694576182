const bodyMap = new Map();
bodyMap.set('DevicesRequestResponse', 'requests');
bodyMap.set('DevicesResponse', 'devices');
bodyMap.set('ClientsResponse', 'clients');
bodyMap.set('ClientResponse', 'client');
bodyMap.set('Search', 'results');
bodyMap.set('DevicesRequestResponse', 'requests');
bodyMap.set('AddressResponse', 'addresses');
bodyMap.set('AccountResetResponse', 'message');
bodyMap.set('LoginResponse', 'key');
bodyMap.set('LoginAnonymousResponse', 'key');
bodyMap.set('RegisterRequest', 'data');
bodyMap.set('MessagesRequest', 'messages');

export default bodyMap;