import {Route, RouteConfig} from 'vue-router';
import {routesRequests} from '@/router/admin/requestsRoutes';
import {routesDevices} from '@/router/admin/devicesRoutes';
import {routesMessages} from '@/router/admin/messagesRoutes';
import {routesUsers} from '@/router/admin/usersRoutes';

function routesSearch(): RouteConfig[] {
    return [
        {
            path: '/search/:query',
            name: 'Search',
            component: () => import(/* webpackChunkName: "search" */ '@/views/user/Search.vue'),
            props: (route: Route) => ({type: route.query.type}),
        },
    ];
}

export function routesAdmin(): RouteConfig[] {
    return [
        ...routesRequests(),
        ...routesDevices(),
        ...routesMessages(),
        ...routesSearch(),
        ...routesUsers(),
    ];
}

export default routesAdmin;