import ApiRequestObjectType from '@/services/api/ApiRequestObjectType';
import SsoApiInterface from '@/services/api/SsoApiInterface';

export default class SsoApi implements SsoApiInterface {
    constructor(private readonly apiUrl: string) {
    }

    login(): ApiRequestObjectType {
        return {
            method: 'post',
            hostName: this.apiUrl,
            path: '/login',
        };
    }

    reset(): ApiRequestObjectType {
        return {
            method: 'post',
            hostName: this.apiUrl,
            path: '/account/reset',
        };
    }
}