import DeviceTableField from '@/components/tables/fields/DeviceTableField.vue';
import RequestCCStatusField from '@/components/tables/fields/RequestCCStatusField.vue';
import ShowActionButton from '@/components/tables/fields/ShowActionButton.vue';

export default [
    {
        name: 'date',
        title: 'cts.table.table-date',
    },
    {
        name: 'device',
        title: 'cts.table.table-device',
        component: DeviceTableField,
    },
    {
        name: 'status',
        title: 'cts.table.table-status',
        component: RequestCCStatusField,
    },
    // {
    //     name: 'process',
    //     title: 'Proces',
    // },
    {
        name: 'actions',
        title: 'cts.table.table-actions',
        component: ShowActionButton,
    },
];