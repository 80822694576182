import {RequestOptions} from '@/services/api/RequestsApi';
import {OrderType} from '@/services/domain/request/RequestsProvider';

type ParamsOptionsType = {
    page: number;
    limit: number;
    orderBy: string;
    order: OrderType;
};

export function getDefaultParams(): ParamsOptionsType {
    return {
        page: 0,
        limit: 50,
        order: 'DESC',
        orderBy: '',
    };
}

export function convertOptionsToURLSearchParams(options: Partial<RequestOptions>): URLSearchParams {
    const query = new URLSearchParams();
    if (options.ridNumber) {
        query.set('rid_number', String(options.ridNumber));
    }
    query.set('page', String(options.page));
    query.set('limit', String(options.limit));
    if (options.order && options.orderBy) {
        query.set('order_by', `${options.order.toLowerCase()}(${options.orderBy})`);
    }
    if (options.uuid) {
        query.set('requestUuid', options.uuid);
    }

    return query;
}

export default ParamsOptionsType;