import {DeviceData} from '@/services/domain/device/DeviceRegistrator';
import {DeviceDocumentType} from '@/models/Device';
import NewDeviceFormDataType from './NewDeviceFormDataType';

class RentalInvoiceFormToDeviceDataMapper {
    map(data: NewDeviceFormDataType): DeviceData {
        return {
            purchaseCountry: data.purchaseCountry,
            deviceName: data.deviceFullName,
            deviceSerial: data.deviceSerial,
            deviceFullName: data.deviceFullName,
            deviceUid: data.deviceUid,
            documentType: data.documentType,
            invoiceNumber: data.rentalInvoice as string,
            proofFiles: data.proofFiles,
            cannotAddProofFile: data.cannotAddProofFile,
            purchaseProofType: data.purchaseProofType,
            purchaseDate: '',
            proofFileDocumentNumber: data.proofFileDocumentNumber,
            purchaseType: data.purchaseType,
            documentDetails: {
                expiredDate: data.purchaseDate,
            },
        };
    }
}

class InvoiceFormToDeviceDataMapper {
    map(data: NewDeviceFormDataType): DeviceData {
        return {
            purchaseCountry: data.purchaseCountry,
            deviceName: data.deviceFullName,
            deviceSerial: data.deviceSerial,
            deviceFullName: data.deviceFullName,
            deviceUid: data.deviceUid,
            documentType: data.documentType,
            invoiceNumber: data.invoiceNumber as string,
            proofFiles: data.proofFiles,
            cannotAddProofFile: data.cannotAddProofFile,
            purchaseProofType: data.purchaseProofType,
            purchaseDate: data.purchaseDate,
            proofFileDocumentNumber: data.proofFileDocumentNumber,
            purchaseType: data.purchaseType,
            documentDetails: {},
        };
    }
}

export default function mapData(invoiceType: DeviceDocumentType, data:NewDeviceFormDataType) {
    const mappers = new Map([
        ['INTERNAL_RENTAL_INVOICE', new RentalInvoiceFormToDeviceDataMapper()],
        ['EXTERNAL_INVOICE', new InvoiceFormToDeviceDataMapper()],
        ['INTERNAL_INVOICE', new InvoiceFormToDeviceDataMapper()],
    ]);
    const mapper = mappers.get(invoiceType);

    if (typeof mapper === 'undefined') {
        throw new Error('Unknown invoice type');
    }

    return mapper.map(data);
}