import AdminApi from '@/services/api/AdminApi';
import MessagesService from '@/services/domain/messages/messagesService';
import {RequestType} from '@/services/domain/request/RequestType';
import {RequestManagerInterface} from '@/services/requestManager';
import ioc from '@/services/ioc';
import ApiRequestObjectType from '@/services/api/ApiRequestObjectType';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';

export default class RequestService {
    private readonly t: (term: string) => string;

    constructor(private requestManager: RequestManagerInterface, private apiAdmin: AdminApi) {
        const translator = ioc.get('translator') as LanguageSwitcherInterface;
        this.t = translator.t.bind(translator);
    }

    async approveRequest(request: RequestType, message: string): Promise<boolean> {
        const acceptedText = 'cts.messagesubject.request-accepted-message-subject';
        const subject = `${acceptedText} ${request.requestsNumber}`;

        return this.sendNewStatusAndNotifyUser(this.apiAdmin.approveRequest(request.id), request, subject, message);
    }

    async rejectRequest(request: RequestType, message: string): Promise<boolean> {
        const rejectedText = 'cts.messagesubject.request-rejected-message-subject';
        const subject = `${rejectedText} ${request.requestsNumber}`;

        return this.sendNewStatusAndNotifyUser(this.apiAdmin.rejectRequest(request.id), request, subject, message);
    }

    private async sendNewStatusAndNotifyUser({
        path,
        method,
        hostName,
    }: ApiRequestObjectType, request: RequestType, subject: string, message: string) {
        const clientRidNumber = request.client?.ridNumber;
        const response = await (this.requestManager.getMethodByString(method))(hostName, path, '', '');
        const result = await response.json();

        if (!clientRidNumber) throw new Error('Cannot get client rid number');

        if (result.status) {
            await this.sendMessage(subject, message, clientRidNumber);

            return true;
        }

        return false;
    }

    private async sendMessage(subject: string, message: string, clientRidNumber: string) {
        const messagesService = ioc.get('service.messagesService') as MessagesService;

        await messagesService.sendMessage(
            subject,
            message,
            [clientRidNumber],
        );
    }
}