import {RouteConfig} from 'vue-router';
import {routesAnon} from '@/router/anonymous/anonRoutes';
import {isLogged, tryRedirectToRoute} from '@/router/routerUtils';

function routesLogin(): RouteConfig[] {
    return [
        {
            path: '/login',
            name: 'Login',
            component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
            meta: {
                routeFor: ['PUBLIC'],
            },
            beforeEnter: tryRedirectToRoute([
                {
                    tryFunction: isLogged,
                    location: {name: 'Home'},
                },
            ]),
        },
    ];
}

function routesRegister(): RouteConfig[] {
    return [
        {
            path: '/register',
            name: 'Register',
            component: () => import(/* webpackChunkName: "register" */ '@/views/Register.vue'),
            meta: {
                routeFor: ['PUBLIC'],
            },
            beforeEnter: tryRedirectToRoute([
                {
                    tryFunction: isLogged,
                    location: {name: 'Home'},
                },
            ]),
        },
    ];
}

function routesResetPasswordConfirmation(): RouteConfig[] {
    return [
        {
            path: '/reset-confirmation',
            name: 'ResetConfirmation',
            component: () => import(
                '@/views/returnPages/ResetPasswordConfirmation.vue'
                /* webpackChunkName: "resetConfirmation" */
            ),
            meta: {
                routeFor: ['PUBLIC'],
            },
            beforeEnter: tryRedirectToRoute([
                {
                    tryFunction: isLogged,
                    location: {name: 'Home'},
                },
            ]),
        },
    ];
}

function routesResetPasswordInfo(): RouteConfig[] {
    return [
        {
            path: '/reset-info',
            name: 'ResetInfo',
            component: () => import(/* webpackChunkName: "ResetInfo" */ '@/views/returnPages/ResetPasswordInfo.vue'),
            meta: {
                routeFor: ['PUBLIC'],
            },
            beforeEnter: tryRedirectToRoute([
                {
                    tryFunction: isLogged,
                    location: {name: 'Home'},
                },
            ]),
        },
    ];
}

export function routesPublic(): RouteConfig[] {
    return [
        ...routesAnon(),
        ...routesLogin(),
        ...routesRegister(),
        ...routesResetPasswordConfirmation(),
        ...routesResetPasswordInfo(),
    ];
}

export default routesPublic;