<template>
    <div class="search-input-wrapper">
        <select class="select" v-model="type">
            <option value="">{{ $t('cts.searchview.header-search-all') }}</option>
            <option value="devices">{{ $t('cts.searchview.header-search-products') }}</option>
            <option value="clients">{{ $t('cts.searchview.header-search-users') }}</option>
            <!--            <option value="">{{ $t('cts.searchview.header-search-request') }}</option>-->
            <!--            <option value="">{{ $t('cts.searchview.header-search-meassages') }}</option>-->
        </select>
        <input type="text"
               class="search-input"
               ref="input"
               @keydown.enter="search()"
               :placeholder="$t('cts.searchview.header-search-text')"
               v-model="searchQuery">
        <button class="button button-no search-input__button" @click="search()">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19.8107 19.8139C19.5575 20.062 19.1481 20.062 18.8949 19.8139L13.711 14.7119C12.2644 15.8672
                      10.4227 16.5618 8.41467 16.5618C3.76722 16.5618 0 12.8539 0 8.28088C0 3.70721 3.76722 0 8.41467
                      0C13.0621 0 16.8293 3.70721 16.8293 8.28088C16.8293 10.4189 15.9985 12.3619 14.6463
                      13.8311L19.8107 18.913C20.0633 19.1618 20.0633 19.565 19.8107 19.8139ZM8.41467 1.27408C4.48241
                      1.27408 1.29471 4.41114 1.29471 8.28088C1.29471 12.1506 4.48241 15.2877 8.41467 15.2877C12.3469
                      15.2877 15.5346 12.1506 15.5346 8.28088C15.5346 4.41114 12.3469 1.27408 8.41467 1.27408Z"
                      fill="#68676C"/>
            </svg>
        </button>
    </div>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';

@Component
export default class SearchInput extends Vue {
    searchQuery = '';

    type = '';

    search() {
        if (this.searchQuery.length) {
            const {type} = this;
            const options = {
                name: 'Search',
                params: {query: this.searchQuery},
                query: {},
            };
            if (type) {
                (options.query as Record<string, string>).type = type;
            }
            if (this.$router.currentRoute.name !== 'Search') {
                this.$router.push(options);
            } else {
                this.$router.replace(options);
            }
            this.input?.blur();
        }
    }

    private get input() {
        return this.$refs.input as HTMLInputElement;
    }
}
</script>

<style lang="scss" scoped>
    $input-map-search-wrapper: map-deep-set($input-map-rawlplug, ('base', 'padding'), 3px 34px 3px 3px);
    $input-map-search-wrapper: map-deep-set($input-map-search-wrapper, ('base', 'height'), 40px);
    $input-map-search-wrapper: map-deep-set($input-map-search-wrapper, ('base', 'margin'), 0px);
    $input-map-search-wrapper: map-deep-set($input-map-search-wrapper, ('base', 'width'), 100%);

    $input-map-search: map-deep-set($input-map-rawlplug, ('base', 'padding'), 4px 10px);
    $input-map-search: map-deep-set($input-map-search, ('base', 'border'), 1px solid transparent);
    $input-map-search: map-deep-set($input-map-search, ('base', 'margin'), initial);
    $input-map-search: map-deep-set($input-map-search, ('base', 'width'), auto);
    $input-map-search: map-deep-set($input-map-search, ('hover', 'border-color'), transparent);
    $input-map-search: map-deep-set($input-map-search, ('focus', 'border-color'), transparent);
    $input-map-search: map-deep-set($input-map-search, ('disabled', 'border-color'), transparent);
    $input-map-search: map-deep-set($input-map-search, ('error', 'border-color'), transparent);

    $select-map-rawlplug: map-deep-set($input-map-rawlplug, ('base', 'background-color'), $color-rwl-gray-5);
    $select-map-rawlplug: map-deep-set($select-map-rawlplug, ('base', 'padding'), 4px 6px);
    $select-map-rawlplug: map-deep-set($select-map-rawlplug, ('base', 'margin'), 0);
    $select-map-rawlplug: map-deep-set($select-map-rawlplug, ('base', 'width'), auto);
    $select-map-rawlplug: map-deep-set($select-map-rawlplug, ('hover', 'background'), $color-rwl-gray-5);
    $select-map-rawlplug: map-deep-set($select-map-rawlplug, ('focus', 'background'), $color-rwl-gray-5);

    .search-input-wrapper {
        position: relative;
        @include make-input($input-map-search-wrapper);
        display: flex;
        align-items: center;
        overflow-x: hidden;

        .select {
            @include make-input($select-map-rawlplug);
            appearance: auto;
        }

        .search-input {
            @include make-input($input-map-search);
            width: 100%;

            &__button {
                top: 50%;
                transform: translateY(-50%);
                position: absolute;
                right: 8px;
                cursor: pointer;
            }

            &__button {
                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }

        @media screen and (max-width: $breakpoint-sm-max) {
            height: 24px;
            line-height: 18px;
            padding: 0 34px 0 2px;

            .select {
                padding: 0;
                font-size: 14px;
                line-height: 18px;
                height: 20px;
            }

            .search-input {
                height: 20px;
                padding: 2px 10px;
                font-size: 14px;
                line-height: 20px;

                &__button {
                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
    }

</style>