<template>
    <div class="language-switcher" :class="{'is-open': open}" :style="style" v-click-outside="hide">
        <button class="language-switcher__toggle" @click="toggleMenu">
            <svg class="icon-earth" width="22" height="22" viewBox="0 0 22 22" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1
                            11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 11H21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M11 1C13.5013 3.73835 14.9228 7.29203 15 11C14.9228 14.708 13.5013
                            18.2616 11 21C8.49872 18.2616 7.07725 14.708 7 11C7.07725 7.29203 8.49872 3.73835 11 1V1Z"
                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

            <span>{{ currentLanguageCode }}</span>

            <svg width="10" height="10" class="icon-arrow-down" :class="{'rotated': open}" viewBox="0 0 10 10"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1L5 5L9 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </button>

        <ul class="toggle-menu" :class="{'is-open' : open}">
            <slot>
            <li v-for="language in languages" :key="language">
                    <a @click="setLanguage(language)">
                        {{ language.toUpperCase() }}
                    </a>
            </li>
            </slot>
        </ul>

    </div>
</template>

<script>
import {color} from '@rawlplug/rawlplug-design-style-tokens/dist/web/colors';
import ClickOutside from '@/helpers/clickOutside';

const colorRawlplugBlue = color.rwl.blue[100].value;
const colorHover = color.rwl.cyan[100].value;
// @ts-ignore
export default {
    name: 'LanguageSwitcher',
    props: {
        languages: {
            type: Array,
            default() {
                return ['PL'];
            },
        },
        currentLanguage: {
            type: String,
            default: 'PL',
        },
        changeLanguage: {
            type: Function,
        },
        backgroundColor: {
            type: String,
            default: colorRawlplugBlue,
        },
        colorHover: {
            type: String,
            default: colorHover,
        },
    },
    directives: {
        ClickOutside,
    },
    data() {
        return {
            open: false,
        };
    },
    methods: {
        toggleMenu() {
            this.open = !this.open;
        },
        setLanguage(language) {
            this.changeLanguage(language);
            this.open = false;
        },
        hide() {
            this.open = false;
        },
    },
    computed: {
        style() {
            return {
                '--color-hover': this.colorHover,
                '--background-color': this.backgroundColor,
            };
        },
        currentLanguageCode() {
            return this.currentLanguage.toUpperCase();
        },
    },
};
</script>

<style lang="scss" scoped>

    .language-switcher {
        position: relative;
        height: 100%;
        z-index: 2;
        margin-right: 10px;
        color: $color-rwl-white-100;
    }

    .language-switcher__toggle {
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        color: $color-rwl-white-100;
        padding: 0 $spacing-2;
        border: none;
        outline: none;
        background-color: var(--background-color);

        .icon-earth {
            path {
                stroke: $color-rwl-white-100;
                transition: stroke ease-in-out 200ms;
            }
        }

        & > span {
            font-size: $font-size-rwl-body;
            line-height: $line-height-rwl-body;
            min-width: 30px;
            margin-left: $spacing-4;
            margin-right: $spacing-4;
        }

        .is-open > & {
            background-color: #fff;
            color: var(--background-color);

            .icon-earth path {
                stroke: var(--background-color);
            }

            .icon-arrow-down path {
                stroke: var(--background-color);
            }
        }

        @media all and (min-width: $breakpoint-lg-min) {
            padding: 0 $spacing-5;
            padding-left: $spacing-3;
        }
    }

    .icon-arrow-down {
        display: none;
        $arrow-down-translate: 2px;
        transform: rotate(0deg) translateY($arrow-down-translate);
        transition: transform ease-in-out 200ms;

        &.rotated {
            transform: rotate(180deg) translateY($arrow-down-translate);
        }

        @media all and (min-width: $breakpoint-lg-min) {
            display: block;
        }
    }

    .toggle-menu {
        position: absolute;
        top: 100%;
        width: 100%;
        display: none;
        background-color: #fff;
        margin: 0px;
        padding: 10px 0px;

        &.is-open {
            display: flex;
            flex-direction: column;
        }
        li {
            cursor: pointer;
            color: var(--background-color);
            list-style-type: none;

            a {
                padding: $spacing-4 $spacing-4;
                display: block;
                color: inherit;
            }

            &:hover {
                color: var(--color-hover);
            }
        }
    }
</style>