import NotificationSystem from '@/services/notification-system/NotificationSystem';
import Notification from '@/services/notification-system/Notification';
import {Store} from 'vuex';

function registerNotificationStore(app: any, store: Store<any>): Store<any> {
    store.registerModule('notificationSystem', {
        namespaced: true,
        state: {
            notifications: new Array<Notification>(),
        },
        mutations: {
            ADD_NOTIFICATION: (state, payload: Notification) => {
                // @ts-ignore
                app?.$children[0]?.append([payload]);

                // state.notifications.push(payload);
                // const elem = state.notifications;
                // @ts-ignore
                // app?.$children[0]?.append(elem);
                // state.notifications = [];
            },
        },
    });

    return store;
}

function registerNotificationSystem(app: any, store: Store<any>): NotificationSystem {
    const notificationSystem = new NotificationSystem(
        (message: Notification) => store.commit('notificationSystem/ADD_NOTIFICATION', message),
    );
    registerNotificationStore(app, store);

    return notificationSystem;
}

export default registerNotificationSystem;