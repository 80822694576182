<template>
    <div class="device-table-field-container">
        <a href="#" class="link" @click.prevent.stop="onClick" :title="device.deviceName">
            <b>{{ device.deviceName }} </b>
        </a>
        N/S: {{ device.deviceSerial }}
    </div>
</template>

<script lang="ts">
import TableFieldComponentInterface from '@/components/tables/TableFieldComponentInterface';
import {TableCellData, TableRowData} from '@/services/table-system/Table';
import TableField from '@/services/table-system/TableField';
import {Vue, Component, Prop} from 'vue-property-decorator';
import Device from '@/models/Device';

@Component({})
export default class DeviceTableField extends Vue implements TableFieldComponentInterface {
    @Prop({required: true}) rowData!: TableRowData;

    @Prop({required: true}) cellData!: TableCellData;

    @Prop({required: true}) field!: TableField;

    get device(): Device {
        return this.cellData as Device;
    }

    onClick(): void {
        this.$emit('click');
    }
}
</script>

<style scoped lang="scss">
    .device-table-field-container {
        display: flex;
        flex-direction: column;
        font-weight: 300;
    }

    .link {
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            text-decoration-color: black;
        }
    }

    a {
        color: black;
        font-weight: 400;
    }
    @media screen and (min-width: $breakpoint-md-min) {
        .link {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
                    line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
</style>