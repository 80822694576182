import {authHeader} from '@/helpers/authHeader';
import ApiRequestObjectType from '@/services/api/ApiRequestObjectType';

type RequestBody = any;
type RequestHeaders = any;
export type FetchFunctionType = (input: string, init?: any) => Promise<Response>;
export type RequestManagerMethodInterface = (
    hostName: string,
    path: string,
    body: RequestBody,
    headers?: RequestHeaders
) => Promise<Response>;
export type RequestMethod = 'get' | 'post' | 'put' | 'delete';

export interface RequestManagerInterface {
    post: RequestManagerMethodInterface;
    put: RequestManagerMethodInterface;
    get: RequestManagerMethodInterface;
    delete: RequestManagerMethodInterface;
    getMethodByString: (methodName: RequestMethod) => RequestManagerMethodInterface;

    call(requestObject: ApiRequestObjectType, body: RequestBody, headers?: RequestHeaders): Promise<Response>;
}

export default class RequestManager implements RequestManagerInterface {
    constructor(protected fetch: FetchFunctionType) {
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async get(hostName: string, path: string, body: RequestBody, headers: RequestHeaders): Promise<Response> {
        const headerOptions = {
            ...authHeader(),
        };

        return this.fetch(hostName + path, {
            headers: new Headers(headerOptions),
        });
    }

    async post(hostName: string, path: string, body: RequestBody, headers: RequestHeaders): Promise<Response> {
        const headerOptions = {
            ...authHeader(),
            ...headers,
        };
        if (typeof body === 'string') {
            headerOptions['Content-Type'] = 'application/json';
        }

        return this.fetch(hostName + path, {
            method: 'POST',
            body,
            headers: new Headers(headerOptions),
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async put(hostName: string, path: string, body: RequestBody, headers: RequestHeaders): Promise<Response> {
        const headerOptions = {
            ...authHeader(),
        };
        if (typeof body === 'string') {
            headerOptions['Content-Type'] = 'application/json';
        }

        return this.fetch(hostName + path, {
            method: 'PUT',
            body,
            headers: new Headers(headerOptions),
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async delete(hostName: string, path: string, body: RequestBody, headers: RequestHeaders): Promise<Response> {
        const headerOptions = {
            ...authHeader(),
        };
        if (typeof body === 'string') {
            headerOptions['Content-Type'] = 'application/json';
        }

        return this.fetch(hostName + path, {
            method: 'DELETE',
            body,
            headers: new Headers(headerOptions),
        });
    }

    getMethodByString(methodName: RequestMethod): RequestManagerMethodInterface {
        if (methodName in this) {
            const method = this[methodName];

            return method.bind(this);
        }

        throw Error(`Request manager has not method called ${methodName}`);
    }

    async call(requestObject: ApiRequestObjectType, body: RequestBody, headers?: RequestHeaders): Promise<Response> {
        const method = this.getMethodByString(requestObject.method);

        return method.bind(this)(requestObject.hostName, requestObject.path, body, headers);
    }
}