<template>
    <div class="base-layout base-skin">
        <!--        <component :is="getHeaderComponent()">-->
        <!--            <template #header-nav>-->
        <!--                <slot name="header-nav"></slot>-->
        <!--            </template>-->
        <!--        </component>-->
        <!--        <component :is="getTopBarComponent()">-->
        <!--            <slot name="top-bar"></slot>-->
        <!--        </component>-->
        <template v-if="Array.isArray(getHeader())">
            <component v-for="(elem, index) in getHeader()" :key="index" :is="elem.component" v-bind="elem.props">
                <template #[elemName] v-if="elem.templateProviderName" v-for="(elemName, index) in elem.templateProviderName" >
                    <slot :name="elemName"></slot>
                </template>
            </component>
        </template>
        <component v-else :is="getHeader().component" v-bind="getHeader().props"></component>
        <div class="container">
            <slot name="messages"></slot>
        </div>
        <div class="container main-container">
            <div class="main-wrapper"
                 :class="{'has-aside-header': $scopedSlots['aside-header'], 'has-aside': $scopedSlots['aside']}">
                <header class="aside-header" v-if="$scopedSlots['aside-header']">
                    <slot name="aside-header"></slot>
                </header>
                <header class="main-header">
                    <slot name="main-header"></slot>
                </header>
                <aside v-if="$scopedSlots['aside']">
                    <slot name="aside"></slot>
                </aside>
                <main class="app-main">
                    <slot name="main"></slot>
                </main>
            </div>
        </div>
        <footer class="footer" v-if="displayFooter">
            <div class="footer__container container">
                <slot name="footer" v-if="$scopedSlots['footer']"></slot>
                <component :is="getFooterRenderer()" :items="getFooterItems()" v-else></component>
            </div>
        </footer>
    </div>
</template>

<script lang="ts">
    import {Component} from 'vue-property-decorator';
    import BaseLayoutMixin from '@Framework/layouts/BaseLayoutMixin';

    @Component
    export default class BaseLayout extends BaseLayoutMixin {}
</script>

<style lang="scss">
    @import 'css/main';
</style>

<style lang="scss" scoped>
    @import 'css-scoped/main.scoped';
</style>
