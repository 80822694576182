import { render, staticRenderFns } from "./Home.vue?vue&type=template&id=6955d061&scoped=true"
import script from "./Home.vue?vue&type=script&lang=ts"
export * from "./Home.vue?vue&type=script&lang=ts"
import style0 from "./Home.vue?vue&type=style&index=0&id=6955d061&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6955d061",
  null
  
)

export default component.exports