import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
// @ts-ignore
import ResponseStatusCodeManager from '@rawlplug/response-manager';
import ApiResponseType from '@/services/api/ApiResponseType';
import NotificationService from '@/services/domain/NotificationService';
import {ResponseAlertsMapInterface} from '@/services/domain/ResponseAlertsMap';

type ResponseHandleOptions = {
    displaySuccess: boolean;
    displayError: boolean
};

class ResponseHandler {
    constructor(
        private responseStatusCodeManager: ResponseStatusCodeManager,
        private alertsMap: ResponseAlertsMapInterface,
        private notificationService: NotificationService,
        private translator: LanguageSwitcherInterface,
    ) {
    }

    handle(
        apiResponse: ApiResponseType,
        options: Partial<ResponseHandleOptions> = ResponseHandler.DEFAULT_HANDLE_RESPONSE_OPTIONS,
    ): boolean {
        const parsedOptions = ResponseHandler.getParsedOptions(options);
        let status = true;
        const messageStatusCode = ResponseHandler.getMessageStatusCode(apiResponse);

        this.responseStatusCodeManager
            .register(/^2..$/, (): void => {
                if (parsedOptions.displaySuccess) {
                    this.displayAlert(
                        apiResponse,
                        messageStatusCode,
                        () => {
                            this.notificationService.success(this.translator.t('cts.alert.GENERIC_SUCCESS'));
                        },
                    );
                }
                status = true;
            })
            .catch(() => {
                if (parsedOptions.displayError) {
                    this.displayAlert(
                        apiResponse,
                        messageStatusCode,
                        () => {
                            this.notificationService.error(this.translator.t('cts.alert.GENERIC_ERROR'));
                        },
                    );
                }
                status = false;
            });
        this.responseStatusCodeManager.call(apiResponse.body, apiResponse.statusCode);

        return status;
    }

    private static getParsedOptions(
        options: Partial<ResponseHandleOptions>,
    ): ResponseHandleOptions {
        return {
            ...ResponseHandler.DEFAULT_HANDLE_RESPONSE_OPTIONS,
            ...options,
        };
    }

    handleFatal(e?: any) {
        if (e) {
            console.error(e);
        }
        this.notificationService.error(this.translator.t('cts.alert.GENERIC_ERROR'));

        return false;
    }

    private static getMessageStatusCode(apiResponse: ApiResponseType): string {
        // Czasami API nie zwraca mi messageStatusCode, w takim przypadku używam statusCode
        const messageStatusCode = apiResponse.body?.messageStatusCode ?? '';

        return messageStatusCode !== ''
            ? String(messageStatusCode) : String(apiResponse.statusCode);
    }

    private displayAlert(
        apiResponse: ApiResponseType,
        messageStatusCode: string,
        ifNotFoundAlertCallback: () => void,
    ) {
        const alert = this.alertsMap.get(apiResponse.bodyType, messageStatusCode);

        if (alert) {
            this.notificationService[alert.type](this.translator.t(`cts.${alert.message}`));
        } else {
            ifNotFoundAlertCallback();
        }
    }

    static DEFAULT_HANDLE_RESPONSE_OPTIONS: ResponseHandleOptions = {
        displaySuccess: true,
        displayError: true,
    };
}

export default ResponseHandler;