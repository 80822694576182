<template>
    <li>
        <router-link :to="menuItem.to">
            {{ menuItem.text }} {{ newProductsCount }}
        </router-link>
    </li>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import MenuItem from '@Framework/interfaces/MenuItem';

@Component({})
export default class AdminDevicesMenuItem extends Vue {
    @Prop() menuItem!: MenuItem;

    get newProductsCount(): string {
        const newProductsCount = this.$store.getters['admin/devices/newProductsCount'];

        return newProductsCount ? ` (${newProductsCount})` : '';
    }
}
</script>