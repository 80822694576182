<template>
    <section class="tiles">
        <v-tile class="v-tile"
                :background="require('@/assets/background-repair--rawlplug2.png')"
                href="#"
                @click="$emit('onRegisterDeviceClick')"
        >
            {{$t('cts.homeview.register-product')}}
        </v-tile>
        <v-tile class="v-tile"
                :background="require('@/assets/background-repair--rawlplug.png')"
                href="#"
                @click="$emit('onRegisterRepairClick')"
        >
            {{$t('cts.homeview.request-repair')}}
        </v-tile>
        <v-tile class="v-tile"
                :background="require('@/assets/background-parts--rawlplug.png')"
                href="#"
                @click="$emit('onOrderPartsClick')"
        >
            {{$t('cts.homeview.order-parts')}}
        </v-tile>
        <v-tile class="v-tile"
                :background="require('@/assets/background-instruction--rawlplug.png')"
                @click="$emit('onDeviceManualsClick')"
                href="#"
        >
            {{$t('cts.homeview.manuals-download')}}
        </v-tile>
    </section>
</template>
<script lang="ts">
import VTile from '@/components/v-tile/VTile.vue';

export default {
    name: 'v-tiles',
    components: {VTile},
};
</script>
<style lang="scss" scoped>
    h5 {
        margin-bottom: 16px;
    }

</style>