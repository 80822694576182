import Device from '@/models/Device';
import ClientApiInterface from '@/services/api/ClientApiInterface';
import {AuthServiceInterface} from '@/services/AuthService';
import DeviceMapper from '@/services/domain/device/deviceMapper';
import {RawDeviceType} from '@/services/domain/device/RawDeviceType';
import {RequestManagerInterface} from '@/services/requestManager';
import IoC from '@rawlplug/inversion-of-control/src/IoC';

export default class DevicesProvider {
    constructor(
        private requestManager: RequestManagerInterface,
        private clientApi: ClientApiInterface,
        private ioc: IoC,
        private deviceMapper: typeof DeviceMapper,
    ) {
    }

    async getMyDevices(): Promise<Device[]> {
        const ridNumber = this.getAuthService().getRidNumber();
        const {hostName, path, method} = this.clientApi.getDevices(ridNumber);
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, '');

        return this.getDevicesFromResponse(response);
    }

    async getDevice(id: number): Promise<Device | undefined> {
        const devices = await this.getMyDevices();

        return devices.find((device: Device) => device.id === id);
    }

    protected async getDevicesFromResponse(response: Response): Promise<Device[]> {
        const json = await response.json();

        if (json?.body?.devices) {
            return this.mapDevices(json.body.devices);
        }

        return [];
    }

    protected mapDevices(rawDevice: RawDeviceType[]): Device[] {
        return this.deviceMapper.mapDevicesForClient(rawDevice);
    }

    private getAuthService(): AuthServiceInterface {
        return this.ioc.get('service.authService') as AuthServiceInterface;
    }
}