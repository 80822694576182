import {RouteConfig} from 'vue-router';

function routesRequestsOpenIndex(): RouteConfig[] {
    return [
        {
            path: '/admin/requests/open',
            name: 'RequestsOpenIndex',
            component: () => import(
                /* webpackChunkName: "requests-open-index" */
                '@/views/admin/requests/RequestsOpenIndex.vue'
            ),
            meta: {
                crumbs: [
                    {name: '', icon: 'home', title: ''},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

function routesSingleRequest(): RouteConfig[] {
    return [
        {
            path: '/admin/requests/:id',
            name: 'SingleRequest',
            component: () => import(
                '@/views/admin/requests/SingleRequest.vue'
                /* webpackChunkName: "single-request" */
            ),
            meta: {
                crumbs: [
                    {name: 'RequestsOpenIndex', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-request'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

export function routesRequests(): RouteConfig[] {
    return [
        ...routesRequestsOpenIndex(),
        ...routesSingleRequest(),
    ];
}

export default routesRequests;