import Home from '@/views/Home.vue';
import {RouteConfig} from 'vue-router';
import {routesPublic} from '@/router/publicRoutes';
import {routesUser} from '@/router/userRoutes';
import {routesAdmin} from '@/router/adminRoutes';

function routesHome() {
    return [
        {
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                parent: null,
                getBreadcrumbItem: () => ({
                    name: '', icon: 'home', title: '', link: '',
                }),
                crumbs: [
                    {name: '', icon: 'home', title: ''},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

export default (): {routes: RouteConfig[]} => ({
    routes: [
        ...routesHome(),
        ...routesPublic(),
        ...routesUser(),
        ...routesAdmin(),
    ],
});