import ProductImage from '@/components/devices/ProductImage.vue';
import VModal from '@/components/modals/VModal.vue';
import iocProvider from '@Framework/components/iocProvider';
import {Vue} from 'vue-property-decorator';
import {VueConstructor} from 'vue';
import XIcon from '@/assets/XIcon.svg';
import CheckIcon from '@/assets/CheckIcon.svg';
import StatusCompletedIcon from '@/assets/product-statuses/StatusCompletedIcon.svg';
import StatusArchivedIcon from '@/assets/product-statuses/StatusArchivedIcon.svg';
import StatusRejectedIcon from '@/assets/product-statuses/StatusRejectedIcon.svg';

export default (): Array<[string, VueConstructor<Vue>]> => ([
    ['v-modal', VModal],
    ['ioc-provider', iocProvider],
    ['product-image', ProductImage],
    ['x-icon', XIcon],
    ['check-icon', CheckIcon],
    ['status-completed-icon', StatusCompletedIcon],
    ['status-archived-icon', StatusArchivedIcon],
    ['status-rejected-icon', StatusRejectedIcon],
]);