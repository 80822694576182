import {ProductStatusBadgeProps} from '@/components/devices/ProductStatusBadgeProps';
import StatusCalculator from '@/services/domain/statuses/calculators/StatusCalculator';

export default class StatusBadges {
    create(calculator: StatusCalculator): ProductStatusBadgeProps {
        return {
            variant: calculator.statusVariant(),
            label: calculator.statusLabel(),
            iconName: '',
        };
    }
}