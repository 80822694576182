import {ClientModel} from '@/models/Client';
import ClientStats from '@/models/ClientStats';
import ClientApi from '@/services/api/ClientApi';
import ParamsOptionsType, {getDefaultParams} from '@/services/api/ParamsOptionsType';
import ClientType from '@/services/domain/client/ClientType';
import ResponseDataExtractor from '@/services/domain/ResponseDataExtractor';
import ResponseHandler from '@/services/domain/ResponseHandler';
import ioc from '@/services/ioc';
import {RequestManagerInterface} from '@/services/requestManager';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';

export default class ClientsProvider {
    private readonly t: (term: string) => string;

    constructor(
        private requestManager: RequestManagerInterface,
        private api: ClientApi,
    ) {
        const translator = ioc.get('translator') as LanguageSwitcherInterface;
        this.t = translator.t.bind(translator);
    }

    async getClients(params: Partial<ParamsOptionsType>): Promise<ClientType[]> {
        const newParams = {
            ...getDefaultParams(),
            ...params,
        };
        const {hostName, path, method} = this.api.getClients(newParams);
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, '', '');
        const result = await response.json();

        if (result?.status) {
            return result.body.clients;
        }

        return [];
    }

    async getClient(ridNumber: string): Promise<ClientModel> {
        const {hostName, path, method} = this.api.getClient(ridNumber);
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, '', '');
        const result = await response.json();

        if (result.body.client) {
            return new ClientModel(result.body.client);
        }
        throw new Error(this.t('cts.alert.ERROR_INVALID_RESPONSE'));
    }

    async getClientsStats(): Promise<ClientStats[] | null> {
        const {hostName, path, method} = this.api.getClientsStats();
        const requestMethod = this.requestManager.getMethodByString(method);
        const responseHandler = ioc.get('service.responseHandler') as ResponseHandler;
        const responseDataExtractor = ioc.get('service.responseDataExtractor') as ResponseDataExtractor;

        try {
            const response = await requestMethod(hostName, path, '', '');
            const result = await response.json();
            const status = responseHandler.handle(result, {displaySuccess: false});

            return status ? (responseDataExtractor.extract(result) as ClientStats[]) : null;
        } catch (e) {
            responseHandler.handleFatal(e);
        }

        throw new Error(this.t('cts.alert.ERROR_INVALID_RESPONSE'));
    }

    async getClientStats(ridNumber: string): Promise<ClientStats | null> {
        const clients = await this.getClientsStats();

        if (clients && Array.isArray(clients)) {
            return clients.find((c) => c.client.ridNumber === ridNumber) ?? null;
        }

        return null;
    }

    async updateClient(clientData: any, ridNumber: string) {
        const {hostName, path, method} = this.api.updateClient(ridNumber);
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, JSON.stringify(clientData), '');
        const result = await response.json();

        return result;
    }
}