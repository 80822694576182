import Notification from '@/services/notification-system/Notification';

class NotificationSystem {
    notifications: Notification[] = [];

    constructor(private storeNotification: (message: Notification) => void) {}

    add(notification: Notification) {
        this.storeNotification(notification);
    }
}

export default NotificationSystem;