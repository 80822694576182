import {RequestStatusEnum} from '@/services/domain/statuses/enums/RequestStatusEnum';

export const requestStatusDictionary = new Map<string, string>([
    [RequestStatusEnum.PENDING, 'cts.ccstatus.for-verification'],
    [RequestStatusEnum.APPROVED, 'cts.ccstatus.verified'],
    [RequestStatusEnum.REJECTED, 'cts.ccstatus.rejected'],
    [RequestStatusEnum.ARCHIVED, 'cts.ccstatus.archived'],
    [RequestStatusEnum.ENDED, 'cts.ccstatus.ended'],
]);

export const requestStatusForAdminDictionary = new Map<string, string>([
    [RequestStatusEnum.PENDING, 'cts.ccstatus.for-verification'],
    [RequestStatusEnum.APPROVED, 'cts.ccstatus.verified'],
    [RequestStatusEnum.REJECTED, 'cts.ccstatus.rejected'],
    [RequestStatusEnum.ARCHIVED, 'cts.ccstatus.archived'],
    [RequestStatusEnum.ENDED, 'cts.ccstatus.ended'],
]);