import {MessageConversationType} from '@/models/MessageConversationType';

import {AuthServiceInterface} from '@/services/AuthService';
import MessagesService from '@/services/domain/messages/messagesService';
import ioc from '@/services/ioc';
import {StoreRootState} from '@/store/storeRootState';
import {MessagesModuleState} from '@/store/user/types/messagesModuleState';
import {ActionContext, Module} from 'vuex';

const messages: Module<MessagesModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        messages: [],
    },
    mutations: {
        setMessages: (state: MessagesModuleState, payload: MessageConversationType[]) => {
            state.messages = payload;
        },
    },
    actions: {
        async sendMessage(_: ActionContext<MessagesModuleState, StoreRootState>, {
            subject,
            body,
            to,
        }: {to: string[], subject: string, body: string}) {
            const messagesService = ioc.get('service.messagesService') as MessagesService;

            return messagesService.sendMessage(subject, body, to);
        },
        async fetchConversations(
            {commit}: ActionContext<MessagesModuleState, StoreRootState>,
        ): Promise<MessageConversationType[]> {
            const messagesService = ioc.get('service.messagesService') as MessagesService;
            const service = ioc.get('service.authService') as AuthServiceInterface;
            if (!service.isLogged()) {
                return [];
            }
            try {
                const messagesList = await messagesService.fetchConversations();

                commit('setMessages', messagesList);

                return messagesList;
            } catch (e) {
                console.error(e);

                return [];
            }
        },
        async fetchMessages(_: ActionContext<MessagesModuleState, StoreRootState>, conversationNumber: string) {
            const messagesService = ioc.get('service.messagesService') as MessagesService;
            try {
                const messagesList = await messagesService.fetchMessages(conversationNumber);
                messagesList.reverse();

                return messagesList;
            } catch (e) {
                return [];
            }
        },
        async sendReplyMessage(_: ActionContext<MessagesModuleState, StoreRootState>, {
            conversationNumber,
            body,
        }: {conversationNumber: string, body: string}) {
            const messagesService = ioc.get('service.messagesService') as MessagesService;

            return messagesService.sendReplyMessage(conversationNumber, body);
        },
    },
    getters: {
        unreadMessages(state: MessagesModuleState) {
            return state.messages.reduce((acc: number, message) => {
                if (message.haveNotReadMessages) {
                    acc += 1;
                }

                return acc;
            }, 0);
        },
    },
};

export default messages;