import I18nLanguageSwitcher from '@/plugins/i18n/I18nLanguageSwitcher';
import ioc from '@/services/ioc';
import LanguageSwitcherConfig from '@AppConfig/LanguageSwitcherConfig';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import {VueConstructor} from 'vue';
import VueI18n from 'vue-i18n';
import {getCookie, setCookie} from '@/helpers/cookies';
// @ts-ignore
import POEditorMapper from '@rawlplug/po-editor-mapper';
import getUserInfo from '@rawlplug/location-detector/dist/location-detector';
import {LocationType} from '@rawlplug/location-detector/dist/types';
// todo wymaga refaktoryzacji i wyrzucenia tej zmiennej poza globalny zakres
// eslint-disable-next-line import/no-mutable-exports
let i18n!: LanguageSwitcherInterface;

ioc.set(
    'config.languageSwitcher',
    function getLangSwitcher() {
        return LanguageSwitcherConfig();
    },
    {},
);

function getConfig() {
    return (ioc.get('config.languageSwitcher') as any);
}

function prepareMessagesForLanguages(content: any, languages: string[], projectsMap: {[key: string]: any}) {
    const mapper = new POEditorMapper(content);

    return mapper.mapAll(languages, projectsMap);
}

function getTranslationContent() {
    // @ts-ignore
    const locales = require.context('@App/translations', true, /[A-Za-z0-9-_,\s]+\.json$/i);

    const messages = {};
    locales.keys().forEach((key: string) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            // @ts-ignore
            messages[locale] = locales(key);
        }
    });

    return messages;
}

async function getBrowserLanguage(): Promise<string> {
    const {languages, defaultLanguage} = getConfig();
    const {browserLang} = await getUserInfo([LocationType.BROWSERLANG]);
    const browserLangPart = browserLang ? browserLang.split('-')[0] : defaultLanguage;
    const cookieValue = getCookie('cts_language', '');

    if (cookieValue) {
        return cookieValue;
    }

    if (languages.includes(browserLangPart)) {
        setCookie('cts_language', browserLangPart, 365);

        return browserLangPart;
    }

    setCookie('cts_language', browserLangPart, 365);

    return defaultLanguage;
}

export async function installI18n(Vue: VueConstructor<Vue>) {
    Vue.use(VueI18n);
    const {languages, projectsMap} = getConfig();
    const locale = await getBrowserLanguage();
    i18n = new I18nLanguageSwitcher(new VueI18n({
        locale,
        messages: prepareMessagesForLanguages(getTranslationContent(), languages, projectsMap),
    }));
    (i18n as I18nLanguageSwitcher).onLanguageChanged((language: string) => {
        setCookie('cts_language', language, 365);
    });
    // todo dostanie się do prototype jest tutaj porządane
    // eslint-disable-next-line no-prototype-builtins
    if (!Vue.prototype.hasOwnProperty('$languageSwitcher')) {
        Object.defineProperty(Vue.prototype, '$languageSwitcher', {
            get() {
                return i18n;
            },
        });
        // eslint-disable-next-line no-param-reassign
        Vue.prototype.$t = function translate(key: string): string {
            return i18n.t(key);
        };
    }
    ioc.set(
        'translator',
        function getTranslator() {
            return i18n;
        },
        {},
    );

    return i18n;
}

export default i18n;