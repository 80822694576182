<template>
    <ul class="aside-menu">
        <component :is="item.component" :menu-item="item" :key="item.to" v-for="item in items">
            <router-link :to="item.to">{{ item.text }}</router-link>
        </component>
    </ul>
</template>

<script lang="ts">
    import {Vue, Component, Prop} from 'vue-property-decorator';

    @Component({})
    export default class AsideMenu extends Vue {
        @Prop() items!: any[];
    }
</script>

<style scoped lang="scss">
    @import "~@rawlplug/rawlplug-design-style-tokens/dist/scss/colors";

    ul {
        margin: 0 0 16px;
        padding: 0;

        &::v-deep {
            li {
                font-size: 16px;
                line-height: 24px;
                border-bottom: 1px solid $color-rwl-gray-15;
                list-style-type: none;
            }

            a {
                display: block;
                height: 40px;
                padding: 7px 0 9px 10px;
                color: $color-rwl-gray-100;
                text-decoration: none;

                &.router-link-exact-active {
                    background-color: #fff;
                }
            }
        }
    }
</style>
