import {CCRequestStatusType} from '@/services/domain/request/RequestType';
import ProductStatusCalculator from '@/services/domain/statuses/calculators/ProductStatusCalculator';
import RequestStatusCalculator from '@/services/domain/statuses/calculators/RequestStatusCalculator';
import RequestStatusWithCCCalculator from '@/services/domain/statuses/calculators/RequestStatusWithCcCalculator';

export default class StatusCalculators {
    constructor(
        private deviceStatusDictionary: Map<string, string>,
        private requestStatusDictionary: Map<string, string>,
    ) {
    }

    public deviceStatusCalculator(
        device: {isApproved: boolean, isRejected: boolean, request: unknown},
    ): ProductStatusCalculator {
        return new ProductStatusCalculator(device, this.deviceStatusDictionary);
    }

    public requestStatusCalculator(
        request: {isApproved: boolean, isRejected: boolean, status: CCRequestStatusType},
    ): RequestStatusCalculator {
        return new RequestStatusCalculator(request, this.requestStatusDictionary);
    }

    public requestStatusWithCcCalculator(
        request: {isApproved: boolean; isRejected: boolean; ccRequestId: string; status: CCRequestStatusType},
    ): RequestStatusWithCCCalculator {
        return new RequestStatusWithCCCalculator(request, this.requestStatusDictionary);
    }
}