import resourceUrls from './resourcesUrls';

export default function getResourceUrl(lang: string, path: string, key: string | null): string {
    const resource = resourceUrls.find((element) => element.lang === lang);
    if (!resource) {
        if (key) {
            return resourceUrls[0][path][key];
        }

        return resourceUrls[0][path];
    }

    if (!key) {
        return resource[path];
    }

    return resource[path][key];
}