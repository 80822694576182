import {Vue, Component} from 'vue-property-decorator';
import ioc from '@Framework/ioc';

@Component({})
export default class BaseLayoutMixin extends Vue {
    getMobileMenuButton() {
        return (ioc.get('config.layout') as any).mobileMenuButton
    }

    getAppName() {
        return (ioc.get('config.app') as any).appName;
    }

    getAppLogo() {
        return (ioc.get('config.app') as any).appLogo;
    }

    getHeader() {
        const config = this.getLayoutConfig();

        return config.layout.slots.header;
    }

    getLayoutConfig(): any {
        return ioc.get('config.layout');
    }

    getFooterItems() {
        return (ioc.get('config.footer') as any).items;
    }

    getFooterRenderer() {
        return (ioc.get('config.footer') as any).rendererComponent
    }

    get displayFooter() {
        return (ioc.get('config.footer') as any).displayFooter
    }
}
