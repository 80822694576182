<template>
    <div>
        {{ $t(status.label) }}
    </div>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import TableFieldComponentInterface from '@/components/tables/TableFieldComponentInterface';
import StatusObjectType from '@/services/domain/statuses/models/StatusObject';
import {TableCellData, TableRowData} from '@/services/table-system/Table';
import TableField from '@/services/table-system/TableField';

@Component({})
export default class RequestCCStatusField<RowData extends {status: StatusObjectType} & TableRowData>
    extends Vue implements TableFieldComponentInterface {
    @Prop({required: true}) rowData!: RowData;

    @Prop({required: true}) cellData!: TableCellData;

    @Prop({required: true}) field !: TableField;

    get status(): StatusObjectType {
        return this.cellData as StatusObjectType;
    }
}
</script>