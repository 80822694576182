import {RouteConfig} from 'vue-router';

function routesMessagesIndex(): RouteConfig[] {
    return [
        {
            path: '/user/messages',
            name: 'UserMessagesIndex',
            component: () => import(/* webpackChunkName: "messages-index" */ '@/views/messages/MessagesIndex.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-messages'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesSingleMessage(): RouteConfig[] {
    return [
        {
            path: '/user/messages/:id',
            name: 'UserSingleMessage',
            component: () => import(/* webpackChunkName: "single-message" */ '@/views/messages/SingleMessage.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'UserMessagesIndex', icon: '', title: 'cts.routes.routes-messages'},
                    {name: '', icon: '', title: 'cts.newmessagesview.label-message'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesUserNewMessage(): RouteConfig[] {
    return [
        {
            path: '/user/messages/new/:ridNumber',
            name: 'UserNewMessage',
            component: () => import(/* webpackChunkName: "user-new-message" */ '@/views/messages/NewMessage.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'UserMessagesIndex', icon: '', title: 'cts.routes.routes-messages'},
                    {name: '', icon: '', title: 'cts.newmessagesview.label-message'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

export function routesMessages(): RouteConfig[] {
    return [
        ...routesMessagesIndex(),
        ...routesSingleMessage(),
        ...routesUserNewMessage(),
    ];
}

export default routesMessages;