<template>
    <a :href="href" class="link" :target="target">{{title}}</a>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class BaseLink extends Vue {
    @Prop() href!: string;
    @Prop() target!: string;
    @Prop() title!: string;
}
</script>

<style lang="scss" scoped>
    .link {
        color: #FFF;
        opacity: 0.6;
        transition: opacity .3s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }
</style>