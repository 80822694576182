import {ServicesName} from '@AppConfig/DependenciesConfig';
import ioc from '@Framework/ioc';

type OptionType = {
    call?: Array<any>,
    args?: Array<any>,
};

const newIoc: {
    get(name: ServicesName): any;
    set(name: string, object: any, args: OptionType): any
} = {
    get<T extends ServicesName>(name: T): any {
        return ioc.get(name);
    },
    set(name: string, object: any, args: OptionType) {
        ioc.set(name, object, args);
    },
};

export default newIoc;