import BaseStatusCalculator from '@/services/domain/statuses/calculators/BaseStatusCalculator';
import {CCStatusModel} from '@/services/domain/statuses/models/CCStatusModel';
import CtsCCStatusModel from '@/services/domain/statuses/models/CtsCCStatusModel';
import {RequestCCStatusEnum} from '@/services/domain/statuses/enums/RequestCCStatusEnum';
import {CCRequestStatusType} from '@/services/domain/request/RequestType';
import RequestStatusCalculator from '@/services/domain/statuses/calculators/RequestStatusCalculator';
import StatusCalculator, {StatusVariant} from '@/services/domain/statuses/calculators/StatusCalculator';

export default class RequestStatusWithCCCalculator<Request extends {
    isApproved: boolean,
    isRejected: boolean,
    ccRequestId: string,
    status: CCRequestStatusType
} = {
    isApproved: boolean,
    isRejected: boolean,
    ccRequestId: string,
    status: CCRequestStatusType
}>
    extends BaseStatusCalculator
    implements StatusCalculator {
    private requestStatusCalculator: RequestStatusCalculator;

    private ccStatusModel: CCStatusModel;

    constructor(
        private request: Request,
        private requestStatusDictionary: Map<string, string>,
    ) {
        super();
        this.requestStatusCalculator = new RequestStatusCalculator(this.request, requestStatusDictionary);
        this.ccStatusModel = new CtsCCStatusModel(this.request.status, this.request.ccRequestId);
    }

    statusCode(): string {
        const {ccStatusModel} = this;
        if (!this.isCcStatusExist()) {
            return this.requestStatusCalculator.statusCode();
        }

        return ccStatusModel.getCode();
    }

    statusLabel(): string {
        const {ccStatusModel} = this;

        if (!this.isCcStatusExist()) {
            return this.requestStatusCalculator.statusLabel();
        }

        return ccStatusModel.getLabel();
    }

    statusVariant(): StatusVariant {
        if (!this.isCcStatusExist()) {
            return this.requestStatusCalculator.statusVariant();
        }

        return this.getCcStatusVariant();
    }

    private getCcStatusVariant() {
        const statusVariantCodeMap = RequestStatusWithCCCalculator.getStatusVariantCodeMap();
        const code = this.statusCode();

        return statusVariantCodeMap.find((statusVariantCode) => {
            const variant = statusVariantCode[1] as string[];

            return variant.includes(code);
        })?.[0] ?? 'default';
    }

    private static getStatusVariantCodeMap(): [StatusVariant, string[]][] {
        return [
            ['successfully_ended', [RequestCCStatusEnum.CL]],
            ['success', [RequestCCStatusEnum.SR, RequestCCStatusEnum.NS, RequestCCStatusEnum.CL]],
            ['error', [RequestCCStatusEnum.CS]],
        ];
    }

    private isCcStatusExist(): boolean {
        const ccRequestIdExists = Number(this.request.ccRequestId) > 0;
        const ccRequestIsSetTrue = this.ccStatusModel.value().isSet;

        return ccRequestIsSetTrue || ccRequestIdExists;
    }
}