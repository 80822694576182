import ClientApiInterface from '@/services/api/ClientApiInterface';
import {MessageConversationType} from '@/models/MessageConversationType';

import {ResponseDataExtractorInterface} from '@/services/domain/ResponseDataExtractor';
import ResponseHandler from '@/services/domain/ResponseHandler';
import ioc from '@/services/ioc';
import {RequestManagerInterface} from '@/services/requestManager';

class MessagesService {
    constructor(private requestManager: RequestManagerInterface, private clientApi: ClientApiInterface) {
    }

    async fetchConversations(): Promise<MessageConversationType[]> {
        const {hostName, path, method} = this.clientApi.fetchConversations();
        try {
            const response = await (this.requestManager.getMethodByString(method))(hostName, path, '', '');
            const result = await response.json();
            const dataExtractor = ioc.get('service.responseDataExtractor') as ResponseDataExtractorInterface;
            const responseHandler = ioc.get('service.responseHandler') as ResponseHandler;
            responseHandler.handle(result, {
                displaySuccess: false,
            });

            return dataExtractor.extract(result) as MessageConversationType[];
        } catch (e) {
            throw new Error(`Cannot fetch conversations ${e}`);
        }
    }

    async fetchAdminConversations() {
        const {hostName, path, method} = this.clientApi.fetchAdminConversations();
        try {
            const response = await (this.requestManager.getMethodByString(method))(hostName, path, '', '');
            const result = await response.json();
            const dataExtractor = ioc.get('service.responseDataExtractor') as ResponseDataExtractorInterface;
            const responseHandler = ioc.get('service.responseHandler') as ResponseHandler;
            responseHandler.handle(result, {
                displaySuccess: false,
            });

            return dataExtractor.extract(result) as MessageConversationType[];
        } catch (e) {
            throw new Error(`Cannot fetch conversations ${e}`);
        }
    }

    async sendMessage(subject: string, body: string, to: string[]) {
        const {hostName, path, method} = this.clientApi.sendMessage();

        return (this.requestManager.getMethodByString(method))(hostName, path, JSON.stringify({
            subject,
            body,
            to,
        }), '');
    }

    async sendReplyMessage(conversationNumber: string, body: string) {
        const {hostName, path, method} = this.clientApi.sendReplyMessage(conversationNumber);

        return (this.requestManager.getMethodByString(method))(hostName, path, JSON.stringify({
            subject: '',
            body,
        }), '');
    }

    async fetchMessages(conversationNumber: string): Promise<MessageConversationType[]> {
        const {hostName, path, method} = this.clientApi.fetchMessages(conversationNumber);
        const response = await (this.requestManager.getMethodByString(method))(hostName, path, '', '');
        const result = await response.json();

        try {
            const dataExtractor = ioc.get('service.responseDataExtractor') as ResponseDataExtractorInterface;
            const responseHandler = ioc.get('service.responseHandler') as ResponseHandler;
            responseHandler.handle(result, {
                displaySuccess: false,
            });

            return dataExtractor.extract(result) as MessageConversationType[];
        } catch (e) {
            throw new Error(`Cannot fetch conversations ${e}`);
        }
    }
}

export default MessagesService;