import Device from '@/models/Device';
import DeviceRegistrant from '@/services/domain/device/DeviceRegistrator';
import ioc from '@/services/ioc';
import {StoreRootState} from '@/store/storeRootState';
import NewDeviceFormDataType from '@/store/user/devices/NewDeviceFormDataType';
import {NewDeviceModuleState} from '@/store/user/types/NewDeviceModuleState';
import * as validators from '@Framework/components/form/validators';
import {ActionContext, Module} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import mapData from './newDeviceMapper';

const initialFormData: NewDeviceFormDataType = {
    purchaseCountry: 'PL',
    deviceName: '',
    deviceSerial: '',
    deviceFullName: '',
    deviceUid: '',
    documentType: 'INTERNAL_INVOICE',
    invoiceNumber: '',
    proofFiles: [],
    cannotAddProofFile: false,
    purchaseProofType: '',
    purchaseDate: '',
    proofFileDocumentNumber: '',
    purchaseType: '',
    rentalInvoice: '',
    isRentalDevice: false,
};

const newDeviceModule: Module<NewDeviceModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        formData: {
            ...initialFormData,
        },
        validators: {...validators},
    },
    getters: {
        getField,
    },
    actions: {
        async createProduct(
            {commit}: ActionContext<NewDeviceModuleState, StoreRootState>,
            productsData: NewDeviceFormDataType,
        ): Promise<Device | boolean> {
            const deviceRegistrant = ioc.get('service.deviceRegistrant') as DeviceRegistrant;
            const device = await deviceRegistrant.register(mapData(productsData.documentType, productsData));

            if (device) {
                commit('user/products/ADD_PRODUCT', device, {root: true});

                return device;
            }

            return device;
        },
    },
    mutations: {
        updateField,
        initFormData: (state) => {
            state.formData = {...initialFormData};
        },
        updateRentalState: (state, isRentalDevice: boolean) => {
            state.formData.isRentalDevice = isRentalDevice;
        },
    },
};

export type FoundDevice = {
    name: string;
    type: string;
    subgroup: {
        id: string;
        name: string;
        identifier: string;
        indexes: string[];
        marketing_features: string[];
        features_and_benefits: string[];
    },
};

export default newDeviceModule;