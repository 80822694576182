<template>
    <div class="home">
    <span class="user-greeting">
      <h2 class="user-greeting__title">
          {{ $t('cts.homeview.hello') }} <b>{{ $store.state.auth.user.firstName }}!</b>
          <span class="subtitle">({{ $t('cts.header.go-to') }}
              <a class="link" href="#" @click.prevent.stop="goToYourAccount">
                  {{ $t('cts.homeview.your-account-link-name') }}
              </a>)
          </span>
      </h2>
      <h5>
        {{ $t('cts.homeview.main-action-button') }}
      </h5>
      <v-tiles @onRegisterDeviceClick="$router.push({name: 'NewDevice'})"
               @onRegisterRepairClick="$router.push({name: 'RepairRequest', params: {id: '-1'}})"
               @onOrderPartsClick="$router.push({name: 'OrderParts', params: {id: '-1'}})"
               @onDeviceManualsClick="$router.push({name: 'DeviceManuals'})"
      />
      <h5>{{ $t('cts.homeview.newest-info-about-products') }}:</h5>
      <device-logs></device-logs>
    </span>
    </div>
</template>

<script lang="ts">
import ProductBlockDetails from '@/components/devices/ProductBlockDetails.vue';
import DeviceLogs from '@/components/home/DeviceLogs.vue';
import VTile from '@/components/v-tile/VTile.vue';
import VTiles from '@/components/VTiles.vue';
import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {
        VTiles,
        ProductBlockDetails,
        VTile,
        DeviceLogs,
    },
})
export default class Home extends Vue {
    goToYourAccount(): void {
        this.$router.push({
            name: 'YourAccount',
        });
    }
}
</script>

<style lang="scss" scoped>
    .home {
        &::v-deep {
            .v-tile {
                width: 190px;
            }
        }
    }

    h5 {
        margin-bottom: 16px;
    }

    .badge {
        width: 100%;
    }
</style>