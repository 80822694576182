import {RequestOptions} from '@/services/api/RequestsApi';
import RequestsApiInterface from '@/services/api/RequestsApiInterface';
import {RequestType} from '@/services/domain/request/RequestType';
import ResponseDataExtractor from '@/services/domain/ResponseDataExtractor';
import ResponseHandler from '@/services/domain/ResponseHandler';
import {RequestManagerInterface} from '@/services/requestManager';
import IoC from '@rawlplug/inversion-of-control/src/IoC';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';

export type OrderType = 'ASC' | 'DESC';

export default class RequestsProvider {
    private readonly t: (term: string) => string;

    constructor(
        private requestManager: RequestManagerInterface,
        private api: RequestsApiInterface,
        private ioc: IoC,
        private responseHandler: ResponseHandler,
        private responseDataExtractor: ResponseDataExtractor,
    ) {
        const translator = ioc.get('translator') as LanguageSwitcherInterface;
        this.t = translator.t.bind(translator);
    }

    async getUserRequests(options: Partial<RequestOptions>): Promise<RequestType[]> {
        const opt = {
            ...RequestsProvider.getDefaultRequestOptions(),
            ...options,
        };
        opt.orderBy = opt.orderBy as OrderType;
        const response = await this.requestManager.call(this.api.getUserRequest(opt), '', '');
        const result = await response.json();

        if (this.responseHandler.handle(result, {displaySuccess: false})) {
            return this.responseDataExtractor.extract(result) as RequestType[];
        }
        throw new Error(this.t('cts.alert.ERROR_INVALID_RESPONSE'));
    }

    async getRequest(uuid: string) {
        const {hostName, path, method} = this.api.getRequest(uuid);
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, '', '');
        const result = await response.json();

        if (result?.status && result?.body?.requests?.[0]) {
            return result.body.requests[0];
        }
        throw new Error(this.t('cts.alert.ERROR_INVALID_RESPONSE'));
    }

    async getAllRequests(page: number, limit: number, orderBy: string, order: OrderType = 'DESC') {
        const {hostName, path, method} = this.api.getRequests({
            page,
            limit,
            order,
            orderBy,
        });
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, '', '');
        const result = await response.json();

        if (result?.body?.requests) {
            return result.body.requests;
        }

        return [];
    }

    static getDefaultRequestOptions() {
        return {
            page: 0,
            limit: 5,
            orderBy: '',
            order: 'DESC' as OrderType,
            uuid: '',
            ridNumber: '',
        };
    }
}