export function getCookie(name: string, def: string): string | undefined {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`) as string[];
    if (parts && parts.length === 2) {
        return parts?.pop()?.split(';').shift();
    }

    return def;
}

export function setCookie(name: string, value: string, maxAge?: number): void {
    let cookie = `${name}=${value}; `;

    if (maxAge !== undefined) {
        cookie += `Max-Age=${maxAge}; `;
    }

    cookie += ';path=/';

    document.cookie = cookie;
}