import AdminApiInterface from '@/services/api/AdminApiInterface';
import DeviceMapper from '@/services/domain/device/deviceMapper';
import IoC from '@rawlplug/inversion-of-control/src/IoC';
import ParamsOptionsType from '@/services/api/ParamsOptionsType';
import {AdminDevice} from '@/models/Device';
import {RawDeviceAdminType} from '@/services/domain/device/RawDeviceType';
import {RequestManagerInterface} from '@/services/requestManager';

export default class AdminDevicesProvider {
    constructor(
        protected requestManager: RequestManagerInterface,
        protected adminApi: AdminApiInterface,
        private ioc: IoC,
        protected deviceMapper: typeof DeviceMapper,
    ) {
    }

    async getDevices(params: ParamsOptionsType): Promise<AdminDevice[]> {
        const {hostName, path, method} = this.adminApi.getDevices(params);
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, '');

        return this.getDevicesFromResponse(response);
    }

    async getDevice(id: number): Promise<AdminDevice | undefined> {
        const {hostName, path, method} = this.adminApi.getDevice(id);
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, '');
        const devices = await this.getDevicesFromResponse(response);

        return devices.find((device: AdminDevice) => device.id === id);
    }

    async getClientDevice(ridNumber: string): Promise<AdminDevice[]> {
        const {hostName, path, method} = this.adminApi.getClientDevices(ridNumber);
        const requestMethod = this.requestManager.getMethodByString(method);
        const response = await requestMethod(hostName, path, '');

        return this.getDevicesFromResponse(response);
    }

    protected async getDevicesFromResponse(response: Response): Promise<AdminDevice[]> {
        const json = await response.json();

        if (json?.body?.devices) {
            return this.mapDevices(json.body.devices);
        }

        return [];
    }

    protected mapDevices(rawDevice: RawDeviceAdminType[]): AdminDevice[] {
        return this.deviceMapper.mapDevicesForAdmin(rawDevice);
    }
}