import AdminDevicesProvider from '@/services/domain/device/AdminDevicesProvider';
import Client from '@/models/Client';
import Device from '@/models/Device';
import DeviceService from '@/services/domain/device/DeviceService';
import NotificationService from '@/services/domain/NotificationService';
import ResponseHandler from '@/services/domain/ResponseHandler';
import StatusBadges from '@/services/domain/statuses-badge/StatusBadges';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import ioc from '@/services/ioc';
import {ActionContext, Module} from 'vuex';
import {ProductStatusBadgeProps} from '@/components/devices/ProductStatusBadgeProps';
import {StoreRootState} from '@/store/storeRootState';
import {getField} from 'vuex-map-fields';

export type DeviceModuleState = {
    device: Device | null,
    client: Client | null,
};

const deviceModule: Module<DeviceModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        device: null,
        client: null,
    },
    mutations: {
        SET_DEVICE: (state: DeviceModuleState, device: Device) => {
            state.device = device;
        },
        SET_CLIENT: (state: DeviceModuleState, client: Client) => {
            state.client = client;
        },
    },
    actions: {
        async loadDevice(context: DeviceModuleActionContext, id: number): Promise<void> {
            const deviceProvider = ioc.get('provider.adminDevicesProvider') as AdminDevicesProvider;
            const device = await deviceProvider.getDevice(id);
            context.commit('SET_DEVICE', device);
            const client = await context.dispatch(
                'admin/clients/fetchClient',
                device?.clientRidNumber,
                {root: true},
            );
            context.commit('SET_CLIENT', client);
        },
        async approveDevice(
            context: DeviceModuleActionContext,
            {device, message}: AcceptRejectDevicePayload,
        ): Promise<boolean> {
            const deviceService = ioc.get('service.device') as DeviceService;
            const responseHandler = ioc.get('service.responseHandler') as ResponseHandler;

            try {
                const deviceId = await deviceService.approveDeviceAndNotifyUser(device, message);
                context.commit('admin/devices/APPROVE_DEVICE', deviceId, {root: true});

                return true;
            } catch (e) {
                responseHandler.handleFatal(e);
            }

            return false;
        },
        async rejectDevice(
            _: DeviceModuleActionContext,
            {device, message}: AcceptRejectDevicePayload,
        ): Promise<boolean> {
            const deviceService = ioc.get('service.device') as DeviceService;
            const notificationService = ioc.get('service.notification') as NotificationService;
            const translator = ioc.get('translator') as LanguageSwitcherInterface;

            if (await deviceService.rejectDeviceAndNotifyUser(device, message)) {
                return true;
            }
            notificationService.error(translator.t('cts.alert.ERROR_CANNOT_ABANDON_DEVICE'));

            return false;
        },
    },
    getters: {
        statusBadge(state: DeviceModuleState): ProductStatusBadgeProps | undefined {
            if (state.device) {
                const statusBadges = ioc.get('service.statusBadge') as StatusBadges;
                const calculators = ioc.get('service.statusCalculatorsForAdmin') as StatusCalculators;

                return statusBadges.create(calculators.deviceStatusCalculator(state.device));
            }

            return undefined;
        },
        getField,
    },
};

type DeviceModuleActionContext = ActionContext<DeviceModuleState, StoreRootState>;
type AcceptRejectDevicePayload = {device: Device, message: string};

export default deviceModule;