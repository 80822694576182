import BaseStatusCalculator from '@/services/domain/statuses/calculators/BaseStatusCalculator';
import {DeviceStatusEnum} from '@/services/domain/statuses/enums/DeviceStatusEnum';
import {RequestStatusEnum} from '@/services/domain/statuses/enums/RequestStatusEnum';
import StatusCalculator, {StatusVariant} from '@/services/domain/statuses/calculators/StatusCalculator';
import {RequestCCStatusEnum} from '@/services/domain/statuses/enums/RequestCCStatusEnum';
import {CCRequestStatusType} from '@/services/domain/request/RequestType';

type Request = {
    isApproved: boolean,
    isRejected: boolean,
    isArchived?: boolean,
    status: CCRequestStatusType,
};

export default class RequestStatusCalculator extends BaseStatusCalculator implements StatusCalculator {
    constructor(
        private request: Request,
        private dictionary: Map<string, string>,
    ) {
        super();
    }

    statusCode(): string {
        return this.getRequestStatusEnum(this.request);
    }

    statusLabel(): string {
        const code = this.statusCode();

        return this.dictionary.get(code) ?? '';
    }

    statusVariant(): StatusVariant {
        const code = this.statusCode();
        const statusVariantCodeMap = RequestStatusCalculator.getStatusVariantCodeMap();

        return statusVariantCodeMap.find((statusVariantCode) => {
            const variant = statusVariantCode[1] as string[];

            return variant.includes(code);
        })?.[0] ?? 'default';
    }

    private static getStatusVariantCodeMap(): [StatusVariant, string[]][] {
        return [
            ['success', [RequestStatusEnum.APPROVED]],
            ['successfully_ended', [RequestStatusEnum.ENDED]],
            ['error', [DeviceStatusEnum.REJECTED]],
        ];
    }

    private getRequestStatusEnum(request: Request): RequestStatusEnum {
        if (request?.status?.code === RequestCCStatusEnum.CL) {
            return RequestStatusEnum.ENDED;
        }
        if (request.isApproved) {
            return RequestStatusEnum.APPROVED;
        }
        if (request.isRejected) {
            return RequestStatusEnum.REJECTED;
        }
        // @ts-ignore
        if (request?.isArchived) {
            return RequestStatusEnum.ARCHIVED;
        }

        return RequestStatusEnum.PENDING;
    }
}