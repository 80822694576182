import {AuthServiceInterface} from '@/services/AuthService';
import clientBaseType from '@/services/domain/client/ClientBaseType';
import ClientsProvider from '@/services/domain/client/ClientsProvider';
import ioc from '@/services/ioc';
import {StoreRootState} from '@/store/storeRootState';
import {ClientModuleState} from '@/store/user/types/clientModuleState';
import {ActionContext, Module} from 'vuex';

const client: Module<ClientModuleState, StoreRootState> = {
    namespaced: true,
    actions: {
        async updateClient(_: ActionContext<ClientModuleState, StoreRootState>, clientData: clientBaseType) {
            const clientsProvider = ioc.get('provider.clientsProvider') as ClientsProvider;
            const authService = ioc.get('service.authService') as AuthServiceInterface;

            return clientsProvider.updateClient(clientData, authService.getRidNumber());
        },
    },
};

export default client;