<template>
    <a class="v-tile" @click.prevent="$emit('click')" :href="href">
        <div class="v-tile__picture">
            <slot name="icon"></slot>
            <div class="v-tile__picture-background" :style="getBackgroundStyle()"></div>
        </div>
        <div class="v-tile__body">
            <span><slot></slot></span>
            <v-tile-arrow class="v-tile__arrow"></v-tile-arrow>
        </div>
    </a>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import VTileArrow from './VTileArrow.svg';

@Component({
    components: {VTileArrow},
})
export default class VTile extends Vue {
    @Prop() href: string | undefined;

    @Prop() background: string | undefined;

    getBackgroundStyle() {
        return `background-image: url(${this.background})`;
    }
}
</script>

<style lang="scss" scoped>
    .v-tile {
        box-shadow: 0 0 24px rgba(0, 0, 0, 0.1);
        @include simple-transition(transform);
        cursor: pointer;
    }

    .v-tile__picture {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 67px);
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;

        ::v-deep svg {
            z-index: 2;
            @include simple-transition(opacity);
            opacity: 0.75;
        }

        &-background {
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            content: "";
            background-color: #fff;
            background-size: cover;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            @include transition(transform, filter, $transition-base-duration $transition-base-function);
            transform: translate3d(0, 0, 0) scale(1);
            filter: grayscale(1) contrast(80%) brightness(120%);
        }

        @media (min-width: 768px) {
            width: 350px;
        }
    }

    .v-tile__arrow {
        @include simple-transition(transform);
        @include box(16px);
        stroke: var(--cts-tile-arrow-stroke, #3E6295);
    }

    .v-tile__body {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 67px;
        padding: 0 16px;
        color: var(--cts-tile-color, #3E6295);
        @include simple-transition(border-color);
        font-size: 18px;
        line-height: 24px;
        background-color: #fff;
        border-bottom: 4px solid transparent;
    }

    .v-tile:hover {
        transform: scale(1.05);
        z-index: 2;

        .v-tile__body {
            border-color: var(--cts-tile-border-color-active, #003366);
        }

        .v-tile__arrow {
            transform: rotate(-45deg);
        }

        .v-tile__picture-background {
            transform: translate3d(0, 0, 0) scale(1.2);
            -webkit-transform: translate3d(0, 0, 0) scale(1.2);
            filter: grayscale(0) contrast(100%) brightness(100%);
        }

        ::v-deep svg {
            opacity: 1;
        }
    }
</style>