import BaseStatusCalculator from '@/services/domain/statuses/calculators/BaseStatusCalculator';
import {DeviceStatusEnum} from '@/services/domain/statuses/enums/DeviceStatusEnum';
import StatusCalculator, {StatusVariant} from '@/services/domain/statuses/calculators/StatusCalculator';

export default class ProductStatusCalculator<T extends {
    isApproved: boolean,
    isRejected: boolean,
    request: unknown,
} = {
    isApproved: boolean,
    isRejected: boolean,
    request: unknown,
}> extends BaseStatusCalculator implements StatusCalculator {
    constructor(private device: T, private dictionary: Map<string, string>) {
        super();
    }

    statusCode(): string {
        return this.getStatusEnum(this.device);
    }

    statusLabel(): string {
        const code = this.statusCode();

        return this.dictionary.get(code) ?? '';
    }

    statusVariant(): StatusVariant {
        const code = this.statusCode();
        const statusVariantCodeMap = ProductStatusCalculator.getStatusVariantCodeMap();

        return statusVariantCodeMap.find((statusVariantCode) => {
            const variant = statusVariantCode[1] as string[];

            return variant.includes(code);
        })?.[0] ?? 'default';
    }

    private static getStatusVariantCodeMap(): [StatusVariant, string[]][] {
        return [
            ['success', [DeviceStatusEnum.APPROVED, DeviceStatusEnum.REQUEST]],
            ['error', [DeviceStatusEnum.REJECTED]],
        ];
    }

    private getStatusEnum(device: T): DeviceStatusEnum {
        return this.isApproved(device);
    }

    private isApproved(device: T): DeviceStatusEnum {
        return device.isApproved ? this.hasRequest(device) : this.isRejected(device);
    }

    private hasRequest(device: T): DeviceStatusEnum {
        return device.request && !Array.isArray(device.request) && typeof device.request === 'object'
            ? DeviceStatusEnum.REQUEST
            : DeviceStatusEnum.APPROVED;
    }

    private isRejected(device: T): DeviceStatusEnum {
        return device.isRejected ? DeviceStatusEnum.REJECTED : DeviceStatusEnum.PENDING;
    }
}