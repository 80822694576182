<template>
    <div class="product-block__details" :class="{ [size]: true }">
        <product-block-details-dynamic :objects="objects"></product-block-details-dynamic>
    </div>
</template>

<script lang="ts">
import EyeIcon from '@/assets/EyeIcon.svg';
import {formatDate} from '@/helpers/formatDate';
import Device from '@/models/Device';
import {DeviceStatusEnum} from '@/components/devices/ProductLogStatusPropsFactory';
import StatusObjectType from '@/services/domain/statuses/models/StatusObject';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import ioc from '@/services/ioc';
import {ServicesName} from '@AppConfig/DependenciesConfig';
import {Component, Prop, Vue} from 'vue-property-decorator';
import type {ObjType} from './ProductBlockDetailsDynamic.vue';
import productImage from './ProductImage.vue';
import GoToPath from './product-details-components/GoToPath.vue';
import LabelValue from './product-details-components/LabelValue.vue';
import ProductBlockDetailsDynamic from './ProductBlockDetailsDynamic.vue';

@Component({
    components: {EyeIcon, ProductBlockDetailsDynamic},
})
export default class ProductBlockDetails extends Vue {
    @Prop() device!: Device;
    @Prop() documentDetails!: {
        expiredDate: string;
    };

    @Prop({default: 'large'}) size!: string;
    @Prop({default: true}) onlyDate!: boolean;

    objects: ObjType[] = [];

    created() {
        this.objects.push(
            {
                component: productImage,
                props: {
                    id: this.device?.deviceUid ?? 0,
                    size: '88',
                },
            },
            {
                component: LabelValue,
                props: {
                    label: this.device.deviceName,
                    value: `N/S: ${this.device.deviceSerial}`,
                    type: 'device',
                    containerClass: 'device-name',
                    labelStyle: 'extra-bold',
                    valueStyle: '',
                },
            },
        );

        if (this.size === 'large') {
            this.objects.push({
                component: LabelValue,
                props: {
                    label: 'cts.product.register-date',
                    value: this.getRegisterDate,
                    labelStyle: '',
                    valueStyle: 'bold',
                },
            });
        }
        if (this.size === 'large') {
            this.objects.push({
                component: LabelValue,
                props: {
                    label: this.getPurchaseLabel,
                    value: this.getPurchaseDate,
                    labelStyle: '',
                    valueStyle: 'bold',
                },
            });
        }

        this.objects.push(
            {
                component: LabelValue,
                props: {
                    label: 'cts.device.device-status-label',
                    value: this.deviceStatus,
                    containerClass: 'device-status',
                    labelStyle: '',
                    valueStyle: 'bold',

                },
            },
            {
                component: GoToPath,
                props: {
                    label: 'cts.product.btn-details',
                    path: this.deviceStatus,
                    icon: EyeIcon,
                    on: this.onDetailsButtonClick,
                },
            },
        );
    }

    formatDate(date: string | undefined): string {
        return formatDate(date, this.onlyDate ? 'onlyDate' : 'withTime');
    }

    private onDetailsButtonClick() {
        this.$emit('detailsClick', {
            device: this.device,
            deviceName: this.device.deviceName,
            deviceSerial: this.device.deviceSerial,
        });
    }

    get deviceStatus(): string {
        if (!this.device) return ''; const deviceStatus: StatusObjectType = this.getStatusCalculators()
            .deviceStatusCalculator(this.device)
            .toValue();
        if (this.isAdmin && deviceStatus.code === DeviceStatusEnum.PENDING) {
            const url = this.$router.match({
                name: 'SingleDevice',
                params: {
                    id: String(this.device.id),
                },
            });

            return `<a class="link" href="${url.path}">${this.$t(deviceStatus?.label)}</a>`;
        }

        return deviceStatus?.label ?? '';
    }

    private getStatusCalculators(): StatusCalculators {
        const serviceName: ServicesName = this.isAdmin
            ? 'service.statusCalculatorsForAdmin'
            : 'service.statusCalculators';

        return ioc.get(serviceName) as StatusCalculators;
    }

    private get isAdmin() {
        return this.$store.getters['auth/isAdmin'];
    }

    get getRegisterDate(): string {
        return this.formatDate(this.device?.createdAt?.date);
    }

    get getPurchaseDate(): string {
        const type = this.getDocumentType;
        if (type === 'INTERNAL_INVOICE') {
            return this.formatDate(this.device.purchaseDate?.date);
        } if (type === 'INTERNAL_RENTAL_INVOICE') { // TODO: WEB-8656
            return this.formatDate(this.device.documentDetails.expiredDate);
        }

        return this.formatDate(this.device.purchaseDate?.date);
    }

    get getDocumentType(): string {
        return this.device.documentType;
    }

    get getPurchaseLabel(): string {
        const documentType = this.getDocumentType;

        if (documentType === 'INTERNAL_INVOICE') {
            return 'cts.newdeviceform.label-purchase-date';
        } if (documentType === 'INTERNAL_RENTAL_INVOICE') {
            return 'cts.newdeviceform.label-end-date';
        }

        return 'cts.newdeviceform.label-purchase-date';
    }
}
</script>

<style scoped lang="scss">
@import "../../../node_modules/@rawlplug/rawlplug-design-style-tokens/dist/scss/colors";

.product-block {

    &__image {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        max-width: 100%;
        aspect-ratio: 1;
    }

    &__status {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    &__details {
        padding-bottom: 20px;

        &-action__btn {
            grid-column-gap: 0.2em;
            font-size: 1.125em;
            line-height: 0.7;
            height: 100%;
            display: flex;
            align-items: center;
            color: $color-rwl-gray-100;

            &__text {
                display: var(--action-text-display, inline);
            }
        }

        label {
            font-size: 1em;
            color: $color-rwl-gray-100;
            margin-bottom: 0;
        }

        .value {
            font-weight: 400;
            color: #000;
        }

        .base-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-row-gap: 1em;
        }

        .device-name {
            font-weight: 500;
            display: inline-block;
        }

        .product-block__value-label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            grid-row-gap: 1em;
        }

        .product-block__value-label__label {
            display: block;
        }
    }
}
</style>