<template>
    <div class="loading-container" v-if="loading">
        <div class="loader">
            <icon-drill-blue></icon-drill-blue>
        </div>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import IconDrillBlue from '@/assets/IconDrillBlue.svg';

@Component({components: {IconDrillBlue}})
export default class VLoading extends Vue {
    private loading = false;

    mounted() {
        this.$loading.registerLoading((status: boolean) => {
            this.loading = status;
        });
    }
}
</script>
<style lang="scss" scoped>
    .loading-container {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0,0,0,0.3);
    }

    .loader {
        position: fixed;
        width: 200px;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 50%;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        background-color: white;
        border-radius: 5px;

        svg {
            animation: alert 2s linear 0s infinite normal;

            @keyframes alert {
                0% {
                    transform: translateY(0);
                }
                5% {
                    transform: translateY(-10px);
                }
                10% {
                    transform: translateY(10px);
                }
                15% {
                    transform: translateY(-10px);
                }
                20% {
                    transform: translateY(10px);
                }
                25% {
                    transform: translateY(-10px);
                }
                30% {
                    transform: translateY(10px);
                }
                35% {
                    transform: translateY(-10px);
                }
                40% {
                    transform: translateY(10px);
                }
                45% {
                    transform: translateY(-10px);
                }
                50% {
                    transform: translateY(0);
                }
            }
        }
    }
</style>