import ioc from '@/services/ioc';
import admin from '@/store/admin';
import auth from '@/store/auth/authModule';
import {StoreRootState} from '@/store/storeRootState';
import user from '@/store/user/user';
import messages from '@/store/user/messagesModule';
import requestsIndexModule from '@/store/user/requests/requestsIndexModule';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store<StoreRootState>({
    modules: {
        admin,
        user,
        auth,
        requests: requestsIndexModule,
        messages,
    },
});

ioc.set(
    'store',
    // eslint-disable-next-line func-names
    function () {
        return store;
    },
    {},
);

export default store;