<template>
    <div class="v-modal" v-if="active" keep-alive>
        <div class="v-modal__content">
            <header>
                <h2 class="v-modal__title" v-html="title"></h2>
                <v-modal-close-button @click="onCloseClick" v-if="closeButton"/>
            </header>
            <div class="v-modal__message" v-html="params.message"></div>
            <div class="v-modal__actions">
                <button class="button button-outline" @click="onNo">{{ $t('cts.no') }}</button>
                <button class="button button-filled" @click="onYes">{{ $t('cts.yes') }}</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import VModal from '@/components/modals/VModal.vue';
import VModalInterface from '@/components/modals/VModalInterface';
import YesNoModalParamsType from '@/components/modals/YesNoModalParamsType';
import Component from 'vue-class-component';
import {Mixins} from 'vue-property-decorator';

@Component({
    mixins: [VModal],
})
export default class YesNoModal extends Mixins(VModal) {
    params: YesNoModalParamsType = {
        message: '',
        // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
        onYes: (modal: VModalInterface) => {
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars
        onNo: (modal: VModalInterface) => {
        },
    };

    onYes() {
        this.params.onYes(this);
    }

    onNo() {
        this.params.onNo(this);
    }

    // @ts-ignore
    show(params: Record<string, unknown>) {
        this.params = {...(params as YesNoModalParamsType)};
        this.active = true;
        document.body.classList.add('modal-open');
        this.$emit('show');
    }

    onCloseClick() {
        this.params.onNo(this);
    }
}
</script>

<style lang="scss" scoped src="./v-modal-styles.scss"></style>
<style lang="scss" scoped>
    .v-modal__message {
        margin-bottom: 3em;
        text-align: center;
    }
</style>