import {RouteConfig} from 'vue-router';

function routesAddressesIndex(): RouteConfig[] {
    return [
        {
            path: '/user/addresses',
            name: 'AddressesIndex',
            component: () => import(
                '@/views/user/addresses/AddressesIndex.vue'
                /* webpackChunkName: "addresses-index" */
            ),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-your-adresses'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesNewAddress(): RouteConfig[] {
    return [
        {
            path: '/user/addresses/new',
            name: 'NewAddress',
            component: () => import(/* webpackChunkName: "new-address" */ '@/views/user/addresses/NewAddress.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'AddressesIndex', icon: '', title: 'cts.routes.routes-your-adresses'},
                    {name: '', icon: '', title: 'cts.routes.routes-add-new-adress'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

function routesEditAddress(): RouteConfig[] {
    return [
        {
            path: '/user/addresses/:id',
            name: 'EditAddress',
            component: () => import(
                '@/views/user/addresses/EditAddress.vue'
                /* webpackChunkName: "edit-address" */
            ),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'AddressesIndex', icon: '', title: 'cts.routes.routes-your-adresses'},
                    {name: '', icon: '', title: 'cts.routes.routes-edit-adress'},
                ],
                routeFor: ['CLIENT'],
            },
        },
    ];
}

export function routesAddresses(): RouteConfig[] {
    return [
        ...routesAddressesIndex(),
        ...routesNewAddress(),
        ...routesEditAddress(),
    ];
}

export default routesAddresses;