const site = process.env.VUE_APP_SITE;
// eslint-disable-next-line global-require,import/no-dynamic-require,@typescript-eslint/no-var-requires
const siteOptions = site ? require(`../../app-config/sites/${site}`)?.default || null : null;

export function applyFilters(filterName: string, payload: unknown, ...additionalArgs: unknown[]): unknown {
    return siteOptions?.[filterName]?.(payload, ...additionalArgs) || payload;
}

export function getLanguage(defaultLanguage: string): string {
    return siteOptions?.language || defaultLanguage;
}