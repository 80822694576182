import Vue, {VueConstructor} from 'vue';
import '@Framework/installConfigs';
import installDependencies from '@Framework/installDependencies';
import installPlugins from '@Framework/installPlugins';
import installComponents from '@Framework/installComponents';

installDependencies();

function install(vue: VueConstructor, options = {}) {
    installPlugins(vue);
    installComponents(vue);
}

function getVueConfig(app: VueConstructor<Vue>): any {
    return {
        render: (h: any) => h(app)
    }
}

export default {
    install,
    getVueConfig,
}
