// import ioc from '@/services/ioc';
// import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import {RouteConfig} from 'vue-router';

function routesDevicesIndex(): RouteConfig[] {
    return [
        {
            path: '/admin/devices',
            name: 'DevicesIndex',
            component: () => import(/* webpackChunkName: "devices-index" */ '@/views/admin/devices/DevicesIndex.vue'),
            meta: {
                crumbs: [
                    {name: 'RequestsOpenIndex', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.searchview.header-search-products'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
        {
            path: '/admin/devices/:ridNumber',
            name: 'DevicesIndexClient',
            component: () => import(
                '@/views/admin/devices/DevicesIndex.vue'
                /* webpackChunkName: "devices-index-client" */
            ),
            meta: {
                crumbs: [
                    {name: 'RequestsOpenIndex', icon: 'home', title: ''},
                    {name: 'DevicesIndex', icon: '', title: 'cts.searchview.header-search-products'},
                    {name: '', icon: '', title: 'cts.routes.routes-user-products'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

function routesSingleDevice(): RouteConfig[] {
    return [
        {
            path: '/admin/devices/:id',
            name: 'SingleDevice',
            component: () => import(/* webpackChunkName: "single-device" */ '@/views/admin/devices/SingleDevice.vue'),
            meta: {
                crumbs: [
                    {name: 'RequestsOpenIndex', icon: 'home', title: ''},
                    {name: 'DevicesIndex', icon: '', title: 'cts.searchview.header-search-products'},
                    {name: '', icon: '', title: 'cts.routes.routes-product'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

export function routesDevices(): RouteConfig[] {
    return [
        ...routesDevicesIndex(),
        ...routesSingleDevice(),
    ];
}

export default routesDevices;