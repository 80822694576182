import ioc from '@Framework/ioc';
import {Component, Provide, Vue} from 'vue-property-decorator';

@Component
export default class iocProvider extends Vue {
    @Provide() ioc = ioc;

    render() {
        return this.$slots.default
    }
}
