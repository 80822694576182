import defaultNoop from '@/helpers/defaultNoop';

export enum RequestCCStatusEnum {
    'NS' = 'NS',
    'PQ' = 'PQ',
    'AS' = 'AS',
    'IS' = 'IS',
    'SP' = 'SP',
    'DP' = 'DP',
    'CL' = 'CL',
    'CS' = 'CS',
    'SR' = 'SR',
}

export default defaultNoop;