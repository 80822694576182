import {StoreRootState} from '@/store/storeRootState';
import {ActionContext, Module} from 'vuex';
import {MessageConversationType} from '@/models/MessageConversationType';

import MessagesService from '@/services/domain/messages/messagesService';
import ioc from '@/services/ioc';

const messagesService = () => ioc.get('service.messagesService') as MessagesService;

export type AdminMessagesModuleState = {
    messages: MessageConversationType[];
};

const messages: Module<AdminMessagesModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        messages: [],
    },
    mutations: {
        setMessages: (state: AdminMessagesModuleState, payload: MessageConversationType[]) => {
            state.messages = payload;
        },
    },
    actions: {
        async fetchConversations({commit}: ActionContext<AdminMessagesModuleState, StoreRootState>) {
            const messagesList = await messagesService().fetchAdminConversations();

            commit('setMessages', messagesList);

            return messagesList;
        },
        async fetchMessages(_: ActionContext<AdminMessagesModuleState, StoreRootState>, conversationNumber: string) {
            const messagesList = await messagesService().fetchMessages(conversationNumber);
            messagesList.reverse();

            return messagesList;
        },
        async sendReplyMessage(
            _: ActionContext<AdminMessagesModuleState, StoreRootState>,
            {conversationNumber, body}: {conversationNumber: string, body: string},
        ) {
            return messagesService().sendReplyMessage(conversationNumber, body);
        },
    },
};

export default messages;