<template>
    <nav class="aside-nav">
        <menu-renderer menu="admin" v-if="$store.getters['auth/isAdmin']"></menu-renderer>
        <menu-renderer menu="client" v-if="!$store.getters['auth/isAdmin']"></menu-renderer>
    </nav>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import MenuRenderer from '@Framework/components/MenuRenderer.vue';

@Component({
    components: {MenuRenderer},
})
export default class AsideNav extends Vue {}
</script>