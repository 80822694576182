import AdminApiInterface from '@/services/api/AdminApiInterface';
import ClientMapper from '@/services/domain/client/ClientMapper';
import ClientType from '@/services/domain/client/ClientType';
import DeviceMapper from '@/services/domain/device/deviceMapper';
import RawClientType from '@/services/domain/client/RawClientType';
import ResponseDataExtractor from '@/services/domain/ResponseDataExtractor';
import {RequestManagerInterface} from '@/services/requestManager';

type SearchRawDeviceType = {
    'id': number,
    'client_id': string,
    'device_name': string,
    'device_serial': string,
    'complaint_number': string,
    'document_type': string,
    'invoice_number': string,
    'proof_files': string,
    'purchase_country': string,
    'purchase_date': string,
    'created_at': string,
    'updated_at': null,
    'update_number': string,
    'is_approved': string,
    'is_archived': string,
    'device_uid': string
};

export {
    SearchRawDeviceType,
};

export type SearchResultData<Type extends 'device' | 'clients' = 'device' | 'clients'> = {
    data: Type extends 'device' ? SearchRawDeviceType[] : Type extends 'clients' ? RawClientType[] : never;
    type: Type;
};

export interface SearchResult {
    data: SearchResultData[];

    getAll(): SearchResultData[];

    getClients(): ClientType[];

    getProducts(): SearchDeviceType[]
}

export type SearchDeviceType = {
    id: number;
    clientId: number;
    deviceName: string;
    deviceSerial: string;
    documentType: string;
    deviceUid: string;
    invoiceNumber: string;
    isApproved: boolean;
    isArchived: boolean;
    isRejected: boolean;
    proofFiles: unknown[];
    purchaseCountry: string;
    purchaseDate: string;
    createdAt: string;
};

export default class SearchService {
    constructor(
        private requestManager: RequestManagerInterface,
        private adminApi: AdminApiInterface,
        private dataExtractor: ResponseDataExtractor,
        private clientMapper: ClientMapper,
        private deviceMapper: typeof DeviceMapper,
    ) {
    }

    async search(type: string, query: string, limit: number, page: number): Promise<SearchResult> {
        const {hostName, path, method} = this.adminApi.search(type, query, limit, page);
        const request = this.requestManager.getMethodByString(method);
        const response = await request(hostName, path, '');
        const json = await response.json();
        const data = this.dataExtractor.extract(json) as SearchResultData[];

        function isCorrectType(typeToCompare: string): (el: SearchResultData) => boolean {
            return (element: SearchResultData) => element.type === typeToCompare;
        }

        const rawClients = (data.find(isCorrectType('clients')) as SearchResultData<'clients'>)?.data ?? [];
        const clients = rawClients.map((rawClient) => this.clientMapper.map(rawClient));
        const rawDevices = (data.find(isCorrectType('device')) as SearchResultData<'device'>)?.data ?? [];
        const devices = rawDevices.map((product) => ({
            id: +product.id,
            clientId: parseInt(product.client_id, 10),
            deviceName: product.device_name,
            deviceSerial: product.device_serial,
            documentType: '',
            deviceUid: product.device_uid ?? 0,
            invoiceNumber: '',
            isApproved: Boolean(product.is_approved),
            isArchived: Boolean(product.is_archived),
            isRejected: false,
            proofFiles: [],
            purchaseCountry: '',
            purchaseDate: '',
            createdAt: product.created_at,
        }));

        return {
            data,
            getAll() {
                return data;
            },
            getClients(): ClientType[] {
                return clients;
            },
            getProducts(): SearchDeviceType[] {
                return devices;
            },
        };
    }
}