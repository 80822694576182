<template>
    <button class="menu-button button-no">
        <menu-icon @click="onMobileMenuButtonClick"></menu-icon>
    </button>
</template>

<script lang="ts">
import MenuIcon from '@/assets/MenuIcon.svg';
import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {
        MenuIcon,
    },
})
export default class MenuButton extends Vue {
    onMobileMenuButtonClick() {
        this.$modals.show('mobile-menu');
    }
}
</script>