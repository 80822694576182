import ioc from '@/services/ioc';
import {StoreRootState} from '@/store/storeRootState';
import {AppConfigType} from '@AppConfig/AppConfig';
import {Store} from 'vuex';
import {VueConstructor} from 'vue';

export default function installCTSLibrary(
    vue: VueConstructor,
    store: Store<StoreRootState>,
    extraDevicesNamesList: string[] = [],
): void {
    if (!window.CentralToolServiceLibrary) {
        console.error('CentralToolServiceLibrary is not present in window');
        throw new Error('CentralToolServiceLibrary is not present in window');
    }

    const ctsConfig = ioc.get('config.app') as AppConfigType;

    vue.use(window.CentralToolServiceLibrary, {
        store,
        configuration: {
            ...ctsConfig.cts,
            extraDevicesNames: extraDevicesNamesList.map((name) => ({
                name,
                subgroupName: name,
                subgroupId: name,
            })),
        },
        plugins: {},
    });
}