import AddressesService from '@/services/domain/addresses/AddressesService';
import AddressFormDataType from '@/services/domain/addresses/AddressFormDataType';
import {AddressType} from '@/services/domain/forms/contactForm/ContactFormSchemaProvider';
import ioc from '@/services/ioc';
import {StoreRootState} from '@/store/storeRootState';
import {AddressesModuleState} from '@/store/user/types/AddressesModuleState';
import {ActionContext, Module} from 'vuex';

const addresses: Module<AddressesModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        addresses: [],
    },
    mutations: {
        SET_ADDRESSES: (state: AddressesModuleState, payload: AddressType[]) => {
            state.addresses = payload;
        },
    },
    actions: {
        async deleteAddress(
            {state, commit}: ActionContext<AddressesModuleState, StoreRootState>,
            id: number,
        ): Promise<void> {
            const addressesService = ioc.get('service.addresses') as AddressesService;

            if (await addressesService.deleteAddress(id)) {
                commit('SET_ADDRESSES', state.addresses.filter((address: AddressType) => address.id !== id));
            }
        },
        async createAddress(_: ActionContext<AddressesModuleState, StoreRootState>, addressData: AddressFormDataType) {
            const addressesService = ioc.get('service.addresses') as AddressesService;

            return addressesService.registerAddress(addressData);
        },
        async editAddress(_: ActionContext<AddressesModuleState, StoreRootState>, {
            id,
            formData,
        }: {id: number, formData: AddressFormDataType}) {
            const addressesService = ioc.get('service.addresses') as AddressesService;

            return addressesService.editAddress(id, formData);
        },
        async fetchAddresses({commit}: ActionContext<AddressesModuleState, StoreRootState>) {
            const addressesService = ioc.get('service.addresses') as AddressesService;
            commit('SET_ADDRESSES', await addressesService.fetchAddresses());
        },
        async fetchAddress(_: ActionContext<AddressesModuleState, StoreRootState>, id: number) {
            const addressesService = ioc.get('service.addresses') as AddressesService;

            return addressesService.fetchAddress(id);
        },
    },
};

export default addresses;