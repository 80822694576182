import ioc from '@Framework/ioc';
import {VueConstructor} from 'vue';
import MenuRenderer from '@Framework/components/MenuRenderer.vue';
import {Vue} from 'vue-property-decorator';
import ApplicationRoot from '@Framework/components/ApplicationRoot.vue';

const frameworkComponents: Array<[string, VueConstructor<Vue>]> = [
    ['application-root', ApplicationRoot],
    ['menu-renderer', MenuRenderer]
]

export default function installComponents(vue: VueConstructor) {
    frameworkComponents.forEach(([id, component]) => {
        vue.component(id, component);
    });

    (ioc.get('config.components') as any).forEach(([id, component]: any) => {
        vue.component(id, component);
    })
}
