import NotificationType from '@/services/notification-system/NotificationType';

export type ResponseAlert = {
    type: NotificationType,
    message: string
};

export interface ResponseAlertsMapInterface {
    get(bodyType: string, messageStatusCode: string): ResponseAlert | undefined;
}

type ResponseAlertsMapRecord = {
    messageStatusCode: string | RegExp,
    bodyType: string,
    value: ResponseAlert
};

export class ResponseAlertsMap implements ResponseAlertsMapInterface {
    tabs: ResponseAlertsMapRecord[] = [];

    constructor() {
        this.set('RegisterRequest', '200', {
            type: NotificationType.success,
            message: 'alert.GENERIC_SUCCESS',
        });
        this.set('ErrorRegisterRequest', '409_1062', {
            type: NotificationType.error,
            message: 'alert.ERROR_REGISTER_REQUEST_AGAIN',
        });
        this.set('ClientsErrorResponse', '403', {
            type: NotificationType.warning,
            message: 'alert.ERROR_YOU_CANNOT_READ_SECURED_RESOURCE',
        });
        this.set('RejectRequestDevicesResponseModel', '201', {
            type: NotificationType.success,
            message: 'alert.SUCCESS_DEVICE_REQUEST_REJECTION',
        });
        this.set('LoginResponse', '200', {
            type: NotificationType.success,
            message: 'alert.GENERIC_SUCCESS',
        });
        this.set('LoginResponseError', '403', {
            type: NotificationType.error,
            message: 'alert.GENERIC_ERROR',
        });
        this.set('AccountResetResponse', '200', {
            type: NotificationType.success,
            message: 'alert.GENERIC_SUCCESS',
        });
    }

    set(bodyType: string, messageStatusCode: string | RegExp, value: ResponseAlert): void {
        this.tabs.push({
            bodyType,
            messageStatusCode,
            value,
        });
    }

    get(bodyType: string, messageStatusCode: string | RegExp): ResponseAlert | undefined {
        return this.tabs
            .find((item) => item.bodyType === bodyType
                    && item.messageStatusCode === messageStatusCode)?.value;
    }
}