<template>
    <application-root class="app">
        <KeepAlive>
            <v-loading></v-loading>
        </KeepAlive>
        <template #header-nav>
            <language-switcher
                :languages = "$languageSwitcher.getLanguagesList()"
                :current-language = "$languageSwitcher.getCurrentLanguage()"
                :change-language = "$languageSwitcher.setLanguage.bind($languageSwitcher)"
            >
            </language-switcher>
            <div class="header__nav-routers-container">
                <router-link v-if="isLogged" to="/user/your-account">
                    {{ $t('cts.header-nav.your-account') }}
                </router-link>
                <router-link v-if="!isLogged" to="/login">
                    {{ $t('cts.header-nav.login') }}
                </router-link>
                <router-link v-if="!isLogged" :to="{name: 'AnonRegisterDevice'}">
                    {{ $t('cts.header-nav.register') }}
                </router-link>
                <a href="#" @click.prevent.stop="$store.dispatch('auth/logout', {reload: true})"
                   v-if="isLogged">{{ $t('cts.header-nav.logout') }}</a>
            </div>
        </template>
        <template #aside-header v-if="isLogged">Menu:</template>
        <template #main-header>
            <v-breadcrumbs></v-breadcrumbs>
        </template>
        <template #top-bar>
            <div class="top-bar-container">
                <what-you-want-to-do v-if="isLogged && !isAdmin"></what-you-want-to-do>
                <div v-else></div>
                <search-box v-if="isLogged && isAdmin"/>
                <div v-else></div>
                <p class="current-user" v-html="getCurrentUserData()"></p>
            </div>
        </template>
        <template #messages>
            <message-system-box ref="messageSystem"></message-system-box>
        </template>
        <template #aside v-if="isLogged">
            <app-sidebar></app-sidebar>
        </template>
        <template #main>
            <router-view/>
            <mobile-menu></mobile-menu>
            <yes-no-modal name="yes-no-modal"></yes-no-modal>
        </template>
        <modal-container></modal-container>
    </application-root>
</template>

<script lang="ts">
import AppSidebar from '@Framework/components/AppSidebar';
import VLoading from '@/components/loading/VLoading.vue';
import MessageSystemBox from '@/components/MessageSystemBox.vue';
import MobileMenu from '@/components/menu/MobileMenuModal.vue';
import ModalContainer from '@/components/ModalContainer.vue';
import Notification from '@/services/notification-system/Notification';
import SearchBox from '@/components/SearchBox.vue';
import VBreadcrumbs from '@/components/VBreadcrumbs.vue';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';
import WhatYouWantToDo from '@/components/what-you-want-to-do/WhatYouWantToDo.vue';
import YesNoModal from '@/components/modals/YesNoModal.vue';
import {Vue, Component, Watch} from 'vue-property-decorator';
import getClientFullName from './helpers/getClientFullName';

@Component({
    components: {
        AppSidebar,
        MessageSystemBox,
        MobileMenu,
        ModalContainer,
        SearchBox,
        VBreadcrumbs,
        YesNoModal,
        WhatYouWantToDo,
        LanguageSwitcher,
        VLoading,
    },
})
export default class App extends Vue {
    isDev = process.env.NODE_ENV !== 'production';
    // @ts-ignore
    languages = this.$languageSwitcher.getCurrentLanguage();

    getCurrentUserData(): string {
        const {user} = this.$store.state.auth;

        return this.isLogged
            ? `<b>${getClientFullName(user)}</b><br/>${user?.companyName ?? ''}`
            : '';
    }

    async mounted(): Promise<void> {
        await this.$store.dispatch('auth/fetchAndSetUserData');
    }

    @Watch('isAdmin', {
        immediate: true,
    })
    onIsAdminChanged(isAdmin: boolean): void {
        if (isAdmin) {
            this.$store.dispatch('admin/requests/fetchRequests', {
                page: 0,
                order: 'DESC',
                orderBy: 'created_at',
                limit: 10,
            });
            this.$store.dispatch('admin/devices/fetchDevices');
        }
    }

    append(newValue: Notification[]): void {
        const messageSystem: MessageSystemBox = this.$refs.messageSystem as MessageSystemBox;

        if (messageSystem?.notification) {
            messageSystem.notification(newValue);
        }
    }

    get isLogged(): boolean {
        return this.$store.state.auth.status.loggedIn;
    }

    get isAdmin(): boolean {
        return this.$store.getters['auth/isAdmin'];
    }
}
</script>

<style lang="scss" scoped>
    @import "./css/app-scoped/app.scoped.scss";
</style>

<style lang="scss">
    @import "./css/app/app.scss";

    @media (max-width: 1199px) {
        .header__nav-routers-container {
            display: none;
        }
    }
    @media (max-width: 993px) {
        .header__nav {
            margin-left: auto;
        }
    }
</style>