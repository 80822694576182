<template>
    <button class="action-details button-outline x-small" @click="click">
        <span>{{ $t('cts.usersview.show') }}</span>
    </button>
</template>

<script lang="ts">
import TableFieldComponentInterface from '@/components/tables/TableFieldComponentInterface';
import {TableCellData, TableRowData} from '@/services/table-system/Table';
import TableField from '@/services/table-system/TableField';
import {
    Component, Emit, Prop, Vue,
} from 'vue-property-decorator';

@Component({})
export default class ShowActionButton extends Vue implements TableFieldComponentInterface {
    @Prop() cellData!: TableCellData;

    @Prop() field!: TableField;

    @Prop() rowData!: TableRowData;

    @Emit('click')
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    click(): void {}
}
</script>

<style lang="scss" scoped>
    .action-details {
        padding: 0 10px;
        font-size: 16px;
        text-wrap: nowrap;

        span {
            text-wrap: none;
        }
    }
</style>