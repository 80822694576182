import Device from '@/models/Device';
import DeviceArchiverService from '@/services/domain/device/DeviceArchiverService';
import DevicesProvider from '@/services/domain/device/DevicesProvider';
import NotificationService from '@/services/domain/NotificationService';
import RequestArchiver from '@/services/domain/request/RequestArchiver';
import Filterer from '@/services/filterer/Filterer';
import ioc from '@/services/ioc';
import {StoreRootState} from '@/store/storeRootState';
import {ProductsModuleGettersTree, ProductsModuleState} from '@/store/user/types/ProductsModuleState';
import {ActionContext, Module} from 'vuex';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';

const products: Module<ProductsModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        products: [],
        isLoaded: false,
    },
    mutations: {
        ADD_PRODUCT: (state: ProductsModuleState, payload: Device) => {
            state.products = [...state.products, payload];
        },
        SET_PRODUCTS: (state: ProductsModuleState, payload: Device[]) => {
            state.products = payload;
        },
        SET_LOADED: (state: ProductsModuleState, payload: boolean) => {
            state.isLoaded = payload;
        },
    },
    actions: {
        async fetchProducts({commit, state}: ActionContext<ProductsModuleState, StoreRootState>) {
            if (!state.isLoaded) {
                const devicesProvider = ioc.get('provider.devicesProvider') as DevicesProvider;
                const devices = await devicesProvider.getMyDevices();
                commit('SET_LOADED', true);
                commit('SET_PRODUCTS', devices);
            }
        },
        async fetchProduct(ctx: ActionContext<ProductsModuleState, StoreRootState>, productId: number) {
            await ctx.dispatch('fetchProducts');

            return ctx.state.products.find((p) => p.id === productId);
        },
        async archiveProduct(
            {commit, state}: ActionContext<ProductsModuleState, StoreRootState>,
            deviceToArchive: Device,
        ) {
            const deviceArchiver = ioc.get('service.deviceArchiver') as DeviceArchiverService;
            const notification = ioc.get('service.notification') as NotificationService;
            const translator = ioc.get('translator') as LanguageSwitcherInterface;

            try {
                await deviceArchiver.archiveDevice(deviceToArchive);
                const newProducts = state.products.map((device: Device) => {
                    if (device.id === deviceToArchive.id) {
                        device.isArchived = true;
                    }

                    return device;
                });
                commit('SET_PRODUCTS', newProducts);
                notification.success(translator.t('cts.device.device-has-been-archived'));
            } catch (e) {
                console.error(e);
                notification.error(translator.t('cts.device.device-cannot-be-archived'));
            }
        },
        async archiveRequestDevice(
            _: ActionContext<ProductsModuleState, StoreRootState>,
            requestDeviceId: string,
        ): Promise<void> {
            const service = ioc.get('service.requestArchiver') as RequestArchiver;
            await service.archive(requestDeviceId);
        },
    },
    getters: {
        allProducts(state: ProductsModuleState): Device[] {
            return state.products;
        },
        archivedProducts(state: ProductsModuleState, getters) {
            const filterer = ioc.get('service.filterer') as Filterer;

            filterer.addFilter({
                property: 'isArchived',
                sign: '=',
                value: true,
            });

            return filterer.doFilter(getters.allProducts);
        },
        nonArchivedProducts(state: ProductsModuleState, getters) {
            const filterer = ioc.get('service.filterer') as Filterer;

            filterer.addFilter({
                property: 'isArchived',
                sign: '=',
                value: false,
            });

            return filterer.doFilter(getters.allProducts);
        },
    } as ProductsModuleGettersTree,
};

export default products;