import { render, staticRenderFns } from "./WhatYouWantToDo.vue?vue&type=template&id=824c0bdc&scoped=true"
import script from "./WhatYouWantToDo.vue?vue&type=script&lang=ts"
export * from "./WhatYouWantToDo.vue?vue&type=script&lang=ts"
import style0 from "./WhatYouWantToDo.vue?vue&type=style&index=0&id=824c0bdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "824c0bdc",
  null
  
)

export default component.exports