<template>
    <div>
        <router-link :to="requestRouterLinkOptions"
                     class="link"
                     v-if="isStatusPending">
            {{ $t(process.label) }}
        </router-link>
        <span v-else>
            {{ $t(process.label) }}
        </span>
    </div>
</template>

<script lang="ts">
import {RequestStatusEnum} from '@/services/domain/statuses/enums/RequestStatusEnum';
import StatusObjectType from '@/services/domain/statuses/models/StatusObject';
import TableField from '@/services/table-system/TableField';
import {Component, Prop, Vue} from 'vue-property-decorator';
import {RawLocation} from 'vue-router';
import {RequestType} from '@/services/domain/request/RequestType';
import {TableCellData, TableRowData} from '@/services/table-system/Table';

@Component
export default class RequestProcessField<DataRow extends {
    process: StatusObjectType,
    request: RequestType
} & TableRowData,
> extends Vue {
    @Prop({
        required: true,
        validator(value: DataRow): boolean {
            return !!value.process && !!value.request;
        },
    }) rowData!: DataRow;

    @Prop() cellData!: TableCellData;

    @Prop() field!: TableField;

    get isStatusPending(): boolean {
        return this.process.code === RequestStatusEnum.PENDING;
    }

    get process(): StatusObjectType {
        return this.cellData as StatusObjectType;
    }

    get requestRouterLinkOptions(): RawLocation {
        return {name: 'SingleRequest', params: {id: this.rowData.request.requestUuid}};
    }
}
</script>