import {ConsentsURLReplacerInterface} from '@/services/domain/consents/interfaces/ConsentsURLReplacerInterface';

export default class ConsentsURLReplacer implements ConsentsURLReplacerInterface {
    replaceConsentURL(consentDescription: string, urlsMap: Record<string, string>) {
        let description = consentDescription;
        Object.entries(urlsMap).forEach((url) => {
            const urlKey = url[0];
            const urlValue = url[1];
            description = consentDescription.replaceAll(`[${urlKey}]`, urlValue);
        });

        return description;
    }
}