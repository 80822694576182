import Device from '@/models/Device';
import AdminApiInterface from '@/services/api/AdminApiInterface';
import ApiRequestObjectType from '@/services/api/ApiRequestObjectType';
import ParamsOptionsType, {convertOptionsToURLSearchParams, getDefaultParams} from '@/services/api/ParamsOptionsType';
import {RequestType} from '@/services/domain/request/RequestType';

export default class AdminApi implements AdminApiInterface {
    constructor(private readonly apiUrl: string) {
    }

    getDevice(id: number): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            method: 'get',
            path: `/devices/${id}`,
        };
    }

    getDevices(paramsOptions: ParamsOptionsType): ApiRequestObjectType {
        const query = convertOptionsToURLSearchParams({
            ...getDefaultParams(),
            ...paramsOptions,
        });

        return {
            hostName: this.apiUrl,
            method: 'get',
            path: `/devices?${query.toString()}`,
        };
    }

    getClientDevices(ridNumber: string): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            method: 'get',
            path: `/devices/${ridNumber}`,
        };
    }

    approveRequest(id: RequestType['id']): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            method: 'put',
            path: `/approved/device_request/${id}`,
        };
    }

    rejectRequest(id: RequestType['id']): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            method: 'put',
            path: `/reject/device_request/${id}`,
        };
    }

    approveDevice(uid: Device['deviceUid']): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            method: 'put',
            path: `/approved/device/${uid}`,
        };
    }

    rejectDevice(uid: Device['deviceUid']): ApiRequestObjectType {
        return {
            hostName: this.apiUrl,
            method: 'put',
            path: `/reject/device/${uid}`,
        };
    }

    search(type: string, query: string, limit: number, page: number): ApiRequestObjectType {
        const endpoint = new URLSearchParams();
        endpoint.append('query', query);
        endpoint.append('type', type);
        endpoint.append('limit', String(limit));
        endpoint.append('page', String(page));

        return {
            hostName: this.apiUrl,
            method: 'get',
            path: `/search?${endpoint.toString()}`,
        };
    }
}