<template>
    <div class="top-bar">
        <div class="top-bar__container container">
            <slot name="top-bar"></slot>
            <slot name="header-nav"></slot>
        </div>
    </div>
</template>

<script lang="ts">
    import {Vue, Component} from 'vue-property-decorator';

    @Component({})
    export default class BaseAdminTopBar extends Vue {}
</script>

<style scoped lang="scss">
    .top-bar {
        height: 72px;
        padding-top: 16px;
        padding-bottom: 16px;
        background-color: var(--layout-top-bar-background-color);
    }
</style>
