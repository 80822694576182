<template>
    <div v-on:click="on" class="goToPath-container">
        <component v-bind:is="icon" :width="30" :height="30"></component>
        <div class="goToPath__label">{{ $t(label) }}</div>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class GoToPath extends Vue {
    @Prop() label!: string;
    @Prop() path!: string;
    @Prop() icon!: string;
    @Prop() on!: any;
}
</script>

<style scoped lang="scss">
.goToPath-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: #68676c;
    cursor: pointer;
}
</style>