import Device, {AdminDevice, RequestInDeviceType} from '@/models/Device';

// todo WEB-5722
// We have to add status calculation here

const mapDeviceForClient = (deviceObject: Record<string, any>): Device => {
    const request = Array.isArray(deviceObject.request) && deviceObject.request.length === 0
        ? null
        : (deviceObject.request as unknown as RequestInDeviceType);

    return {
        clientId: deviceObject.clientId,
        clientRidNumber: deviceObject.clientRidNumber,
        complaintNumber: deviceObject.complaintNumber,
        createdAt: deviceObject.createdAt,
        deviceFullName: deviceObject.deviceFullName,
        deviceName: deviceObject.deviceName,
        deviceSerial: deviceObject.deviceSerial,
        deviceUid: Number(deviceObject.deviceUid),
        documentType: deviceObject.documentType,
        id: +deviceObject.id,
        invoiceNumber: deviceObject.invoiceNumber,
        proofFileDocumentNumber: deviceObject.proofFileDocumentNumber ?? '',
        proofFiles: deviceObject.proofFiles,
        purchaseCountry: deviceObject.purchaseCountry,
        purchaseDate: deviceObject.purchaseDate,
        updatedAt: deviceObject.updatedAt,
        isArchived: Boolean(+deviceObject.isArchived),
        isApproved: Boolean(+deviceObject.isApproved),
        isRejected: Boolean(deviceObject.isRejected),
        documentDetails: {
            expiredDate: deviceObject.documentDetails?.expiredDate,
        },
        request,
    };
};

const mapDeviceForAdmin = (deviceObject: Record<string, any>): AdminDevice => {
    const request = Array.isArray(deviceObject.request) && deviceObject.request.length === 0
        ? null
        : (deviceObject.request as unknown as RequestInDeviceType);

    return {
        client: deviceObject.client ?? 0,
        clientId: deviceObject.clientId,
        clientRidNumber: deviceObject.clientRidNumber,
        complaintNumber: deviceObject.complaintNumber,
        createdAt: deviceObject.createdAt,
        deviceFullName: deviceObject.deviceFullName,
        deviceName: deviceObject.deviceName,
        deviceSerial: deviceObject.deviceSerial,
        deviceUid: Number(deviceObject.deviceUid),
        documentType: deviceObject.documentType,
        id: +deviceObject.id,
        invoiceNumber: deviceObject.invoiceNumber,
        isApproved: Boolean(+deviceObject.isApproved),
        isArchived: Boolean(+deviceObject.isArchived),
        isRejected: Boolean(deviceObject.isRejected),
        isNew: Boolean(deviceObject.isNew),
        proofFileDocumentNumber: deviceObject.proofFileDocumentNumber ?? '',
        proofFiles: deviceObject.proofFiles,
        purchaseCountry: deviceObject.purchaseCountry,
        purchaseDate: deviceObject.purchaseDate,
        updatedAt: deviceObject.updatedAt,
        request,
    };
};

export default {
    mapDeviceForClient,
    mapDeviceForAdmin,
    mapDevicesForClient(devices: any): Device[] {
        return devices.map((deviceObject: any) => mapDeviceForClient(deviceObject));
    },
    mapDevicesForAdmin(devices: any[]): AdminDevice[] {
        try {
            return devices.map((deviceObject: any) => mapDeviceForAdmin(deviceObject));
        } catch (e) {
            console.log(devices);
            console.error('Cannot map devices for admin');

            return [];
        }
    },
};