<template>
    <div class="product-info__badge badge" :class="variantClass">
        <component v-if="iconName" :is="iconName" width="25" height="21"></component>
        {{ $t(`cts.variant.variant-${variant}`) }}
    </div>
</template>

<script lang="ts">
import StatusObjectType from '@/services/domain/statuses/models/StatusObject';
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class ProductStatusBadge extends Vue {
    @Prop() iconName!: string;
    @Prop() label!: string;
    @Prop({default: 'success'}) variant!: string;
    @Prop() status!: StatusObjectType;

    get variantClass(): string {
        return `badge--${this.getVariant}`;
    }

    get getLabel(): string {
        return this.status?.label ?? this.label;
    }

    get getVariant(): string {
        return this.status?.variant ?? this.variant ?? 'default';
    }
}
</script>