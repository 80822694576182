import defaultNoop from '@/helpers/defaultNoop';
import ioc from '@/services/ioc';

export function authHeader(): Record<string, string> {
    const token = ioc.get('service.authService').getRidToken();

    if (token) {
        return {Authorization: `Bearer ${token}`};
    }

    return {};
}

export default defaultNoop;