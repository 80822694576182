import {SortableModuleState} from '@/store/admin/types/AdminClientsModule';
import {StoreRootState} from '@/store/storeRootState';
import {ActionContext, Module} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';
import RequestCCStatusField from '@/components/tables/fields/RequestCCStatusField.vue';
import {AdminDevice} from '@/models/Device';
import AdminDevicesProvider from '@/services/domain/device/AdminDevicesProvider';
import ClientTableField from '@/components/tables/fields/ClientTableField.vue';
import DeviceProcessField from '@/components/tables/fields/DeviceProcessField.vue';
import DeviceTableField from '@/components/tables/fields/DeviceTableField.vue';
import {createEmptyStatusObject} from '@/services/domain/statuses/models/StatusObject';
import PaginableModuleStateType, {getPaginableModuleState} from '@/store/PaginableModuleStateType';
import ParamsOptionsType from '@/services/api/ParamsOptionsType';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import deviceModule from '@/store/admin/devices/deviceModule';
import equals from '@/helpers/equals';
import ioc from '@/services/ioc';
import {formatDate} from '@/helpers/formatDate';

export type AdminDevicesModuleState = {
    devices: AdminDevice[],
    clientDevices: AdminDevice[],
    lastParamsSearch: ParamsOptionsType | null;
} & PaginableModuleStateType
& SortableModuleState;

async function getAdminDevices(params: ParamsOptionsType) {
    const adminDevicesProvider = ioc.get('provider.adminDevicesProvider') as AdminDevicesProvider;

    return await adminDevicesProvider.getDevices(params) as AdminDevice[];
}

async function getAdminClientDevices(ridNumber: string) {
    const adminDevicesProvider = ioc.get('provider.adminDevicesProvider') as AdminDevicesProvider;

    return await adminDevicesProvider.getClientDevice(ridNumber) as AdminDevice[];
}

const devices: Module<AdminDevicesModuleState, StoreRootState> = {
    namespaced: true,
    modules: {
        single: deviceModule,
    },
    state: {
        devices: [],
        clientDevices: [],
        ...getPaginableModuleState(),
        lastParamsSearch: null,
        sortOptions: [
            {
                order: 'DESC',
                orderBy: 'created_at',
                text: 'cts.filters.option-date-desc',
            },
            {
                order: 'ASC',
                orderBy: 'created_at',
                text: 'cts.filters.option-date-asc',
            },
            {
                order: 'DESC',
                orderBy: 'device_name',
                text: 'cts.filters.option-device-alphabetically-desc',
            },
            {
                order: 'ASC',
                orderBy: 'device_name',
                text: 'cts.filters.option-device-alphabetically-asc',
            },
        ],
        sorting: {
            order: 'DESC',
            orderBy: 'created_at',
            text: 'cts.filters.option-date-desc',
        },
    },
    mutations: {
        SET_DEVICES: (state: AdminDevicesModuleState, payload: AdminDevice[]) => {
            state.devices = payload;
        },
        SET_CLIENT_DEVICES: (state: AdminDevicesModuleState, payload: AdminDevice[]) => {
            state.clientDevices = payload;
        },
        SET_PARAMS_SEARCH: (state: AdminDevicesModuleState, payload: ParamsOptionsType) => {
            state.lastParamsSearch = payload;
        },
        APPROVE_DEVICE: (state: AdminDevicesModuleState, id: number) => {
            const device = state.devices.find((d) => d.id === id);
            if (device) device.isApproved = true;
        },
        updateField,
    },
    actions: {
        async fetchDevices({
            commit,
            state,
        }: ActionContext<AdminDevicesModuleState, StoreRootState>, params: ParamsOptionsType) {
            if (equals(params, state.lastParamsSearch ?? {}) && state.devices.length) {
                return state.devices;
            }
            const devicesList = await getAdminDevices(params);
            if (devicesList) {
                commit('SET_DEVICES', devicesList);
                commit('SET_PARAMS_SEARCH', params);

                return devicesList;
            }

            return [];
        },
        async fetchClientDevices({
            commit,
        }: ActionContext<AdminDevicesModuleState, StoreRootState>, ridNumber: string) {
            const devicesList = await getAdminClientDevices(ridNumber);
            if (devicesList) {
                commit('SET_CLIENT_DEVICES', devicesList);

                return devicesList;
            }

            return [];
        },
    },
    getters: {
        getField,
        devicesIndexFields() {
            return [
                {
                    name: 'device',
                    title: 'cts.table.table-device',
                    component: DeviceTableField,
                },
                {
                    name: 'date',
                    title: 'cts.table.table-registration',
                },
                {
                    name: 'client',
                    title: 'cts.table.table-client',
                    component: ClientTableField,
                },
                {
                    name: 'status',
                    title: 'cts.table.table-status',
                    component: DeviceProcessField,
                },
                {
                    name: 'process',
                    title: 'cts.table.table-process',
                    component: RequestCCStatusField,
                },
                {
                    name: 'actions',
                    title: 'cts.table.table-actions',
                },
            ];
        },
        newProductsCount(state: AdminDevicesModuleState) {
            return state.devices.reduce((acc: number, device) => acc + (device.isNew ? 1 : 0), 0);
        },
        devicesWithClient: (state: AdminDevicesModuleState) => (opt: Record<string, string>) => {
            const {ridNumber} = opt;
            const statusCalculators = ioc.get('service.statusCalculatorsForAdmin') as StatusCalculators;
            let sources = state.devices;
            if (ridNumber) {
                sources = state.clientDevices;
            }

            return sources.map((device: AdminDevice) => ({
                device,
                date: formatDate(device?.createdAt?.date),
                client: {
                    // @ts-ignore todo
                    ridNumber: device.clientRidNumber,
                    ...device.client,
                },
                status: statusCalculators.deviceStatusCalculator(device).toValue(),
                process: device.request
                    ? statusCalculators.requestStatusWithCcCalculator(device.request).toValue()
                    : createEmptyStatusObject(),
            }));
        },
        devicesTableRowsMeta(state: AdminDevicesModuleState) {
            return state.devices.map((device: AdminDevice) => ({
                rowClass: device.isNew ? 'is-new' : '',
            }));
        },
    },
};

export default devices;