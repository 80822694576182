import ClientType from '@/services/domain/client/ClientType';
import RawClientType from '@/services/domain/client/RawClientType';
import {DateType} from '@/services/domain/request/RequestType';

function toDateType(string: string): DateType {
    return {
        date: string, timezone: '', timezone_type: 0,
    };
}

export default class ClientMapper {
    map(rawClient: RawClientType): ClientType {
        return {
            availableAt: toDateType(rawClient.available_at),
            clientType: rawClient.client_type,
            companyName: rawClient.company_name,
            createdAt: toDateType(rawClient.created_at),
            email: rawClient.email,
            firstName: rawClient.first_name,
            language: rawClient.language,
            lastName: rawClient.last_name,
            phone: rawClient.phone,
            reservedAt: toDateType(rawClient.reserved_at),
            ridNumber: rawClient.rid_number,
            taxNumber: rawClient.tax_number,
            updatedAt: toDateType(rawClient.updated_at),
        };
    }
}