<template>
    <div class="copyright-text">
        ©{{(new Date()).getFullYear()}} {{companyName}}
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop} from 'vue-property-decorator';

    @Component({})
    export default class CopyrightText extends Vue {
        @Prop() companyName!: string
    }
</script>
