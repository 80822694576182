<template>
    <ul class="aside-menu">
        <component :is="item.component" :menu-item="item" :key="item.to" v-for="item in items">
            <router-link :to="item.to">{{ item.text }}</router-link>
        </component>
    </ul>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})
export default class MobileMenu extends Vue {
    @Prop() items!: any[];
}
</script>

<style scoped lang="scss">
    @import "~@rawlplug/rawlplug-design-style-tokens/dist/scss/colors";

    ul {
        margin: 0;
        padding: 0;

        &::v-deep {
            li {
                font-size: 1.25em;
                line-height: 2.35;
                list-style-type: none;
                border-bottom: 1px solid #3C7EC1;
            }

            a {
                display: block;
                color: #FFF;
                text-decoration: none;
                font-weight: 300;

                &.router-link-exact-active {
                    font-weight: 400;
                }
            }
        }
    }
</style>