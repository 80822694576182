// @ts-ignore todo Create type for response manager
import {formatDate} from '@/helpers/formatDate';
import RequestRegister from '@/services/domain/request/RequestRegister';
import RequestsProvider from '@/services/domain/request/RequestsProvider';
import {RequestType} from '@/services/domain/request/RequestType';
import ResponseHandler from '@/services/domain/ResponseHandler';
import StatusCalculators from '@/services/domain/statuses/StatusCalculators';
import ioc from '@/services/ioc';
import {StoreRootState} from '@/store/storeRootState';
import RequestModule from '@/store/user/requests/requestModule';
import {RegisterRequestPayload, RequestsModuleState} from '@/store/user/types/requestsModuleState';
import tableFields from '@/store/user/requests/tableFields';
import {ActionContext, Module} from 'vuex';

export const requests: Module<RequestsModuleState, StoreRootState> = {
    namespaced: true,
    modules: {
        single: RequestModule,
    },
    state: {
        requests: [],
    },
    mutations: {
        SET_REQUESTS: (state, payload) => {
            state.requests = payload;
        },
    },
    actions: {
        async registerRequest(
            {dispatch}: ActionContext<RequestsModuleState, StoreRootState>,
            payload: RegisterRequestPayload,
        ): Promise<boolean> {
            const requestRegister = ioc.get('service.requestRegister') as RequestRegister;
            const responseHandler = ioc.get('service.responseHandler') as ResponseHandler;

            try {
                const response = await requestRegister.register(
                    payload.repairFormData,
                    payload.contactFormData,
                    payload.anotherAddresses,
                );
                responseHandler.handle(response);
                dispatch('fetchRequests');

                return true;
            } catch (e) {
                responseHandler.handleFatal(e);
            }

            return false;
        },
        async fetchRequests({commit}, {page, limit} = {page: 0, limit: 500}) {
            const responseHandler = ioc.get('service.responseHandler') as ResponseHandler;
            const requestProvider = ioc.get('provider.adminRequestsProvider') as RequestsProvider;

            try {
                const data = await requestProvider.getUserRequests({
                    page, limit, order: 'DESC', orderBy: 'created_at',
                });
                commit('SET_REQUESTS', data);
            } catch (e) {
                responseHandler.handleFatal(e);
            }
        },
    },
    getters: {
        requestsDataset(state: RequestsModuleState) {
            const statusCalculators = ioc.get('service.statusCalculators') as StatusCalculators;

            return state.requests.map((request: RequestType) => ({
                date: formatDate(request.createdAt.date),
                ...request,
                status: statusCalculators.requestStatusCalculator(request).toValue(),
            }));
        },
        requestsTableFields() {
            return tableFields;
        },
    },
};

export default requests;