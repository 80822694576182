import ClientTableField from '@/components/tables/fields/ClientTableField.vue';
import DeviceTableField from '@/components/tables/fields/DeviceTableField.vue';
import RequestCCStatusField from '@/components/tables/fields/RequestCCStatusField.vue';
import RequestProcessField from '@/components/tables/fields/RequestProcessField.vue';
import ShowActionButton from '@/components/tables/fields/ShowActionButton.vue';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import ioc from '@/services/ioc';

export default function () {
    const translator = ioc.get('translator') as LanguageSwitcherInterface;
    const t = translator.t.bind(translator);

    return [
        {
            name: 'date',
            title: t('cts.table.table-date'),
        },
        {
            name: 'client',
            title: t('cts.table.table-client'),
            component: ClientTableField,
        },
        {
            name: 'device',
            title: t('cts.table.table-device'),
            component: DeviceTableField,
        },
        {
            name: 'status',
            title: t('cts.table.table-status'),
            component: RequestCCStatusField,
        },
        {
            name: 'process',
            title: t('cts.table.table-process'),
            component: RequestProcessField,
        },
        {
            name: 'actions',
            title: t('cts.table.table-actions'),
            component: ShowActionButton,
        },
    ];
}