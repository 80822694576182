import {StoreRootState} from '@/store/storeRootState';
import addresses from '@/store/user/addressesModule';
import clientModule from '@/store/user/clientModule';
import newDevice from '@/store/user/devices/newDeviceModule';
import home from '@/store/user/homeModule';
import messages from '@/store/user/messagesModule';
import productsModule from '@/store/user/productModule';
import requestsIndexModule from '@/store/user/requests/requestsIndexModule';
import spareParts from '@/store/user/sparePartsModule';
import {UserModuleState} from '@/store/user/types/userModuleState';
import {Module} from 'vuex';

const user: Module<UserModuleState, StoreRootState> = {
    namespaced: true,
    modules: {
        addresses,
        client: clientModule,
        home,
        messages,
        products: productsModule,
        requests: requestsIndexModule,
        spareParts,
        newDevice,
    },
};

export default user;