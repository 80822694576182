import Device from '@/models/Device';
import {StoreRootState} from '@/store/storeRootState';
import {HomeModuleState} from '@/store/user/types/homeModuleState';
import {ActionContext, Module} from 'vuex';

const initialState: HomeModuleState = {
    productsLogs: [],
};

const home: Module<HomeModuleState, StoreRootState> = {
    namespaced: true,
    state: initialState,
    actions: {
        async init({dispatch}: ActionContext<HomeModuleState, StoreRootState>) {
            await dispatch('user/products/fetchProducts', {}, {root: true});
        },
    },
    getters: {
        products(state, getters, rootState) {
            const productsCount = 3;

            return rootState.user.products.products
                .filter((product: Device) => product.isRejected || product.isApproved)
                .sort((productA: Device, productB: Device) => {
                    const dateA: Date = productA.updatedAt ? new Date(productA.updatedAt?.date) : new Date(0);
                    const dateB: Date = productB.updatedAt ? new Date(productB.updatedAt?.date) : new Date(0);

                    return dateA > dateB ? -1 : 1;
                })
                .slice(0, productsCount);
        },
    },
};

export default home;