<template>
    <div class="wrapper what-you-want-to-do-wrapper">
        <label id="what-you-want-to-do" class="button-outline" for="what-you-want-to-do-select" :class="active">
            {{ $t('cts.homeview.main-action-button') }}
            <select id="what-you-want-to-do-select" class="select" ref="select"
                    v-model="route" @change="onChange" @click="onClick">
                <option value="NewDevice">{{ $t('cts.homeview.register-product') }}</option>
                <option value="RepairRequest">{{ $t('cts.homeview.request-repair') }}</option>
                <option value="OrderParts">{{ $t('cts.homeview.order-parts') }}</option>
                <option value="DeviceManuals">{{ $t('cts.homeview.manuals-download') }}</option>
            </select>
        </label>
    </div>
</template>

<script lang="ts">
import {Vue, Component} from 'vue-property-decorator';

@Component({})
export default class WhatYouWantToDo extends Vue {
    active = '';

    route = '';

    opened = false;

    mounted(): void {
        document.addEventListener('click', (e: MouseEvent) => {
            if (this.isOutside(e.target as HTMLElement)) {
                this.opened = false;
            }
        });
    }

    onChange(e: Event): void {
        this.unfocusElement(e.target as HTMLElement);
        this.$router.push({name: this.route});
    }

    onClick(e: Event): void {
        if (this.opened) {
            this.unfocusElement(e.target as HTMLElement);
        }
        this.opened = !this.opened;
    }

    unfocusElement(element: HTMLElement): void {
        element.blur();
    }

    isOutside(element: HTMLElement): boolean {
        return element !== this.$refs.select;
    }
}
</script>

<style lang="scss" scoped>
    @import "~@rawlplug/rawlplug-design-style-tokens/dist/scss/colors";

    .what-you-want-to-do-wrapper {
        #what-you-want-to-do {
            position: relative;
            width: 255px;
            height: 40px;
            padding: 0px 15px 0px 10px;
            margin-bottom: unset;
            font-size: 15px;
            cursor: pointer;

            &::after {
                content: "";
                width: 10px;
                height: 10px;
                display: block;
                transform: rotate(-45deg);
                margin: 0 0 5px auto;
                border-left: $color-rwl-orange-100 1px solid;
                border-bottom: $color-rwl-orange-100 1px solid;
                z-index: 0;
            }

            select {
                width: 100%;
                background-color: rgba(0, 0, 0, 0);
                color: rgba(0,0,0,0);
                position: absolute;
                border: none;
                left: -2px;
                top: -4px;
                cursor: pointer;
                z-index: 1;
                user-select: none;
            }

            select option {
                color: black;
                cursor: pointer;
            }
        }

        #what-you-want-to-do:focus-within {
            border-radius: 24px 24px 0 0;
            border-color: $color-rwl-red-100;
            border-bottom: none;
        }
    }
</style>