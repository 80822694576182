// eslint-disable max-len
<template>
    <div :style="getStyle()">
        <span v-for="(crumb, i) in crumbs" :key="i" class="crumb" :class="{'has-link': crumb.name}">
            <svg v-if="crumb.icon === 'home'"
                 width="20"
                 height="16"
                 class="home-icon"
                 @click="goToCrumb(crumb)" viewBox="0 0 20 16"
                 fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19.8183 6.90909L15.8183 4V0.727273H14.3637V2.90909L10.3637 0L0.90918
                      6.90909L1.63645 8L3.81827 6.54545V16H16.9092V6.54545L19.091 8L19.8183
                      6.90909ZM9.27282 14.5455V9.09091H11.8183V14.5455H9.27282ZM15.4546
                      14.5455H13.2728V7.63636H7.81827V14.5455H5.27282V5.45455L10.3637 1.81818L15.4546
                      5.45455V14.5455Z"/>
            </svg>
            <span v-else class="item">
                <span class="item__title" @click="goToCrumb(crumb)">{{ t(crumb.title) }}</span>
            </span>
        </span>
    </div>
</template>
<script lang="ts">
import ioc from '@/services/ioc';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import {
    Component, Vue,
} from 'vue-property-decorator';

@Component({})
export default class VBreadcrumbs extends Vue {
    private translator = ioc.get('translator') as LanguageSwitcherInterface;
    private t = this.translator.t.bind(this.translator);

    get crumbs() {
        return this.$route?.matched?.[0]?.meta?.crumbs;
    }

    goToCrumb(crumb: any) {
        if (crumb.name !== '') {
            this.$router.push({
                name: crumb.name,
            });
        }
    }

    getStyle() {
        return `url("data:image/svg+xml,%3Csvg width='8' height='12' viewBox='0 0 8 12' fill='none'
            xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd'
            d='M0.965777 0.85725L6.59642 5.99975L0.965777 11.1428C0.76922 11.3388 0.76922 11.6569
            0.965777 11.853C1.16233 12.049 1.48143 12.049 1.67799 11.853L7.66923 6.38036C7.77426
            6.27583 7.81878 6.13679 7.81178 5.99975C7.81878 5.86321 7.77426 5.72417 7.66923 5.61964L1.67799
            0.147043C1.48143 -0.0490143 1.16233 -0.0490143 0.965777 0.147043C0.76922 0.3431 0.76922 0.660693
            0.965777 0.85725Z' fill='%239A9B9C'/%3E%3C/svg%3E%0A")`;
    }
}
</script>

<style lang="scss" scoped>
    .item {
        margin-left: 8px;

        &::before {
            content: '';
            margin-right: 7px;
            display: inline-block;
            width: 8px;
            height: 12px;
            background-image: var(--icon);
        }
    }

    .home-icon {
        fill: #9A9B9C;
        transform: translateY(2px);
    }

    .has-link .home-icon {
        fill: #FF8A00;
        cursor: pointer;
    }

    .has-link .item__title {
        color: #F05600;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
</style>