import getClientFullName from '@/helpers/getClientFullName';
import toFormData from '@/helpers/toFormData';
import Client from '@/models/Client';
import Device from '@/models/Device';
import ClientApiInterface from '@/services/api/ClientApiInterface';
import {AddressType} from '@/services/domain/forms/contactForm/ContactFormSchemaProvider';
import {RequestManagerInterface} from '@/services/requestManager';

export type OrderPartsDeviceFormDataType = {
    product: number,
    description: string,
    files: string[],
};
export type OrderPartsContactFormDataType = {address: AddressType | number | null, phone: string};
export type OrderPartsFullFormDataType = OrderPartsContactFormDataType & OrderPartsDeviceFormDataType;

export default class SparePartsService {
    constructor(private requestManager: RequestManagerInterface, private api: ClientApiInterface) {
    }

    async sendSparePartsRequest(
        requestData: OrderPartsFullFormDataType,
        clientData: Client,
        productData: Device,
    ): Promise<unknown> {
        const {hostName, path, method} = this.api.registerSpareParts();
        const request = this.requestManager.getMethodByString(method);
        const formData = SparePartsService.prepareData(clientData, requestData, productData);
        const response = await request(hostName, path, formData);

        return response.json();
    }

    private static prepareData(clientData: Client, requestData: OrderPartsFullFormDataType, productData: Device) {
        const data = {
            clientRequests: [{
                name: getClientFullName(clientData),
                email: clientData.email,
                phone: requestData.phone,
                deviceName: productData.deviceName,
                purchaseCountry: productData.purchaseCountry,
                description: requestData.description,
                files: requestData.files,
                consents: '{}',
            }],
        };

        return toFormData(data);
    }
}