<template>
    <li>
        <router-link :to="menuItem.to">
            {{ menuItem.text }} {{ unreadMessages }}
        </router-link>
    </li>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import MenuItem from '@Framework/interfaces/MenuItem';

@Component({})
export default class MessagesMenuItem extends Vue {
    @Prop() menuItem!: MenuItem;

    async mounted(): Promise<void> {
        await this.$store.dispatch('messages/fetchConversations');
    }

    get unreadMessages(): string {
        const unReads = this.$store.getters['messages/unreadMessages'];

        if (unReads) {
            return ` (${unReads})`;
        }

        return '';
    }
}
</script>