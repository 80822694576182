import MessageInterface from '@/services/message-factory/MessageInterface';
import Notification from '@/services/notification-system/Notification';
import NotificationType from '@/services/notification-system/NotificationType';

export default class MessageDirector {
    constructor(private builder: MessageInterface) {
    }

    setSuccess(text: string, data: any): Notification {
        return this.builder
            .setType(NotificationType.success)
            .setText(text)
            .setData(data)
            .getMessage();
    }

    setWarning(text: string, data: any) {
        return this.builder
            .setType(NotificationType.warning)
            .setText(text)
            .setData(data)
            .getMessage();
    }

    setError(text: string, data: any) {
        return this.builder
            .setType(NotificationType.error)
            .setText(text)
            .setData(data)
            .getMessage();
    }

    setInfo(text: string, data: any) {
        return this.builder
            .setType(NotificationType.info)
            .setText(text)
            .setData(data)
            .getMessage();
    }
}