<template>
    <div class="v-modal" v-if="active" keep-alive>
        <div class="v-modal__content">
            <header>
                <h2 class="v-modal__title" v-html="title"></h2>
                <v-modal-close-button @click="close" v-if="closeButton"/>
            </header>
            <slot></slot>
        </div>
    </div>
</template>

<script lang="ts">
import VModalCloseButton from '@/components/modals/VModalCloseButton.vue';
import VModalInterface from '@/components/modals/VModalInterface';
import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({
    components: {VModalCloseButton},
})
export default class VModal extends Vue implements VModalInterface {
    active = false;

    @Prop() title!: string;

    @Prop() name!: string;

    @Prop({default: true}) closeButton = true;

    created() {
        this.$modals.add(this.name, this);
    }

    beforeDestroy() {
        this.$modals.remove(this.name);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    show<T extends Record<string, unknown>>() {
        this.active = true;
        document.body.classList.add('modal-open');
        this.$emit('show');
    }

    close() {
        this.active = false;
        document.body.classList.remove('modal-open');
        this.$emit('close');
    }

    hide() {
        return this.close();
    }
}
</script>

<style scoped lang="scss" src="./v-modal-styles.scss"></style>