import Notification from '@/services/notification-system/Notification';
import NotificationSystem from '@/services/notification-system/NotificationSystem';
import MessageDirector from '@/services/message-factory/MessageDirector';

export default class NotificationService {
    constructor(private notificationSystem: NotificationSystem, private messageDirector: MessageDirector) {}

    success(text: string) {
        this.createAndAddMessage(this.messageDirector.setSuccess.bind(this.messageDirector), text);
    }

    warning(text: string) {
        this.createAndAddMessage(this.messageDirector.setWarning.bind(this.messageDirector), text);
    }

    error(text: string) {
        this.createAndAddMessage(this.messageDirector.setError.bind(this.messageDirector), text);
    }

    info(text: string) {
        this.createAndAddMessage(this.messageDirector.setInfo.bind(this.messageDirector), text);
    }

    private createAndAddMessage(createMessageFunction: (text: string, data: unknown) => Notification, text: string) {
        const message = createMessageFunction(text, {});
        message.render = () => text;
        this.notificationSystem.add(message);
    }
}