<template>
    <div class="message-system-box">
        <div class="message-system-box__container" v-if="reversedNotification.length > 0">
            <div v-for="(message) in reversedNotification" :key="message.id"
                 :class="`notification notification--${message.type} notification-${message.id}`">
                <component
                    v-if="message.returnType === 'object'"
                    :is="message.data"></component>
                <div v-else v-html="message.render()"></div>
                <button @click="close(message)">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13 1L7 7M7 7L1 13M7 7L1 1M7 7L13 13" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import Notification from '@/services/notification-system/Notification';

@Component({})
export default class MessageSystemBox extends Vue {
    notifications: Array<Notification & {id: number}> = [];

    notificationAutoincrement = 0;

    public notification(notification: Notification[]) {
        const newNotifications: Array<Notification & {id: number}> = [];
        notification.forEach((not) => {
            newNotifications.push({
                ...not,
                id: this.notificationAutoincrement,
            });
            this.notificationAutoincrement++;
        });
        this.notifications.push(...newNotifications);
        setTimeout(() => {
            newNotifications.forEach((n) => this.close(n));
        }, 3500);
    }

    close(notificationToDelete: Notification) {
        this.notifications = this.notifications
            .filter((notification: Notification) => notification !== notificationToDelete);
    }

    get reversedNotification() {
        return [...this.notifications].reverse();
    }
}
</script>

<style scoped lang="scss">
    .message-system-box {
        position: fixed;
        z-index: 100;
        left: 50%;
        transform: translate(-50%);
        right: 0.5em;
        top: 0.5em;
        width: 100%;
        max-width: 1140px;

        &__container {
            padding-top: 1em;
            padding-bottom: 1em;
            position: absolute;
            width: 100%;
        }
    }

    .notification {
        animation: disapear .5s ease forwards;
        animation-delay: 3s;
    }

    @keyframes disapear {
        0% {
            opacity: 1
        }
        100% {
            opacity: 0
        }
    }
</style>