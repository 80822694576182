import ApiResponseType from '@/services/api/ApiResponseType';
import ClientApiInterface from '@/services/api/ClientApiInterface';
import {AuthServiceInterface} from '@/services/AuthService';
import AddressFormDataType from '@/services/domain/addresses/AddressFormDataType';
import {AddressType} from '@/services/domain/forms/contactForm/ContactFormSchemaProvider';
import ResponseDataExtractor from '@/services/domain/ResponseDataExtractor';
import {RequestManagerInterface} from '@/services/requestManager';

export default class AddressesService {
    constructor(
        private requestManager: RequestManagerInterface,
        private clientApi: ClientApiInterface,
        private authService: AuthServiceInterface,
        private dataExtractor: ResponseDataExtractor,
    ) {
    }

    async fetchAddresses(): Promise<AddressType[]> {
        const ridNumber = this.authService.getRidNumber();
        const requestObject = this.clientApi.fetchAddresses(ridNumber);
        const data = await this.requestManager.call(requestObject, '');
        const json = await data.json();

        return this.dataExtractor.extract(json) as AddressType[] ?? [];
    }

    async fetchAddress(id: number): Promise<AddressType | undefined> {
        const addresses = await this.fetchAddresses();

        return addresses.find((address: AddressType) => address.id === id);
    }

    async deleteAddress(id: number): Promise<boolean> {
        const {hostName, path, method} = this.clientApi.deleteAddress(id);
        const response = await this.requestManager.getMethodByString(method)(hostName, path, '', '');
        const data = await response.json();

        return data.status;
    }

    async registerAddress(addressData: AddressFormDataType): Promise<ApiResponseType> {
        const response = await this.requestManager.call(
            this.clientApi.registerAddress(),
            JSON.stringify(addressData),
            '',
        );

        return response.json();
    }

    async editAddress(addressId: number, addressData: AddressFormDataType): Promise<ApiResponseType> {
        const response = await this.requestManager.call(
            this.clientApi.editAddress(addressId),
            JSON.stringify(addressData),
            '',
        );

        return response.json();
    }
}