import Device from '@/models/Device';
import AdminApi from '@/services/api/AdminApi';
import MessagesService from '@/services/domain/messages/messagesService';
import {RequestManagerInterface} from '@/services/requestManager';
import ioc from '@/services/ioc';
import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';
import ResponseHandler from '@/services/domain/ResponseHandler';
import ResponseDataExtractor from '@/services/domain/ResponseDataExtractor';

export default class DeviceService {
    private readonly t: (term: string) => string;

    constructor(
        private requestManager: RequestManagerInterface,
        private apiAdmin: AdminApi,
        private messagesService: MessagesService,
    ) {
        const translator = ioc.get('translator') as LanguageSwitcherInterface;
        this.t = translator.t.bind(translator);
    }

    async approveDevice(device: Device): Promise<number> {
        const {hostName, path, method} = this.apiAdmin.approveDevice(device.id);
        const response = await (this.requestManager.getMethodByString(method))(hostName, path, '', '');
        const responseJson = await response.json();
        const responseHandler = ioc.get('service.responseHandler') as ResponseHandler;
        const responseDataExtractor = ioc.get('service.responseDataExtractor') as ResponseDataExtractor;
        responseHandler.handle(responseJson);
        const result = responseDataExtractor.extract(responseJson) as number[];

        return result[0];
    }

    async approveDeviceAndNotifyUser(device: Device, message: string): Promise<number> {
        const deviceId = await this.approveDevice(device);

        if (deviceId) {
            const acceptedText = 'cts.messagesubject.device-accepted-message-subject';
            const subject = `${acceptedText} ${device.deviceName} ${device.deviceSerial}`;
            await this.sendMessageToClient(device, subject, message);
        }

        return deviceId;
    }

    async rejectDeviceAndNotifyUser(device: Device, message: string): Promise<boolean> {
        if (await this.rejectDevice(device)) {
            const rejectionText = 'cts.messagesubject.device-rejected-message-subject';
            const subject = `${rejectionText} ${device.deviceName} ${device.deviceSerial}`;
            await this.sendMessageToClient(device, subject, message);

            return true;
        }

        return false;
    }

    private async rejectDevice(device: Device): Promise<boolean> {
        const {hostName, path, method} = this.apiAdmin.rejectDevice(device.id);
        const response = await (this.requestManager.getMethodByString(method))(hostName, path, '', '');
        const result = await response.json();

        return !!result?.body?.device;
    }

    private async sendMessageToClient(device: Device, subject: string, message: string) {
        await this.messagesService.sendMessage(
            subject,
            message,
            [device.clientRidNumber],
        );
    }
}