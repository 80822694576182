export default class ModalsContainer {
    modals = new Map<string, any>();

    add(key: string, value: any) {
        if (this.modals.has(key)) {
            console.warn(`There is already a modal with that key ${key}`);

            return false;
        }
        this.modals.set(key, value);
        console.log(`Register modal ${key}`);

        return true;
    }

    remove(key: string) {
        this.modals.delete(key);
    }

    show(key: string, params?: unknown) {
        if (this.modals.has(key)) {
            const modal = this.modals.get(key);
            if (params) modal.show(params);
            else modal.show();

            return true;
        }
        console.warn(`Cannot find modal ${key}`);

        return false;
    }

    hide(key: string) {
        if (this.modals.has(key)) {
            this.modals.get(key).hide();
            console.log(`Hide modal ${key}`);

            return true;
        }
        console.warn(`Cannot find modal ${key}`);

        return false;
    }
}