<template>
    <li>
        <router-link :to="menuItem.to">
            {{ menuItem.text }} {{ newRequestsCount }}
        </router-link>
    </li>
</template>

<script lang="ts">
import {Vue, Component, Prop} from 'vue-property-decorator';
import MenuItem from '@Framework/interfaces/MenuItem';

@Component({})
export default class AdminRequestsMenuItem extends Vue {
    @Prop() menuItem!: MenuItem;

    get newRequestsCount(): string {
        const newRequestsCount = this.$store.getters['admin/requests/newRequestsCount'];

        return newRequestsCount ? ` (${newRequestsCount})` : '';
    }
}
</script>