import ApiResponseType from '@/services/api/ApiResponseType';
import SsoApiInterface from '@/services/api/SsoApiInterface';
import ResponseDataExtractor from '@/services/domain/ResponseDataExtractor';
import ResponseHandler from '@/services/domain/ResponseHandler';
import {RequestManagerInterface} from '@/services/requestManager';

export default class SsoService {
    constructor(
        private requestManager: RequestManagerInterface,
        private ssoApi: SsoApiInterface,
        private responseHandler: ResponseHandler,
        private responseDataExtractor: ResponseDataExtractor,
    ) {
    }

    async login(login: string, password: string): Promise<string | undefined> {
        const {hostName, path, method} = this.ssoApi.login();
        const call = this.requestManager.getMethodByString(method);
        const response = await call(hostName, path, JSON.stringify({login, password}));
        try {
            const json = await response.json() as ApiResponseType;
            if (this.responseHandler.handle(json)) {
                return this.responseDataExtractor.extract(json) as string;
            }
        } catch (e) {
            this.responseHandler.handleFatal(e);
        }

        return undefined;
    }

    async reset(login: string, returnUrl: string): Promise<undefined | boolean> {
        const {hostName, path, method} = this.ssoApi.reset();
        const call = this.requestManager.getMethodByString(method);
        const response = await call(hostName, path, JSON.stringify({login, returnUrl}));
        try {
            const json = await response.json() as ApiResponseType;
            this.responseHandler.handle(json);

            return this.responseDataExtractor.extract(json, 'status') as boolean;
        } catch (e) {
            this.responseHandler.handleFatal(e);
        }

        return undefined;
    }
}