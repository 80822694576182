import defaultNoop from '@/helpers/defaultNoop';

const pad = (dt: number) => (dt < 10 ? '0' : '') + dt;

export function formatDate(date: string | undefined, type: 'onlyDate' | 'withTime' = 'withTime'): string {
    if (typeof date === 'undefined' || date === '-') return '-';

    try {
        const d = new Date(date);
        const [month, day, year] = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
        const [hour, minutes] = [pad(d.getHours()), pad(d.getMinutes()), d.getSeconds()];

        if (type === 'withTime') {
            return `${year}/${month}/${day}, ${hour}:${minutes}`;
        } if (type === 'onlyDate') {
            return `${year}/${month}/${day}`;
        }

        return date;
    } catch {
        return date;
    }
}

export default defaultNoop;