import {DeviceStatusEnum} from '@/services/domain/statuses/enums/DeviceStatusEnum';

export interface ProductLogStatusProps {
    icon: string;
    badge: string;
    actionName: string;
    statusName: string;
}

export class ProductLogStatusPropsFactory {
    create(b: {
        actionName: string,
        statusName: string,
        status: {
            isApproved: boolean,
            isRejected: boolean,
        }
    }): ProductLogStatusProps {
        return {
            actionName: b.actionName,
            icon: b.status.isApproved ? 'status-completed-icon' : 'status-rejected-icon',
            badge: b.status.isApproved ? 'success' : 'error',
            statusName: b.statusName,
        };
    }

    createSuccess(actionName: string, statusName: string): ProductLogStatusProps {
        return {
            icon: 'status-completed-icon',
            badge: 'success',
            actionName,
            statusName,
        };
    }

    createError(actionName: string, statusName: string): ProductLogStatusProps {
        return {
            icon: 'status-rejected-icon',
            badge: 'error',
            actionName,
            statusName,
        };
    }

    createBase(): ProductLogStatusProps {
        return {
            icon: 'status-completed-icon',
            badge: 'info',
            actionName: '',
            statusName: '',
        };
    }
}

export {
    DeviceStatusEnum,
};