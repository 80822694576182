<template>
    <div class="footer-renderer container">
        <component v-for="(item, index) in items" :is="item[0]" v-bind="item[1]" :key="index">
            <component v-for="(subItem, subIndex) in item[2]" :is="subItem[0]" v-bind="subItem[1]" :key="subIndex">{{ subItem[2] }}</component>
        </component>
    </div>
</template>

<script lang="ts">
    import {Vue, Component, Prop} from 'vue-property-decorator';

    @Component
    export default class FooterRenderer extends Vue {
        @Prop() items!: any[];
    }
</script>
