<template>
    <section>
        <div class="infos">
            <product-log-block
                v-for="product in products"
                :key="product.id"
                :device="product"
            />
        </div>
    </section>
</template>

<script lang="ts">
import ProductBlockDetails from '@/components/devices/ProductBlockDetails.vue';
import ProductLogBlock from '@/components/devices/ProductLogBlock.vue';
import Device from '@/models/Device';
import {Component, Vue} from 'vue-property-decorator';

@Component({
    components: {
        ProductLogBlock,
        ProductBlockDetails,
    },
})
export default class DeviceLogs extends Vue {
    async mounted(): Promise<void> {
        await this.$store.dispatch('user/home/init');
    }

    get products(): Promise<Device[]> {
        return this.$store.getters['user/home/products'];
    }
}
</script>

<style scoped lang="scss">
    ::v-deep .product-info {

        @media screen and (max-width: $breakpoint-sm-max) {
            font-size: 16px;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            --action-text-display: none;
            --action-column-width: 5fr;
            --image-column-width: 18fr;
            --basic-info-width: 18fr;
            --status-column-width: 1fr;

            &__details-date {
                display: block;
            }

            &__details {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 1em;
            }

            &__badge {
                width: auto;
            }
        }

        @media screen and (max-width: $breakpoint-xs-max) {
            font-size: 14px;
        }
    }
</style>