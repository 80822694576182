import ClientApi from '@/services/api/ClientApi';
import {RequestManagerInterface} from '@/services/requestManager';

export default class RequestArchiver {
    constructor(private requestManager: RequestManagerInterface, private clientApi: ClientApi) {
    }

    async archive(requestId: string): Promise<boolean> {
        const {hostName, path, method} = this.clientApi.archiveRequest(requestId);
        const result = await this.requestManager.getMethodByString(method)(hostName, path, '');
        const json = await result.json();

        if (json) {
            return false;
        }

        return false;
    }
}