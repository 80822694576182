export type AssetsRequestApi = {
    hostName: string;
    method: 'get' | 'post' | 'put' ;
    path: string;
};

export default class AssetsApi {
    constructor(private readonly apiUrl: string) {
    }

    getProductImage(productUid: number): AssetsRequestApi {
        return {
            hostName: this.apiUrl,
            method: 'get',
            path: `/image/XS/${productUid}`,
        };
    }
}