<template>
    <div class="label-value" :class="containerClass">
        <span class="label-value__label" :class="labelStyle" :title="$t(label).toString()" >{{
            $t(label) }}</span>
        <span class="label-value__value" :class="valueStyle" v-html="$t(value)"></span>
    </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class LabelValue extends Vue {
    @Prop() label!: string;
    @Prop() value!: string;
    @Prop() containerClass!: string;
    @Prop() labelStyle!: string;
    @Prop() valueStyle!: string;
}

</script>

<style scoped lang="scss">
@import '~@rawlplug/rawlplug-design-style-tokens/dist/scss/colors';

.label-value {
    max-width: 180px;
    height: 60%;
    width: auto;
    text-align: left;
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    font-weight: 300;

    &.device-name {
        width: 160px;
    }

    &.device-status {
        width: 160px;
    }

    &__label {
        color: $color-rwl-gray-100;
    }

    &__value {
        color: $color-rwl-gray-100;
        width: 100%;
    }
}

.bold {
    font-weight: 400;
    color: $color-rwl-black-100;
}

.extra-bold {
    font-weight: 500;
    color: $color-rwl-black-100;

}

@media (max-width: $breakpoint-sm-max) {
    .label-value {
        text-align: center;
    }
}
@media (min-width: $breakpoint-sm-max) {
    .label-value {
        &__label {
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
                    line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
}
</style>