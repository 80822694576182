import LanguageSwitcherInterface from '@Framework/interfaces/LanguageSwitcherInterface';

class I18nLanguageSwitcher implements LanguageSwitcherInterface {
    private callbacks = {
        onLanguageChanged: [] as ((language: string) => void)[],
    };

    constructor(private i18n: any) { }

    t(term: string): any {
        // To wynika z zewnętrznej biblioteki
        // eslint-disable-next-line no-underscore-dangle
        return this.i18n._t(term, this.i18n.locale, this.i18n.messages, this);
    }

    getLanguagesList() {
        return this.i18n.availableLocales;
    }

    getCurrentLanguage() {
        return this.i18n.locale;
    }

    setLanguage(value: string) {
        this.i18n.locale = value;
        this.callbacks.onLanguageChanged.forEach((callback) => callback(value));
    }

    get locale() {
        return this.i18n.locale;
    }

    onLanguageChanged(callback: (language: string) => void) {
        this.callbacks.onLanguageChanged.push(callback);
    }
}

export default I18nLanguageSwitcher;