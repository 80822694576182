<template>
    <div class="product-block-dynamic-container">
        <div class="product-block-dynamic-container__element" :key=key v-for="(element, key) in objects">
            <component :is="element.component" :key="key" v-bind="element.props" v-on="element.on"></component>
        </div>
    </div>
</template>

<script lang="ts">
import {Component, Vue, Prop} from 'vue-property-decorator';
import type {VueConstructor} from 'vue';

export type ObjType = {
    component: VueConstructor<Vue>;
    props: Record<any, any>;
    on?: Record<string, (...args: any) => void>;
};

@Component
export default class ProductBlockDetailsDynamic extends Vue {
    @Prop() objects!: ObjType[];
}
</script>

<style scoped lang="scss">
.product-block-dynamic-container {
    background-color: $color-rwl-gray-5;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-bottom: 0px;
    padding: 10px 10px;

    &__element {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            mix-blend-mode: multiply;
        }
    }

    @media(max-width: $breakpoint-sm-max) {
        & {
            width: 100%;
            align-items: center;
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 20px;
        }
    }
}
</style>