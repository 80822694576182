import Framework from '@Framework/index';
import LoadingService from '@/services/LoadingService';
import Vue from 'vue';
import installCTSLibrary from '@/plugins/CentralToolServiceLibrary';
import ioc from '@/services/ioc';
import registerNotificationSystem from '@/services/notificationSystem';
import {installI18n} from './plugins/i18n';
import registerRouter from './router';
import App from './App.vue';
import store from './store';

Vue.config.productionTip = false;
Vue.use(Framework);
Vue.prototype.$loading = new LoadingService();
const extraDevicesNamesList: string[] = [
    'R-PAG-125750',
    'R-PAG-1251500V',
    'R-PID18-315-M2',
    'R-PID18-315-S',
    'R-PID18-315-XL2',
    'R-PID18-315-XS',
    'R-PRH18-24-S',
    'R-PRH18-24-XL2',
    'R-PRH18-24-XS',
    'R-PRH18-26-S',
    'R-PRH18-26-XL2',
    'R-PRH18-26-XS',
];
ioc.set(
    'extraDevicesNamesList',
    function getExtraDevicesNamesList() {
        return extraDevicesNamesList;
    },
    {},
);
const languageSwitcher = await installI18n(Vue);
installCTSLibrary(Vue, store, extraDevicesNamesList);
const router = registerRouter(store);

let config = Framework.getVueConfig(App);
config = {
    ...config,
    // i18n,
    languageSwitcher,
    router,
    store,
};
const app = new Vue(config);
// todo - występował problem że rejestrowało mi wielokrotnie tne serwis w związku czym duplikowały się wiadomości
// i wyświetlały 1, 2, 4, 8 krotnie
const notificationSystem = registerNotificationSystem(app, store);
ioc.set(
    'service.notificationSystem',
    function getNotificationSystem() {
        return notificationSystem;
    },
    {},
);

app.$mount('#app');