import defaultNoop from '@/helpers/defaultNoop';

export enum RequestStatusEnum {
    'PENDING' = 'PENDING',
    'APPROVED' = 'APPROVED',
    'REJECTED' = 'REJECTED',
    'ARCHIVED' = 'ARCHIVED',
    'ENDED' = 'ENDED',
    'UNSET' = 'UNSET',
}

export default defaultNoop;