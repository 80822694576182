import StatusCalculator, {StatusVariant} from '@/services/domain/statuses/calculators/StatusCalculator';
import StatusObjectType from '@/services/domain/statuses/models/StatusObject';

export default abstract class BaseStatusCalculator implements StatusCalculator {
    abstract statusCode(): string;

    abstract statusLabel(): string;

    abstract statusVariant(): StatusVariant;

    toValue(): StatusObjectType {
        return {
            code: this.statusCode(),
            label: this.statusLabel(),
            variant: this.statusVariant(),
        };
    }
}