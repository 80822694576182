import Device from '@/models/Device';
import ClientApiInterface from '@/services/api/ClientApiInterface';
import DeviceMapper from '@/services/domain/device/deviceMapper';
import {RequestManagerInterface} from '@/services/requestManager';

export default class DeviceArchiverService {
    constructor(
        private requestManager: RequestManagerInterface,
        private deviceMapper: typeof DeviceMapper,
        private api: ClientApiInterface,
    ) {
    }

    async archiveDevice(device: Device): Promise<Device[]> {
        const response = await this.requestManager.call(this.api.archiveDevice(device.id), '', '');
        const result = await response.json();

        return this.deviceMapper.mapDevicesForClient(result.body.devices);
    }
}