import ioc from '@Framework/ioc';
import AppConfig from '@AppConfig/AppConfig';
import ComponentsConfig from '@AppConfig/ComponentsConfig';
import DependenciesConfig from '@AppConfig/DependenciesConfig';
import FooterConfig from '@AppConfig/FooterConfig';
import LayoutConfig from '@AppConfig/LayoutConfig';
import MenuConfig from '@AppConfig/MenuConfig';
import PluginsConfig from '@AppConfig/PluginsConfig';
import RouterConfig from '@AppConfig/RouterConfig';

export const configs = [
    ['config.app', function() { return AppConfig()}, {}],
    ['config.components', function() { return  ComponentsConfig()}, {}],
    ['config.dependencies', function() { return DependenciesConfig()}, {}],
    ['config.footer', function() { return  FooterConfig()}, {}],
    ['config.layout', function() { return  LayoutConfig()}, {}],
    ['config.menu', function() { return  MenuConfig()}, {}],
    ['config.plugins', function() { return PluginsConfig()}, {}],
    ['config.router', function() { return  RouterConfig()}, {}],
] as const

configs.forEach(config => {
    ioc.set(config[0] as string, config[1], config[2]);
})