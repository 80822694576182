import ClientTableField from '@/components/tables/fields/ClientTableField.vue';
import equals from '@/helpers/equals';
import {formatDate} from '@/helpers/formatDate';
import ClientStats from '@/models/ClientStats';
import ParamsOptionsType from '@/services/api/ParamsOptionsType';
import ClientBaseType from '@/services/domain/client/ClientBaseType';
import ClientsProvider from '@/services/domain/client/ClientsProvider';
import ClientType from '@/services/domain/client/ClientType';
import ioc from '@/services/ioc';
import {
    AdminClientActionContext,
    AdminClientModuleState,
    ClientWithShortStatType,
} from '@/store/admin/types/AdminClientsModule';
import {StoreRootState} from '@/store/storeRootState';
import {ActionContext, Commit, Module} from 'vuex';
import {getField, updateField} from 'vuex-map-fields';

let loadedClients: boolean;
const clients: Module<AdminClientModuleState, StoreRootState> = {
    namespaced: true,
    state: {
        clients: [],
        clientsStats: [],
        clientStats: null,
        lastParamsSearch: null,
        sortOptions: [
            {
                order: 'DESC',
                orderBy: 'last_name',
                text: 'cts.filters.option-last-name-desc',
            },
            {
                order: 'ASC',
                orderBy: 'last_name',
                text: 'cts.filters.option-last-name-asc',
            },
            {
                order: 'DESC',
                orderBy: 'created_at',
                text: 'cts.filters.option-registration-date-desc',
            },
            {
                order: 'ASC',
                orderBy: 'created_at',
                text: 'cts.filters.option-registration-date-asc',
            },
        ],
        sorting: {
            order: 'DESC',
            orderBy: 'last_name',
            text: 'cts.filters.option-last-name-desc',
        },
        page: 0,
        limit: 100,
    },
    mutations: {
        SET_CLIENTS: (state: AdminClientModuleState, payload: ClientType[]) => {
            state.clients = payload;
        },
        SET_CLIENTS_STATS: (state: AdminClientModuleState, payload: ClientStats[]) => {
            state.clientsStats = payload;
        },
        SET_CLIENT_STATS: (state: AdminClientModuleState, payload: ClientStats) => {
            state.clientStats = payload;
        },
        SET_PARAMS_SEARCH: (state: AdminClientModuleState, payload: ParamsOptionsType) => {
            state.lastParamsSearch = payload;
        },
        updateField,
    },
    actions: {
        findClient({state}: AdminClientActionContext, ridNumber: string) {
            return state.clients.find((client) => client.ridNumber === ridNumber);
        },
        async fetchClient({dispatch, commit, state}: AdminClientActionContext, ridNumber: string) {
            let resultFindClient = await dispatch('findClient', ridNumber);
            if (resultFindClient && resultFindClient.email) {
                return resultFindClient;
            }
            const clientsProvider = ioc.get('provider.clientsProvider') as ClientsProvider;
            const client = await clientsProvider.getClient(ridNumber);
            if (client) {
                if (!resultFindClient) {
                    const newClients = [...state.clients, client];
                    commit('SET_CLIENTS', newClients);
                } else {
                    resultFindClient = client;
                }
                loadedClients = true;

                return client;
            }

            return null;
        },
        async fetchClients(ctx: AdminClientActionContext, params: ParamsOptionsType): Promise<ClientType[]> {
            const {commit, state} = ctx;
            if (equals(params, state.lastParamsSearch ?? {}) && loadedClients) {
                if (loadedClients) {
                    (setTimeout(() => {
                        const clientsProvider = ioc.get('provider.clientsProvider') as ClientsProvider;
                        clientsProvider.getClients(params).then((clientsList) => {
                            commit('SET_PARAMS_SEARCH', params);
                            commit('SET_CLIENTS', clientsList);
                        });
                    }, 1000));
                }

                return state.clients;
            }

            const clientsProvider = ioc.get('provider.clientsProvider') as ClientsProvider;
            const clientsList = await clientsProvider.getClients(params);
            commit('SET_PARAMS_SEARCH', params);
            commit('SET_CLIENTS', clientsList);
            loadedClients = true;

            return clientsList;
        },
        async fetchClientsStats({commit}: {commit: Commit}) {
            const clientsProvider = ioc.get('provider.clientsProvider') as ClientsProvider;
            const res = await clientsProvider.getClientsStats();

            if (res) {
                commit('SET_CLIENTS_STATS', res);

                return res;
            }

            return [];
        },
        async fetchClientStats({commit}: {commit: Commit}, {ridNumber}): Promise<ClientStats | null> {
            const clientsProvider = ioc.get('provider.clientsProvider') as ClientsProvider;
            const res = await clientsProvider.getClientStats(ridNumber);

            if (res) {
                commit('SET_CLIENT_STATS', res);

                return res;
            }

            return null;
        },
        async updateClient(_: ActionContext<AdminClientModuleState, StoreRootState>, {
            clientData,
            ridNumber,
        }: {clientData: ClientBaseType, ridNumber: string}) {
            const clientsProvider = ioc.get('provider.clientsProvider') as ClientsProvider;

            return clientsProvider.updateClient(clientData, ridNumber);
        },
    },
    getters: {
        clientsWithShortStats(state: AdminClientModuleState): ClientWithShortStatType[] {
            function getStatsForClient(ridNumber: string) {
                return state.clientsStats.find((stat: ClientStats) => stat?.client?.ridNumber === ridNumber);
            }

            return state.clients.map((client: ClientType) => {
                const stats = getStatsForClient(client?.ridNumber ?? '');

                return {
                    client,
                    date: formatDate(client.createdAt.date) ?? '',
                    devices: stats?.tools?.total ?? 0,
                    openRequests: stats?.responses?.total ?? 0,
                    closedRequests: stats?.responses?.close ?? 0,
                    messages: stats?.messages?.total ?? 0,
                };
            });
        },
        usersIndexFields() {
            return [
                {
                    name: 'client',
                    title: 'cts.table.table-client',
                    component: ClientTableField,
                },
                {
                    name: 'date',
                    title: 'cts.table.table-registration',
                },
                {
                    name: 'devices',
                    title: 'cts.table.table-devices',
                },
                {
                    name: 'openRequests',
                    title: 'cts.table.table-openRequests',
                },
                {
                    name: 'closedRequests',
                    title: 'cts.table.table-closedRequests',
                },
                {
                    name: 'messages',
                    title: 'cts.table.table-messages',
                },
                {
                    name: 'actions',
                    title: 'cts.table.table-actions',
                },
            ];
        },
        getField,
    },
};

export default clients;