import {StatusVariant} from '@/services/domain/statuses/calculators/StatusCalculator';

type StatusObjectType = {
    code: string,
    label: string,
    variant: StatusVariant
};

export function createEmptyStatusObject(): StatusObjectType {
    return {
        code: '',
        label: '',
        variant: 'default',
    };
}

export default StatusObjectType;