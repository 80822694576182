import {RouteConfig} from 'vue-router';
import {routesRequests} from '@/router/user/requestsRoutes';
import {routesDevices} from '@/router/user/devicesRoutes';
import {routesMessages} from '@/router/user/messagesRoutes';
import {routesAddresses} from '@/router/user/addressesRoutes';

function routesYourAccount(): RouteConfig[] {
    return [
        {
            path: '/user/your-account',
            name: 'YourAccount',
            component: () => import(/* webpackChunkName: "your-account" */ '@/views/user/YourAccount.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.header-nav.your-account'},
                ],
                routeFor: [],
            },
        },
    ];
}

export function routesUser(): RouteConfig[] {
    return [
        ...routesYourAccount(),
        ...routesRequests(),
        ...routesDevices(),
        ...routesMessages(),
        ...routesAddresses(),
    ];
}

export default routesUser;