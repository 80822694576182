import {RouteConfig} from 'vue-router';

function routesMessagesIndex(): RouteConfig[] {
    return [
        {
            path: '/admin/messages',
            name: 'AdminMessagesIndex',
            component: () => import(
                '@/views/messages/MessagesIndex.vue'
                /* webpackChunkName: "admin-messages-index" */
            ),
            meta: {
                crumbs: [
                    {name: 'RequestsOpenIndex', icon: 'home', title: ''},
                    {name: '', icon: '', title: 'cts.routes.routes-messages'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

function routesSingleMessage(): RouteConfig[] {
    return [
        {
            path: '/admin/messages/:id',
            name: 'AdminSingleMessage',
            component: () => import(
                '@/views/messages/SingleMessage.vue'
                /* webpackChunkName: "admin-single-message" */
            ),
            meta: {
                crumbs: [
                    {name: 'RequestsOpenIndex', icon: 'home', title: ''},
                    {name: 'AdminMessagesIndex', icon: '', title: 'cts.routes.routes-messages'},
                    {name: '', icon: '', title: 'cts.routes.routes-message'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

function routesNewMessage(): RouteConfig[] {
    return [
        {
            path: '/admin/messages/new/:ridNumber',
            name: 'AdminNewMessage',
            component: () => import(/* webpackChunkName: "admin-new-message" */ '@/views/messages/NewMessage.vue'),
            meta: {
                crumbs: [
                    {name: 'Home', icon: 'home', title: ''},
                    {name: 'AdminMessagesIndex', icon: '', title: 'cts.routes.routes-message'},
                    {name: '', icon: '', title: 'cts.routes.routes-message'},
                ],
                admin: true,
                routeFor: ['ADMIN'],
            },
        },
    ];
}

export function routesMessages(): RouteConfig[] {
    return [
        ...routesMessagesIndex(),
        ...routesSingleMessage(),
        ...routesNewMessage(),
    ];
}

export default routesMessages;