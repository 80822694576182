<template>
    <div class="menu-modal" v-show="active" keep-alive @click="onMenuClick">
        <header class="menu-modal__top">
            <button class="button-close button-no" @click="close">
                <close-icon></close-icon>
            </button>
            <div class="user-info" v-if="$store.state.auth.status.loggedIn && !$store.getters['auth/isAdmin']">
                <p>
                    <span v-html="getCurrentUserData()"></span><br/>
                    <router-link :to="{name: 'YourAccount'}" class="link secondary">
                        {{ $t('cts.header-nav.your-account') }}
                    </router-link>
                </p>
            </div>
        </header>
        <main class="menu-modal__content">
            <menu-renderer menu="admin-mobile"
                           v-if="$store.state.auth.status.loggedIn && $store.getters['auth/isAdmin']"/>
            <menu-renderer menu="client-mobile"
                           v-if="$store.state.auth.status.loggedIn && !$store.getters['auth/isAdmin']"/>
            <template v-if="!$store.state.auth.status.loggedIn">
                <p class="menu-modal__anonymous-mobile-title">{{ $t('cts.homeview.main-action-button') }}</p>
                <menu-renderer menu="anonymous-mobile"></menu-renderer>
            </template>
            <p>
                <a href="#" class="link secondary"
                   @click.prevent.stop="$store.dispatch('auth/logout', {reload: true})"
                   v-if="$store.state.auth.status.loggedIn">{{ $t('cts.header-nav.logout') }}</a>
            </p>
        </main>
        <footer class="menu-modal__footer">
            <span v-html="footer.text"></span>
            <p class="menu-modal__links">
                <a :href="link.href" v-for="(link, index) in footer.links" :key="index" class="link secondary">
                    {{ link.label }}
                </a>
            </p>
        </footer>
    </div>
</template>

<style lang="scss">
    .menu-modal {
        z-index: 2;
        @include make-overlay();
        display: flex;
        flex-direction: column;
        color: #fff;

        header {
            background-color: $color-rwl-blue-100;
            padding: 0.5em;
            font-weight: 300;
            padding-left: 3em;
            min-height: 40px;
        }

        .menu-modal__anonymous-mobile-title {
            font-size: 1.5em;
            color: $color-rwl-white-100;
        }

        .button-close {
            position: absolute;
            left: 1em;
            top: 1em;
        }

        main {
            background-color: $color-rwl-blue-90;
            padding-left: 1em;
            flex-grow: 2;
        }

        footer {
            padding: 0.5em;
            margin-top: auto;
            background-color: $color-rwl-blue-100;

            .menu-modal__links {
                display: flex;
                grid-gap: 1em;
            }
        }
    }
</style>

<script lang="ts">
import CloseIcon from '@/assets/CloseIcon.svg';
import getClientFullName from '@/helpers/getClientFullName';
import MenuRenderer from '@Framework/components/MenuRenderer.vue';
import {Component, Vue} from 'vue-property-decorator';
import VModalInterface from '@/components/modals/VModalInterface';

@Component({
    components: {MenuRenderer, CloseIcon},
})
export default class MobileMenuModal extends Vue implements VModalInterface {
    name = 'mobile-menu';
    active = false;

    footer = {
        text: `©${new Date().getFullYear()} Koelner Rawlplug IP`,
        links: [
            {
                href: '',
                label: 'Prywatność',
            },
            {
                href: '',
                label: 'RODO',
            },
            {
                href: '',
                label: 'Regulamin',
            },
        ],
    };

    onMenuClick(event: MouseEvent) {
        if ((event.target as HTMLElement)?.tagName === 'A') {
            this.close();
        }
    }

    getCurrentUserData() {
        const {user} = this.$store.state.auth;

        return this.loggedIn
            ? `<b>${getClientFullName(user)}</b><br/>${user?.companyName}`
            : '';
    }

    mounted() {
        this.$modals.add(this.name, this);
    }

    beforeDestroy() {
        this.$modals.remove(this.name);
    }

    show() {
        this.active = true;
        document.body.classList.add('modal-open');
        this.$emit('show');
    }

    hide() {
        return this.close();
    }

    close() {
        this.active = false;
        document.body.classList.remove('modal-open');
        this.$emit('close');
    }

    get loggedIn() {
        return this.$store.state.auth.status.loggedIn;
    }
}
</script>